import {useMutation} from "@apollo/client";
import {useCallback, useState} from "react";
import {FORGOT_PASSWORD} from "./commands";

/**
 * Custom hook to handle forgot password functionality.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{submit: Function, isSubmitting: boolean, resetSuccess: boolean}} - The function to submit the forgot password request, the loading state, and the success state.
 */
export function useForgotPassword(
  // onCompleted = () => null,
  onError = () => null) {
  // const { setToken } = useAuth();
  const [resetSuccess, setResetSuccess] = useState(null);
  const [useNotFound, setUseNotFound] = useState(null);

  const [comForgetPassword, {loading}] = useMutation(FORGOT_PASSWORD, {
    onCompleted: ({comForgetPassword}) => {
      if (comForgetPassword.success === false) {
        setResetSuccess(false);
        setUseNotFound(comForgetPassword.userNotFound);
        throw Error(comForgetPassword.message);
      }
      setUseNotFound(false);
      setResetSuccess(true);
      localStorage.clear();
    },
    onError: error => {
      setResetSuccess(false);
      onError(error);
    }
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comForgetPassword({
          variables: credentials
        });

        // ReactGA.event({
        //   category: "Authentication",
        //   action: "PasswordReset",
        //   label: credentials?.email.replace("@", "[at]")
        // });
      } catch { /* empty */
      }
    },
    [comForgetPassword]
  );

  return {
    submit,
    isSubmitting: loading,
    resetSuccess,
    useNotFound
  };
}
