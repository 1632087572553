export const sumRow = (rows, columns) => {
  const tmpObj = {
  };
  Object.keys(rows[0]).forEach(function (key) {
    if (key === "id") {
      tmpObj[key] = "sum-999999";
    } else if (key === columns[0].field) {
      tmpObj[key] = "Total";
    } else if (columns.find(column => column.field === key && column.summable)) {
      let tot = 0;
      Object.keys(rows).forEach(function (rowKey) {
        tot += rows[rowKey][key];
      });

      tmpObj[key] = tot;
    } else {
      tmpObj[key] = "";
    }
  });
  return [tmpObj];
};