import {FilterBar1} from "../../components";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Button, Paper, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery} from "@mui/material";
import {useLang, useTextDomainContext, useUser} from "../../providers";
import {
  energyMonthlyColumns,
  energyMonthlyRows,
  energyStatementKey,
  exportEnergyStatement,
  formatBytes,
  getLocalStorageSize,
  getMonthAbbreviation,
  getSessionStorageSize,
  indexedDbCalculateSize,
  indexedDbGetById,
  indexedDbUpdate,
  sleep,
  toLocaleNumber,
  urlFriendlyString
} from "../../../utils";
import {isEqual} from "lodash";
import {useApolloClient, useLazyQuery} from "@apollo/client";
import {
  CONSUMPTION_ENERGY_DATA_AGGREGATED_DATA
} from "../../../hooks/energy/commands/CONSUMPTION_ENERGY_DATA_AGGREGATED_DATA";
import {CONSUMPTION_ENERGY_DATA_MONTH_DATA} from "../../../hooks/energy/commands";
import {Stack} from "@mui/system";
import {deleteAll, deleteById} from "../../common/IndexedDb";
import {useSnackbar} from "notistack";

const colors = {
  notCached: "white",
  checkingCache: "warning.main",
  gettingData: "success.100",
  cached: "success.main",
};

export const EnergyStatementCache = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const client = useApolloClient();
  const {enqueueSnackbar} = useSnackbar();
  const matches = useMediaQuery("(min-width:1000px)");
  const locale = useLang();
  const {user} = useUser();

  let {
    currentTeam: customer,
    currentSubsidiary: group,
  } = user || {};

  if (!customer) {
    customer = "all";
  }

  if (!group) {
    group = "All";
  }

  //#region React Hooks (useState)
  const [fileNameForExport, setFileNameForExport] = useState("energy-statement");
  const [energyType, setEnergyType] = useState("electricity");
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthColumns, setMonthColumns] = useState([]);
  const [month, setMonth] = useState('jan');
  const [yearRowsData, setYearRowsData] = useState([]);
  const [monthRowsData, setMonthRowsData] = useState([]);
  const [isClimate, setIsClimate] = useState(false);
  const [colorYear, setColorYear] = useState(colors.notCached);
  const [colorJan, setColorJan] = useState(colors.notCached);
  const [colorFeb, setColorFeb] = useState(colors.notCached);
  const [colorMar, setColorMar] = useState(colors.notCached);
  const [colorApr, setColorApr] = useState(colors.notCached);
  const [colorMay, setColorMay] = useState(colors.notCached);
  const [colorJun, setColorJun] = useState(colors.notCached);
  const [colorJul, setColorJul] = useState(colors.notCached);
  const [colorAug, setColorAug] = useState(colors.notCached);
  const [colorSep, setColorSep] = useState(colors.notCached);
  const [colorOct, setColorOct] = useState(colors.notCached);
  const [colorNov, setColorNov] = useState(colors.notCached);
  const [colorDec, setColorDec] = useState(colors.notCached);
  const [textYear, setTextYear] = useState(gettext("Year: Get"));
  const [textJan, setTextJan] = useState(gettext("Jan: Get"));
  const [textFeb, setTextFeb] = useState(gettext("Feb: Get"));
  const [textMar, setTextMar] = useState(gettext("Mar: Get"));
  const [textApr, setTextApr] = useState(gettext("Apr: Get"));
  const [textMay, setTextMay] = useState(gettext("May: Get"));
  const [textJun, setTextJun] = useState(gettext("Jun: Get"));
  const [textJul, setTextJul] = useState(gettext("Jul: Get"));
  const [textAug, setTextAug] = useState(gettext("Aug: Get"));
  const [textSep, setTextSep] = useState(gettext("Sep: Get"));
  const [textOct, setTextOct] = useState(gettext("Oct: Get"));
  const [textNov, setTextNov] = useState(gettext("Nov: Get"));
  const [textDec, setTextDec] = useState(gettext("Dec: Get"));
  const [filtersYear, setFiltersYear] = useState({
    meterType: energyType,
    customer: customer,
    group: group,
    year: year,
    isClimate: isClimate,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [memoryPercentage, setMemoryPercentage] = useState(0);
  // const [storageUsed, setStorageUsed] = useState(0);
  const [localStorageSize, setLocalStorageSize] = useState(0);
  const [sessionStorageSize, setSessionStorageSize] = useState(0);
  const [indexedDbPersistentSize, setIndexedDbPersistentSize] = useState(0);
  const [indexedDbCacheSize, setIndexedDbCacheSize] = useState(0);

  //#endregion React Hooks (useState)

  //#region Data
  const [getYearData] = useLazyQuery(CONSUMPTION_ENERGY_DATA_AGGREGATED_DATA);
  const [getMonthData] = useLazyQuery(CONSUMPTION_ENERGY_DATA_MONTH_DATA);

  const yearColumns = useMemo(() => {
    return [
      {
        field: "meter",
        headerName: gettext("Order No"),
        width: 200,
      },
      {
        field: "meterNumber",
        headerName: gettext("Meter No"),
        width: 150,
      },
      {
        field: "customer",
        headerName: gettext("Company"),
        width: 250,
      },
      // {
      //   field: "address",
      //   headerName: gettext("Address"),
      //   width: 300,
      // },
      {
        field: "streetName",
        headerName: gettext("Street"),
        width: 200,
      },
      {
        field: "buildingNumber",
        headerName: gettext("House No."),
        width: 100,
      },
      {
        field: "postcode",
        headerName: gettext("Zip Code"),
        width: 100,
      },
      {
        field: "cityName",
        headerName: gettext("City"),
        width: 150,
      },
      {
        field: "consumerCVR",
        headerName: gettext("Company No."),
        width: 100,
      },
      {
        field: "total",
        headerName: gettext("Sum"),
        // flex: 1,
        width: 150,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "jan",
        headerName: gettext("January"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "feb",
        headerName: gettext("February"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "mar",
        headerName: gettext("March"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "apr",
        headerName: gettext("April"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "may",
        headerName: gettext("May"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "jun",
        headerName: gettext("June"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "jul",
        headerName: gettext("July"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "aug",
        headerName: gettext("August"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "sep",
        headerName: gettext("September"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "oct",
        headerName: gettext("October"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "nov",
        headerName: gettext("November"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "dec",
        headerName: gettext("December"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
    ];
  }, [gettext, locale]);

  const excelData = useMemo(() => {
    const customerName = group.toLowerCase() === "all" ? customer : group;
    return [
      {
        filter: filtersYear,
        // title: gettext("Overall statement"),
        // columns: yearColumns,
        // rows: yearRowsData,
        customer: {
          name: `${customerName} - ${year}`,
          logo: {
            file: "",
            extension: "png",
            width: 100,
            height: 50,
            link: "https://app.fabeke.com",
          },
        },
        partner: {
          name: "Energi Team ApS",
          logo: {
            file: "/media/partners/energiteam/logos/logo-energy-team-one-line.png",
            extension: "png",
            width: 393,
            height: 62,
            link: "https://app.energiteam.dk",
          },
        },
      },
      // {
      //   title: gettext("January"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.jan || [],
      // },
      // {
      //   title: gettext("February"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.feb || [],
      // },
      // {
      //   title: gettext("March"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.mar || [],
      // },
      // {
      //   title: gettext("April"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.apr || [],
      // },
      // {
      //   title: gettext("May"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.may || [],
      // },
      // {
      //   title: gettext("June"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.jun || [],
      // },
      // {
      //   title: gettext("July"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.jul || [],
      // },
      // {
      //   title: gettext("August"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.aug || [],
      // },
      // {
      //   title: gettext("September"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.sep || [],
      // },
      // {
      //   title: gettext("October"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.oct || [],
      // },
      // {
      //   title: gettext("November"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.nov || [],
      // },
      // {
      //   title: gettext("December"),
      //   columns: monthColumns,
      //   rows: monthRowsData?.dec || [],
      // },
    ];
  }, [customer, gettext, group, monthColumns, monthRowsData, year, yearColumns, yearRowsData]);

  const storageUsed = useMemo(() => {
    return localStorageSize + sessionStorageSize + indexedDbPersistentSize + indexedDbCacheSize;
  }, [indexedDbCacheSize, indexedDbPersistentSize, localStorageSize, sessionStorageSize]);

  //#endregion Data


  //#region React Hooks (useEffect)
  useEffect(() => {
    const key = energyStatementKey("monthData4Columns", filtersYear);
    if (filtersYear?.customer) {
      indexedDbGetById("cache", key).then((result) => {
        const columnsData = energyMonthlyColumns(result, gettext, locale);
        setMonthColumns(columnsData);
      });
    }
  }, [filtersYear, gettext, locale]);

  useEffect(() => {
    if (filtersYear?.meterType) {
      const key = energyStatementKey("aggregated", filtersYear);
      indexedDbGetById("cache", key).then((result) => {
        setYearRowsData(result);
      }).catch();
    }
  }, [filtersYear]);

  useEffect(() => {
    for (let tmp = 1; tmp <= 12; tmp++) {
      const monthName = getMonthAbbreviation(tmp);
      const key = energyStatementKey(monthName, filtersYear);
      indexedDbGetById("cache", key).then((result) => {
        if (result?.length) {
          const newMonthRowsData = {
            ...monthRowsData
          };
          newMonthRowsData[monthName] = result;
          if (!isEqual(newMonthRowsData, monthRowsData)) {
            setMonthRowsData(newMonthRowsData);
          }
        }
      });
    }
  }, [filtersYear, monthRowsData]);

  useEffect(() => {
    let filenamePrefix = "energy-statement-";
    if (isClimate) {
      filenamePrefix = "climate-statement-";
    }

    const customerName = group.toLowerCase() === "all" ? customer : group;

    setFileNameForExport(urlFriendlyString(filenamePrefix + customerName + "-" + energyType + "-" + year + "-" + month + "-" + Math.floor(Date.now() / 1000)));
  }, [energyType, year, month, customer, isClimate, group]);

  useEffect(() => {
    if (!buttonDisabled) {
      const keyYear = energyStatementKey("aggregated", filtersYear);
      indexedDbGetById("cache", keyYear).then(dataIndexedDb => {
        if (dataIndexedDb) {
          setColorYear(colors.cached);
          setTextYear(gettext("Year: Update"));
        } else {
          setColorYear(colors.notCached);
          setTextYear(gettext("Year: Get"));
        }
      });
    }
  }, [filtersYear, gettext, buttonDisabled]);

  useEffect(() => {
    if (!buttonDisabled) {
      for (let i = 0; i < 12; i++) {
        const monthName = getMonthAbbreviation(i + 1);
        const keyMonth = energyStatementKey(monthName, filtersYear);
        switch (monthName) {
          case "jan":
            setColorJan(colors.checkingCache);
            setTextJan(gettext("Jan: Checking"));
            break;
          case "feb":
            setColorFeb(colors.checkingCache);
            setTextFeb(gettext("Feb: Checking"));
            break;
          case "mar":
            setColorMar(colors.checkingCache);
            setTextMar(gettext("Mar: Checking"));
            break;
          case "apr":
            setColorApr(colors.checkingCache);
            setTextApr(gettext("Apr: Checking"));
            break;
          case "may":
            setColorMay(colors.checkingCache);
            setTextMay(gettext("May: Checking"));
            break;
          case "jun":
            setColorJun(colors.checkingCache);
            setTextJun(gettext("Jun: Checking"));
            break;
          case "jul":
            setColorJul(colors.checkingCache);
            setTextJul(gettext("Jul: Checking"));
            break;
          case "aug":
            setColorAug(colors.checkingCache);
            setTextAug(gettext("Aug: Checking"));
            break;
          case "sep":
            setColorSep(colors.checkingCache);
            setTextSep(gettext("Sep: Checking"));
            break;
          case "oct":
            setColorOct(colors.checkingCache);
            setTextOct(gettext("Oct: Checking"));
            break;
          case "nov":
            setColorNov(colors.checkingCache);
            setTextNov(gettext("Nov: Checking"));
            break;
          case "dec":
            setColorDec(colors.checkingCache);
            setTextDec(gettext("Dec: Checking"));
            break;
          default:
            break;
        }
        indexedDbGetById("cache", keyMonth).then(dataIndexedDb => {
          if (dataIndexedDb) {
            switch (monthName) {
              case "jan":
                setColorJan(colors.cached);
                setTextJan(gettext("Jan: Update"));
                break;
              case "feb":
                setColorFeb(colors.cached);
                setTextFeb(gettext("Feb: Update"));
                break;
              case "mar":
                setColorMar(colors.cached);
                setTextMar(gettext("Mar: Update"));
                break;
              case "apr":
                setColorApr(colors.cached);
                setTextApr(gettext("Apr: Update"));
                break;
              case "may":
                setColorMay(colors.cached);
                setTextMay(gettext("May: Update"));
                break;
              case "jun":
                setColorJun(colors.cached);
                setTextJun(gettext("Jun: Update"));
                break;
              case "jul":
                setColorJul(colors.cached);
                setTextJul(gettext("Jul: Update"));
                break;
              case "aug":
                setColorAug(colors.cached);
                setTextAug(gettext("Aug: Update"));
                break;
              case "sep":
                setColorSep(colors.cached);
                setTextSep(gettext("Sep: Update"));
                break;
              case "oct":
                setColorOct(colors.cached);
                setTextOct(gettext("Oct: Update"));
                break;
              case "nov":
                setColorNov(colors.cached);
                setTextNov(gettext("Nov: Update"));
                break;
              case "dec":
                setColorDec(colors.cached);
                setTextDec(gettext("Dec: Update"));
                break;
              default:
                break;
            }
          } else {
            switch (monthName) {
              case "jan":
                setColorJan(colors.notCached);
                setTextJan(gettext("Jan: Get"));
                break;
              case "feb":
                setColorFeb(colors.notCached);
                setTextFeb(gettext("Feb: Get"));
                break;
              case "mar":
                setColorMar(colors.notCached);
                setTextMar(gettext("Mar: Get"));
                break;
              case "apr":
                setColorApr(colors.notCached);
                setTextApr(gettext("Apr: Get"));
                break;
              case "may":
                setColorMay(colors.notCached);
                setTextMay(gettext("May: Get"));
                break;
              case "jun":
                setColorJun(colors.notCached);
                setTextJun(gettext("Jun: Get"));
                break;
              case "jul":
                setColorJul(colors.notCached);
                setTextJul(gettext("Jul: Get"));
                break;
              case "aug":
                setColorAug(colors.notCached);
                setTextAug(gettext("Aug: Get"));
                break;
              case "sep":
                setColorSep(colors.notCached);
                setTextSep(gettext("Sep: Get"));
                break;
              case "oct":
                setColorOct(colors.notCached);
                setTextOct(gettext("Oct: Get"));
                break;
              case "nov":
                setColorNov(colors.notCached);
                setTextNov(gettext("Nov: Get"));
                break;
              case "dec":
                setColorDec(colors.notCached);
                setTextDec(gettext("Dec: Get"));
                break;
              default:
                break;
            }
          }
        });
        sleep(10000);
      }
    }
  }, [filtersYear, gettext, buttonDisabled]);

  useEffect(() => {
    setFiltersYear({
      meterType: energyType,
      customer: customer,
      group: group,
      year: year,
      isClimate: isClimate,
    });
  }, [customer, energyType, group, isClimate, year]);

  useEffect(() => {
    const updateMemoryUsage = () => {
      if (performance.memory) {
        const {totalJSHeapSize, jsHeapSizeLimit} = performance.memory;
        const totalMemoryPercentage = ((totalJSHeapSize / jsHeapSizeLimit) * 100).toFixed(2);
        setMemoryPercentage(totalMemoryPercentage);
      }
    };

    updateMemoryUsage(); // Initial fetch
    const intervalMemory = setInterval(updateMemoryUsage, 60000); // Update every second

    return () => clearInterval(intervalMemory); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    const updateStorageUsage = () => {
      const localStorageSize = getLocalStorageSize();
      const sessionStorageSize = getSessionStorageSize();
      setLocalStorageSize(localStorageSize);
      setSessionStorageSize(sessionStorageSize);
      indexedDbCalculateSize("persistent-data").then(dbSize => {
        setIndexedDbPersistentSize(dbSize);
      });
      indexedDbCalculateSize("cache").then(dbSize => {
        setIndexedDbCacheSize(dbSize);
      });
    };

    updateStorageUsage(); // Initial fetch
    const intervalStorage = setInterval(updateStorageUsage, 300000); // Update every second

    return () => clearInterval(intervalStorage); // Cleanup on component unmount
  }, []);
  //#endregion React Hooks (useEffect)  


  //#region Handlers

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.selectedYearDate) {
      setYear(selectedValues.selectedYearDate.getFullYear());
    }

    if (selectedValues?.selectedMonth?.value) {
      setMonth(selectedValues.selectedMonth.value);
    }

    if (selectedValues?.consumptionOrCo2?.value) {
      switch (selectedValues?.consumptionOrCo2?.value) {
        case "climate":
        case "co2":
          setIsClimate(true);
          break;
        default:
          setIsClimate(false);
          break;
      }
    }
  };

  const handleExportToExcel = () => {
    exportEnergyStatement(excelData, `${fileNameForExport}.xlsx`, gettext, locale).then();
  };

  const handleGetYearData = () => {
    setColorYear(colors.checkingCache);
    setButtonDisabled(true);
    const keyYear = energyStatementKey("aggregated", filtersYear);
    // setDataReceived(false);
    getYearData({
      variables: filtersYear,
    }).then(({data}) => {
      const dataForSaving = data?.consumptionEnergyData?.aggregatedData;
      setYearRowsData(dataForSaving);

      indexedDbUpdate("cache", dataForSaving, keyYear).then(() => {
        setButtonDisabled(false);
        enqueueSnackbar(gettext("The year cache has been updated"), {
          variant: "success",
        });
        setColorYear(colors.cached);
        // setDataReceivedYear(true);
        // setLoaded(0);
        // setLoadingYear(false);
      });
    });
  };

  const handleGetMonthData = (monthNumber) => {
    const monthName = getMonthAbbreviation(monthNumber);
    const keyMonthData4Columns = energyStatementKey("monthData4Columns", filtersYear);
    const keyMonth = energyStatementKey(monthName, filtersYear);
    changeMonthColor(monthName, colors.gettingData);
    setButtonDisabled(true);

    getMonthData({
      variables: {
        ...filtersYear,
        month: monthNumber
      },
    }).then(({data}) => {
      indexedDbUpdate("cache", data, keyMonthData4Columns).then(() => {

      });

      const allRowsData = energyMonthlyRows(data, gettext);

      indexedDbUpdate("cache", allRowsData[monthName], keyMonth).then(() => {
        enqueueSnackbar(monthName + " " + gettext("cache has been updated"), {
          variant: "success",
        });
        changeMonthColor(monthName, colors.cached);
        setButtonDisabled(false);
      });
    });
  };

  const handlePageReload = () => {
    window.location.reload();
  };

  const handleClearAllCache = () => {
    setButtonDisabled(true);
    const storageLocale = localStorage.getItem("languageSelect");
    localStorage.clear();
    storageLocale && localStorage.setItem("languageSelect", storageLocale);

    deleteById("cache", "apollo-cache-persist").then(() => {
      deleteAll(["cache"]).then(() => {
        client.clearStore().then(() => {
          enqueueSnackbar(gettext("The cache has been cleared"), {
            variant: "info",
          });
        });
        setButtonDisabled(false);
      });
    });
  };

  //#endregion Handlers

  //#region Functions
  const changeMonthColor = (monthName, color) => {
    switch (monthName) {
      case "jan":
        setColorJan(color);
        break;
      case "feb":
        setColorFeb(color);
        break;
      case "mar":
        setColorMar(color);
        break;
      case "apr":
        setColorApr(color);
        break;
      case "may":
        setColorMay(color);
        break;
      case "jun":
        setColorJun(color);
        break;
      case "jul":
        setColorJul(color);
        break;
      case "aug":
        setColorAug(color);
        break;
      case "sep":
        setColorSep(color);
        break;
      case "oct":
        setColorOct(color);
        break;
      case "nov":
        setColorNov(color);
        break;
      case "dec":
        setColorDec(color);
        break;
      default:
        break;
    }
  };
  //#endregion Functions

  return (
    <>
      <Typography
        variant="h4"
        fontWeight={"bold"}
        sx={{
          mb: 2
        }}
        display="inline-block"
      >
        {gettext("Cashed Statement - Under Development")}
      </Typography>

      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        consumptionOrCo2={isClimate ? "climate" : "consumption"}
        showEnergyType
        showConsumptionOrCo2
        showYear
        showMonth
      />
      <br/>

      <ToggleButtonGroup
        orientation={`${matches ? `horizontal` : `vertical`}`}
      >
        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={handleGetYearData}
          sx={{
            backgroundColor: colorYear,
          }}
        >
          {textYear}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(1)}
          sx={{
            backgroundColor: colorJan,
          }}
        >
          {textJan}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(2)}
          sx={{
            backgroundColor: colorFeb,
          }}
        >
          {textFeb}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(3)}
          sx={{
            backgroundColor: colorMar,
          }}
        >
          {textMar}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(4)}
          sx={{
            backgroundColor: colorApr,
          }}
        >
          {textApr}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(5)}
          sx={{
            backgroundColor: colorMay,
          }}
        >
          {textMay}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(6)}
          sx={{
            backgroundColor: colorJun,
          }}
        >
          {textJun}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(7)}
          sx={{
            backgroundColor: colorJul,
          }}
        >
          {textJul}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(8)}
          sx={{
            backgroundColor: colorAug,
          }}
        >
          {textAug}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(9)}
          sx={{
            backgroundColor: colorSep,
          }}
        >
          {textSep}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(10)}
          sx={{
            backgroundColor: colorOct,
          }}
        >
          {textOct}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(11)}
          sx={{
            backgroundColor: colorNov,
          }}
        >
          {textNov}
        </ToggleButton>

        <ToggleButton
          value="none"
          disabled={buttonDisabled}
          onClick={() => handleGetMonthData(12)}
          sx={{
            backgroundColor: colorDec,
          }}
        >
          {textDec}
        </ToggleButton>

      </ToggleButtonGroup>
      <br/>
      <br/>

      <Stack direction="row" spacing={1}>
        <Paper>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            onClick={handleExportToExcel}
          >
            {gettext("Export to Excel")}
          </Button>
        </Paper>
        <Paper>
          <Button
            disabled={true}
            variant="contained"
            onClick={handleExportToExcel}
          >
            {gettext("Clear above cache")}
          </Button>
        </Paper>
        <Paper>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            onClick={handleClearAllCache}
          >
            {storageUsed ? gettext("Clear all cache") + " (" + gettext("Used:") + " " + formatBytes(storageUsed) + ")" : gettext("Clear all cache")}
          </Button>
        </Paper>
        <Paper>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            onClick={handlePageReload}
          >
            {memoryPercentage ? gettext("Clear browser memory / Page reload") + " (" + gettext("Used:") + " " + memoryPercentage + "%)" : gettext("Clear browser memory / Page reload")}
          </Button>
        </Paper>
      </Stack>

      <br/>
      <br/>
      <ToggleButtonGroup
        orientation="vertical"
      >
        <ToggleButton
          value="none"
          sx={{
            backgroundColor: colors.notCached,
          }}
        >
          {gettext("Not Cached")}
        </ToggleButton>

        <ToggleButton
          value="none"
          sx={{
            backgroundColor: colors.checkingCache,
          }}
        >
          {gettext("Checking Cache")}
        </ToggleButton>

        <ToggleButton
          value="none"
          sx={{
            backgroundColor: colors.gettingData,
          }}
        >
          {gettext("Getting Data")}
        </ToggleButton>

        <ToggleButton
          value="none"
          sx={{
            backgroundColor: colors.cached,
          }}
        >
          {gettext("Cached")}
        </ToggleButton>
      </ToggleButtonGroup>

    </>
  );
};