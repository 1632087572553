import {RolesList} from "../../components";
import React from "react";

export const DeveloperRoles = () => {

  return (
    <RolesList
      showAdd
      showEdit
      showDelete
    />
  );
};
