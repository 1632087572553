import {UsersList} from "../../components";
import React from "react";

export const DeveloperUsers = () => {
  return (
    <UsersList
      showLoginAs
      userFilter="all"
    />
  );
};
