/* global process */
import {Box} from "@mui/material";
import {useTextDomainContext, useUser} from "../../../../../../providers";
import React, {useEffect, useState} from "react";
import {SubsidiaryDropdownAutoSave} from "../../../../../../components";
import {useGetGroups, useUpdateProfile, useUpdateUser} from "../../../../../../../hooks";
import {enqueueSnackbar} from "notistack";
import {isEqual} from "lodash";

// const codesAllCompanies = ["all-companies-full", "all-Companies-readonly", "everything-full", "everything-readonly", "developer"];

/**
 *
 */
export const SubsidiarySelector = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = React.useContext(TextDomainContext);
  const {updateProfile} = useUpdateProfile();
  const {
    user,
    setUser
  } = useUser();
  const {
    currentSubsidiaryId,
    currentSubsidiary,
    currentTeam,
    currentTeamId
  } = user || {};
  const {subsidiary} = useGetGroups(currentTeam, currentTeamId);
  const {updateUser} = useUpdateUser(
    () => {
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        if (process.env.REACT_APP_NODE_ENV !== "production") {
          enqueueSnackbar(`Backend Error: ${error}`, {
            variant: "error"
          });
        }
      }
    }
  );

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (subsidiary.length > 0) {
      if (!isEqual(subsidiary, companies)) {
        setCompanies(subsidiary);
      }
    }
  }, [companies, subsidiary]);

  useEffect(() => {
    if (subsidiary.length === 1) {
      if (subsidiary[0].id !== currentSubsidiaryId || subsidiary[0].name !== currentSubsidiary) {
        const variables = {
          currentSubsidiary: subsidiary[0].name,
          currentSubsidiaryId: subsidiary[0].id
        };
        const updatedUser = {
          ...user,
          ...variables
        };
        setUser(updatedUser);

        updateProfile(variables);
      }
    }

    if (subsidiary.length > 1 && currentSubsidiaryId !== "all") {
      let selectedCustomerId = "";
      subsidiary.map(customerRow => {
        if (customerRow.name === currentSubsidiary) {
          selectedCustomerId = customerRow.id;
        }
        return null;
      });
      if (!selectedCustomerId) {
        const variables = {
          currentSubsidiary: subsidiary[0].name,
          currentSubsidiaryId: subsidiary[0].id
        };
        const updatedUser = {
          ...user,
          ...variables
        };
        setUser(updatedUser);

        updateProfile(variables);
      }
    }
  }, [currentSubsidiary, currentSubsidiaryId, setUser, subsidiary, updateProfile, updateUser, user]);

  return (
    <Box sx={{
      ml: 2
    }}>
      {companies?.length > 1 &&
        <SubsidiaryDropdownAutoSave/>
      }
      {(companies?.length < 2 || !companies) && <>
        {/*{!currentTeam || currentTeam === "null"*/}
        {/*  ? gettext("Select Company")*/}
        {/*  : currentTeam}*/}
      </>
      }
    </Box>
  );
};
