import {Grid} from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import {useDataHubCustomers} from "./hooks";
import React from "react";
import PropTypes from "prop-types";

const DatahubCustomers = props => {
  const {view, setView, setSelectedItem, selectedItem, gettext} = props;
  const {customersList, loading} = useDataHubCustomers();

  return (
    <Grid item>
      <DataHubTable
        title="Companies"
        setView={setView}
        view={view}
        rows={customersList}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default DatahubCustomers;

DatahubCustomers.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
  gettext: PropTypes.func
};
