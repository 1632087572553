import {Box, Chip, Grid, Typography} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const InfoItem = ({label, value}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignContent="center"
      justifyContent="center"
    >
      <Typography fontSize="14px">{label}:</Typography>
      <Chip
        sx={{
          ml: 0.5,
          fontSize: "14px",
          backgroundColor: "primary.50",
          color: "primary.main"
        }}
        size="small"
        label={value}
      />
    </Box>
  );
};

const InfoBar = props => {
  const {
    view,
    customerName,
    buildingAddress,
    cvr,
    accessPeriod,
    status,
    gettext
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        p: 1,
        backgroundColor: "secondary.50",
        border: "1px solid",
        borderColor: "secondary.200",
        borderRadius: "8px",
        // minHeight: "48px",
        alignItems: "center"
      }}
    >
      <Grid
        display="flex"
        flexDirection="row"
        container
        spacing={2}
        alignItems="center"
        justifyItems="center"
      >
        {view === "Meters" && (
          <Grid item xs={12} sm={"auto"}>
            <InfoItem
              label={gettext("Building address")}
              value={buildingAddress}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={"auto"}>
          <InfoItem label={gettext("Company name")} value={customerName}/>
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <InfoItem label={gettext("CVR")} value={cvr}/>
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <InfoItem label={gettext("Access period")} value={accessPeriod}/>
        </Grid>

        <Grid item xs={12} sm={"auto"}>
          <InfoItem label={gettext("Status")} value={status}/>
        </Grid>
      </Grid>
    </Box>
  );
};

InfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

InfoBar.propTypes = {
  view: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  buildingAddress: PropTypes.string.isRequired,
  cvr: PropTypes.string.isRequired,
  accessPeriod: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  gettext: PropTypes.func.isRequired,
};

export default InfoBar;

