import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {UPDATE_SETTINGS} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to update settings.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{saveSettings: Function, loading: boolean}} - The function to save settings and the loading state.
 */
export function useUpdateSettings(
  onCompleted = () => null,
  onError = () => null) {
  const [updateSettings, {loading}] = useMutation(UPDATE_SETTINGS, {
    onCompleted: () => {
      onCompleted();
    },
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    refetchQueries: ["settings"],
    errorPolicy: "all"
  });

  const saveSettings = useCallback(
    async variables => {
      updateSettings({
        variables,
        refetchQueries: ["settings"]
      }).then();
    },
    [updateSettings]
  );

  return {
    saveSettings,
    loading
  };
}
