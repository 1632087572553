import {getMonthFromString, getShortMonthNameFromNumber} from "../../dates";

export const monthTotalOnYearSheet = (cell, field, monthSheetColumn) => {
  const monthNumber = getMonthFromString(field);
  if (monthNumber > 0) {
    const sheetName = getShortMonthNameFromNumber(monthNumber);
    cell.value = {
      formula: `${sheetName}!${monthSheetColumn}2`
    };
  }
  return cell;
};