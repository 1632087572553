import {FilterBar1, TableView2, TableView3} from "../../components";
import {Box} from "@mui/system";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {useTaxesInvoices} from "../../../hooks";
import {useTextDomainContext, useUser} from "../../providers";

/**
 * The Invoice component renders a table view of the invoice data.
 * @returns {React.ReactElement} The JSX Element.
 */
export const Invoice = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();

  const propsDefault = {
    electricalTrade: [
      {
        id: 'electrical-trade-spot',
        desc: gettext("Spot (60%)"),
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'electrical-trade-spot-supplement',
        desc: "Spot supplement (60%)",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'electrical-trade-fixed-price',
        desc: "Fixed price (40%)",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'electrical-trade-utility-regulator-fee',
        desc: "Utility Regulator Fee",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
    ],
    gridCost: [
      {
        id: 'grid-cost-discount-on-net-tariff-n1-as',
        desc: "Discount on net tariff N1 A/S",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'grid-cost-subscription-to-local-grid-supplier',
        desc: "Subscription to local grid supplier",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'grid-cost-discount-on-subscription-n1-as',
        desc: "Discount on subscription N1 A/S",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'grid-cost-net-tariff-to-local-grid-company',
        desc: "Net tariff to local grid company",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
    ],
    nationalTransmission: [
      {
        id: 'national-transmission-net-tariff',
        desc: "Transmissions net tariff",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'national-transmission-system-tariff',
        desc: "System tariff",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
      {
        id: 'national-transmission-tso-system-subscription',
        desc: "TSO - system subscription",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
    ],
    electricityFee: [
      {
        id: 'electricity-fee',
        desc: " Electricity Fee",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
    ],
    baseFee: [
      {
        id: 'base-fee',
        desc: "Base Fee",
        quantity: 423.6723434232,
        price: 51.517229,
        from: "2024-05-01T00:00:00",
        to: "2024-05-31T00:00:00",
        cost: 545.89,
        vat: 25
      },
    ],
  };


  const electricalTradeColumns = [
    {
      field: "desc",
      headerName: "Electrical Trade",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity (kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "price",
      headerName: "Net Price (øre/kWh)",
      type: "number",
      summable: true,
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "from",
      headerName: "From",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "to",
      headerName: "To",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "cost",
      headerName: "Net Cost (DKK)",
      // sortable: false,
      type: "number",
      width: 150,
      summable: true,
      valueFormatter: (value) => value && value.toFixed(2)
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "vat",
      headerName: "VAT",
      // sortable: false,
      type: "number",
      width: 100,
      valueFormatter: (value) => value && value.toFixed(2) + "%"
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
  ];
  const gridCostColumns = [
    {
      field: "desc",
      headerName: "Grid Cost",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity (kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "price",
      headerName: "Net Price (øre/kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "from",
      headerName: "From",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "to",
      headerName: "To",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "cost",
      headerName: "Net Cost (DKK)",
      // sortable: false,
      type: "number",
      width: 150,
      summable: true,
      valueFormatter: (value) => value && value.toFixed(2)
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "vat",
      headerName: "VAT",
      // sortable: false,
      type: "number",
      width: 100,
      valueFormatter: (value) => value && value.toFixed(2) + "%"
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
  ];
  const nationalTransmissionColumns = [
    {
      field: "desc",
      headerName: " National transmission system",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity (kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "price",
      headerName: "Net Price (øre/kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "from",
      headerName: "From",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "to",
      headerName: "To",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "cost",
      headerName: "Net Cost (DKK)",
      // sortable: false,
      type: "number",
      width: 150,
      summable: true,
      valueFormatter: (value) => value && value.toFixed(2)
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "vat",
      headerName: "VAT",
      // sortable: false,
      type: "number",
      width: 100,
      valueFormatter: (value) => value && value.toFixed(2) + "%"
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
  ];
  const electricityFeeColumns = [
    {
      field: "desc",
      headerName: "Electricity Fee (Potential compensation)",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity (kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "price",
      headerName: "Net Price (øre/kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "from",
      headerName: "From",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "to",
      headerName: "To",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "cost",
      headerName: "Net Cost (DKK)",
      // sortable: false,
      type: "number",
      width: 150,
      summable: true,
      valueFormatter: (value) => value && value.toFixed(2)
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "vat",
      headerName: "VAT",
      // sortable: false,
      type: "number",
      width: 100,
      valueFormatter: (value) => value && value.toFixed(2) + "%"
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
  ];
  const baseFeeColumns = [
    {
      field: "desc",
      headerName: "Base Fee",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity (kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "price",
      headerName: "Net Price (øre/kWh)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "from",
      headerName: "From",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "to",
      headerName: "To",
      // sortable: false,
      width: 150,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          returnValue = new Date(value);
        }
        return returnValue;
      },
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "cost",
      headerName: "Net Cost (DKK)",
      // sortable: false,
      type: "number",
      width: 150,
      summable: true,
      valueFormatter: (value) => value && value.toFixed(2)
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "vat",
      headerName: "VAT",
      // sortable: false,
      type: "number",
      width: 100,
      valueFormatter: (value) => value && value.toFixed(2) + "%"
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    }
  ];
  const totalColumns = [
    {
      field: "desc",
      headerName: "Total",
      flex: 1,
    },
    {
      field: "cost",
      headerName: "Net Cost (DKK)",
      type: "number",
      width: 150,
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "vat",
      headerName: "VAT",
      type: "number",
      width: 100,
      valueFormatter: (value) => value && value.toFixed(2) + "%"
    }
  ];

  let {
    currentTeam: customer,
    currentSubsidiary: group,
  } = user || {};

  if (!customer) {
    customer = "all";
  }
  if (!group) {
    group = "All";
  }

  // Tmp
  const electricalTrade = propsDefault.electricalTrade;
  const gridCost = propsDefault.gridCost;
  const nationalTransmission = propsDefault.nationalTransmission;
  const electricityFee = propsDefault.electricityFee;
  const baseFee = propsDefault.baseFee;
  // end Tmp

  const [totalRow, setTotalRow] = useState([]);
  const [building, setBuilding] = useState("all");
  const [meter, setMeter] = useState("all");
  const [energyType, setEnergyType] = useState("electricity");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  // const [electricalTrade, setElectricalTrade] = useState(propsDefault.electricalTrade);
  // const [gridCost, setGridCost] = useState(propsDefault.gridCost);
  // const [nationalTransmission, setNationalTransmission] = useState(propsDefault.nationalTransmission);
  // const [electricityFee, setElectricityFee] = useState(propsDefault.electricityFee);
  // const [baseFee, setBaseFee] = useState(propsDefault.baseFee);

  const filters = {
    customer,
    group,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: moment(fromDate).format("YYYY-MM-DDTHH:mm:ss"),
    toDate: moment(toDate).format("YYYY-MM-DDTHH:mm:ss")
  };
  const {
    data,
    loading
  } = useTaxesInvoices(filters);

  //#region React Hooks (useEffect)
  useEffect(() => {
    let costTotal = 0;
    electricalTrade.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    gridCost.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    nationalTransmission.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    electricityFee.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    baseFee.map((obj) => {
      costTotal += obj.cost;
      return null;
    });

    setTotalRow([
      {
        id: 1,
        desc: "Total amount subject to VAT",
        quantity: "",
        price: "",
        from: "",
        to: "",
        cost: costTotal,
        vat: ""
      },
    ]);
  }, [baseFee, electricalTrade, electricityFee, gridCost, nationalTransmission]);
  //#endregion React Hooks (useEffect)

  //#region Handlers
  const handleSelectedValues = (selectedValues) => {
    if (selectedValues?.building?.value) {
      setBuilding(selectedValues.building.value);
    }

    if (selectedValues?.meter?.value) {
      setMeter(selectedValues.meter.value);
    }

    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.date?.startDate) {
      setFromDate(selectedValues.date.startDate.toISOString());
    }

    if (selectedValues?.date?.endDate) {
      setToDate(selectedValues.date.endDate.toISOString());
    }
    if (loading) {
      // do nothing
    }
    if (data) {
      // do nothing
    }
  };
  //#endregion Handlers

  return (
    <>
      <FilterBar1
        getSelectedValues={handleSelectedValues}
        showBuilding
        showMeter
        showEnergyType
        showDate
        showBuildingAll
        showMeterAll
      />
      <br/>
      <Box sx={{
        border: 1,
        borderColor: "gray.300",
        marginX: "9px"
      }}>
        <TableView2
          rows={electricalTrade}
          columns={electricalTradeColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={gridCost}
          columns={gridCostColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={nationalTransmission}
          columns={nationalTransmissionColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={electricityFee}
          columns={electricityFeeColumns}
        />
        <br/>
        <br/>
        <TableView2
          rows={baseFee}
          columns={baseFeeColumns}
        />
        <br/>
        <br/>
        <TableView3
          rows={totalRow}
          columns={totalColumns}
        />
      </Box>
    </>
  );
};