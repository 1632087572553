import {useUser} from "../../providers";
import React, {useEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router";
import PropTypes from "prop-types";

/**
 *
 * @param props
 * @returns {Element}
 * @class
 */
export const RequirePermission = (props) => {
  const {codes} = props;
  const location = useLocation();

  const {user} = useUser();
  // const { isSuperAdmin } = user || {
  // };

  const {modulePermissions} = user || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (codes?.length) {
      codes.forEach(code => {
        if (code && modulePermissions && modulePermissions.length > 0) {
          const module = modulePermissions.find(item => item.code === code);

          // if (!(module?.read || module?.write) && !isSuperAdmin) {
          if (!(module)) {
            navigate("/error/error-v3", {
              replace: true,
              state: {
                from: location
              }
            });
          }
        }
      });
    } else {
      navigate("/error/error-v3", {
        replace: true,
        state: {
          from: location
        }
      });
    }
  }, [codes, location, modulePermissions, navigate]);

  return <Outlet/>;
};

RequirePermission.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string).isRequired
};
