import React, {useContext} from "react";
import {getPartnerName} from "../../../../../utils";
import {useTextDomainContext} from "../../../../providers";

export const CarouselItems = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const partnerName = getPartnerName();
  return [
    {
      name: gettext("Welcome to ") + partnerName + ".",
      heading: gettext("Get Full Visibility Over Your Energy Spending With Less Manual Work"),
      description: <>
        <strong>
          {gettext("Real-time, precise, and automated.")} &nbsp;
        </strong>
        {partnerName} &nbsp;
        {gettext("collects your energy data, prices and carbon emissions from all relevant data sources and present your energy consumption, prices, taxes, costs, and carbon emissions in our energy spending platform.")}
      </>
    },
    {
      name: gettext("Welcome to ") + partnerName + ".",
      heading: gettext("Effortless energy insights at your fingertips"),
      description: <>
        {partnerName} &nbsp;
        {gettext("gives you")} &nbsp;
        <strong>
          {gettext("real-time, accurate insights")} &nbsp;
        </strong>
        {gettext("into your energy usage, costs, and carbon footprint.")}
      </>
    },
    {
      name: gettext("Welcome to ") + partnerName + ".",
      heading: gettext("Gain control of your energy spending"),
      description: <>
        {gettext("Take control of your energy expenses with")} {partnerName}&#x2019s &nbsp;
        <strong>
          {gettext("comprehensive data and insights")}
        </strong>
      </>
    }
  ];
};
