import { gql } from "@apollo/client";

export const DELETE_DEAL = gql`
  mutation deleteEnergyDeal($ids: [String]!) {
    deleteEnergyDeal(ids: $ids) {
      success
      message
    }
  }
`;
