import {useTextDomainContext} from "../../providers";
import React, {useContext} from "react";
import {ChangePasswordForm} from "../";
import {ModalBox} from "./ModalBox";
import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import PropTypes from "prop-types";

export const ChangePasswordModal = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // const partnerName = getPartnerName();

  return (
    <ModalBox open={props.open} handleClose={props.handleClose}>
      <Box sx={{
        backgroundColor: (theme) => theme.palette.grey[50],
        p: 4
      }}>
        <Box sx={{
          textAlign: "center",
          pb: 3
        }}>
          <Typography variant="h4">
            {gettext("Change password")}
          </Typography>
        </Box>
        <Box>
          <ChangePasswordForm handleClose={props.handleClose}/>
        </Box>
      </Box>
    </ModalBox>
  );
};

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};