import React, {useContext} from "react";
import {useTextDomainContext} from "../providers";

// import { useSubheader } from "../../_metronic/layout";

export const MyPage = () => {
  // const suhbeader = useSubheader();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  // suhbeader.setTitle("My Custom title");

  return (
    <>
      <h1>{gettext("Coming Soon")}</h1>
    </>
  );
};
