import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";
import qs from "qs";
import {useFormik} from "formik";
import {Navigate, useLocation, useParams} from "react-router";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {convertErrorObject, resetPassword} from "./_redux/authCrud";
import ReactGA from "react-ga4";
import Icon from "@mdi/react";
import {mdiEyeOffOutline, mdiEyeOutline} from "@mdi/js";
import PropTypes from "prop-types";

const initialValues = {
  email: "",
  password: "",
  password_confirmation: "",
  token: ""
};

/**
 *
 * @param props
 */
export const RecoverPassword = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const params = useParams();
  const location = useLocation();
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  }, [props, location.pathname]);

  const {intl} = props;
  const [isRequested, setIsRequested] = useState(false);
  const RecoverPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(255, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password_confirmation: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
      .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        )
      })
  });

  initialValues.token = params.code;
  initialValues.email = qs.parse(location.search, {
    ignoreQueryPrefix: true
  }).email;

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RecoverPasswordSchema,
    onSubmit: (values, {
      setStatus,
      setSubmitting
    }) => {
      resetPassword(
        values.email,
        values.password,
        values.password_confirmation,
        values.token
      )
        .then(() => {
          setIsRequested(true);
        })
        .catch(err => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(convertErrorObject(err.data.errors));
          // setStatus(
          //     intl.formatMessage(
          //         {id: "AUTH.VALIDATION.NOT_FOUND"},
          //         {name: values.email}
          //     )
          // );
        });
    }
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      {isRequested && <Navigate to="/auth"/>}
      {!isRequested && (
        <div className="login-form login-recover" style={{
          display: "block"
        }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{gettext("Recover Password")}</h3>
            <div className="text-muted font-weight-bold">
              {gettext("Enter your new password to reset")}
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* begin: Email */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="form-group fv-plugins-icon-container">
                <input
                  type="hidden"
                  name="token"
                  {...formik.getFieldProps("token")}
                />
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-3 px-6 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* end: Email */}

            {/* begin: Password */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  type={passwordShown ? "text" : "password"}
                  className={`change-password form-control form-control-solid h-auto py-3 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                <button
                  type="button"
                  className="show-hide-password recovery"
                  onClick={togglePassword}
                >
                  <Icon
                    path={passwordShown ? mdiEyeOutline : mdiEyeOffOutline}
                    title="Password"
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color="#7a6969"
                  />
                </button>
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* end: Password */}

            {/* begin: Confirm Password */}
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Confirm Password"
                  type={passwordShown ? "text" : "password"}
                  className={`form-control form-control-solid h-auto py-3 px-6 ${getInputClasses(
                    "password_confirmation"
                  )}`}
                  name="password_confirmation"
                  {...formik.getFieldProps("password_confirmation")}
                />
                {formik.touched.password_confirmation &&
                formik.errors.password_confirmation ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password_confirmation}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {/* end: Confirm Password */}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_recover_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-3 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                {gettext("Submit")}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_recover_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-3 my-3 mx-4"
                >
                  {gettext("Cancel")}
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

RecoverPassword.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
};