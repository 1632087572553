/* global process */
// import React from "react";
// import KTDialog from "../../_metronic/_assets/js/components/dialog";

export const DECIMAL = 2;

// API URLs
const {REACT_APP_API_URL} = process.env;
const API_URL = REACT_APP_API_URL;

export const COT2_BUILDINGS_URL = API_URL + "buildings";
export const COMPANIES_URL = API_URL + "address/companies";
export const CO2_INTRODUCTION_POST_URL = API_URL + "user/setting";
export const CO2_INTRODUCTION_GET_URL = API_URL + "user/setting";
export const SETTINGS_URL = API_URL + "user/setting";
export const SINGLE_SETTINGS_URL = API_URL + "user/get-user-single-settings";
export const REPORT_CONSUMER_TABLE_URL = API_URL + "test/electricity/table";
export const REPORT_CONSUMER_GRAPH_URL = API_URL + "test/electricity/graph";
export const REPORT_CONSUMER_URL =
  REACT_APP_API_URL + "/data/v2/api/by_address?";
export const DASHBOARD_DATA_URL = API_URL + "data/dashboard";
export const CORPORATE_CLIMATE_REPORT = API_URL + "test/climate-report";
export const CORPORATE_ENERGY_PROCESS = API_URL + "test/energy-process";
export const CORPORATE_PURCHASE = API_URL + "test/purchase";
export const CORPORATE_SOLD_PRODUCTS = API_URL + "test/sold-products";
export const CORPORATE_TRANSPORT = API_URL + "test/transport";
export const CORPORATE_WASTE_RECYCLING = API_URL + "test/waste-recycling";
export const SETTINGS_BUILDING_TABLE_URL = API_URL + "test/building";
export const GET_CUSTOMER_URL = REACT_APP_API_URL + "/customer/item/";
export const GET_INITIATIVES = API_URL + "test/initiatives";
// export const CO2_REPORT_URL = API_URL + "data/report";
export const ENERGY_COMPARISON_URL = API_URL + "data/v2/api/by_address?";
// export const ENERGY_COMPARISON_URL = API_URL_LARAVEL + "data/energy-report";
export const USER_UPDATE_URL = API_URL + "user/update_profile";
export const ADDRESS_CLEANER_URL = API_URL + "admin/address_cleaner";

// export const USER_UPDATE_REDIRECT_STATS_URL = REACT_APP_API_URL + "api/user/save-redirect-stats";
// export const USER_UPDATE_REDIRECT_STATS_URL = API_URL_LARAVEL_BASE + "/api/user/save-redirect-stats";
export const USER_UPDATE_REDIRECT_STATS_URL = API_URL + "v1/redirect_stats";
export const REFRESH_AUTH_DATA1 = REACT_APP_API_URL + "/api/user/refresh-auth";

export const REFRESH_AUTH_DATA = REACT_APP_API_URL + "/admin/refresh";
export const REFRESH_TEAM_AUTH_DATA = REACT_APP_API_URL + "/v1/teams/refresh";

// export const CO2_REPORT_URL = REACT_APP_API_URL + "api/co2_report/test";
export const COUNTRY_ALL_URL = REACT_APP_API_URL + "/api/countries/all/dropdown";
export const COMPARE_BY_ADDRESS =
  REACT_APP_API_URL + "data/v2/api/compare_by_address";

export const COMPANY_ADDRESSES = API_URL + "/user/get-company-address";
export const SAVE_COMPANY_ADDRESS_DETAILS =
  API_URL + "user/update-company-address";

export const UPLOAD_CSV_FILE_FORMATE = REACT_APP_API_URL + "/data/manual_data";

// for user settings
export const ACCOUNT_SETTINGS_DATA = API_URL + "/user/get-user-settings";
export const SAVE_ACCOUNT_SETTINGS_DATA = API_URL + "/user/save-user-settings";

export const GRAPH_IMAGES = API_URL + "v1/graph_images/";
export const PUBLIC_GRAPH_IMAGES = API_URL + "v1/public_images/";

// PUBLIC PAGE
export const TEAMS_SETTINGS = API_URL + "v1/team_settings/get_all/";
export const POST_TEAMS_SETTINGS = API_URL + "v1/team_settings/add_all/";
// PUBLIC PAGE
export const GET_PAGE_DATA = API_URL + "v1/team_settings/get_all/";
export const GET_GRAPH_IMAGES = API_URL + "v1/public_images/";
export const GET_TEAM_ID = API_URL + "/v1/teams/get_team_id/";
export const GET_TEAM_SEF = API_URL + "/v1/teams/";
export const GET_GRAPHS = API_URL + "/v1/graph_images/";

//Meters
export const GET_SUPPLIERS = API_URL + "v1/energy_suppliers/";
export const ADD_METERS_DATA = API_URL + "/v1/buildings/meters/load_data";

// Registration Flow
export const GET_CONTRIEST_LIST = API_URL + "/v1/countries";
export const GET_TIMEZONES_LIST = API_URL + "/v1/timezones"; // ?country_code=DK

// Roles Modules
export const GET_USER_TYPE_PERMISSIONS = API_URL + "v1/permissions/users/";

export const GET_TEAM_USERS_USERS = API_URL + "/v1/team_users/users/";

export const GET_SCRAPPER_LOG = API_URL + "v1/admin/scrapper_logs"; // ?scrapper_name=heat_data_scrapper4&created_at=2022-03

// For Graph
export const CO2_COLOR = "#c0c7b5";
export const ELECTRICITY_COLOR = "#adb7c9";
export const HEAT_COLOR = "#dc562b";
export const WATER_COLOR = "#004BFF";
export const ENERGY_COLOR = "#485561";
export const PRODUCTIION_COLOR = "#6fa8dc";
export const COMPENSATIION_COLOR = "#015731";
export const GAS_COLOR = "#ffc442";

export const YEAR3_ELECTRICITY_COLOR = "#05CD99";
export const YEAR2_ELECTRICITY_COLOR = "#030C24";
export const YEAR1_ELECTRICITY_COLOR = "#426A2F";

export const YEAR_PRODUCTION_COLOR = "#030C24";
export const YEAR_COMPENSATION_COLOR = "#05CD99";

export const YEAR3_HEAT_COLOR = "#05CD99";
export const YEAR2_HEAT_COLOR = "#030C24";
export const YEAR1_HEAT_COLOR = "#FFC442";

export const YEAR3_WATER_COLOR = "#05CD99";
export const YEAR2_WATER_COLOR = "#030C24";
export const YEAR1_WATER_COLOR = "#004BFF";

export const YEAR3_GAS_COLOR = "#05CD99";
export const YEAR2_GAS_COLOR = "#030C24";
export const YEAR1_GAS_COLOR = "#004BFF";

export const PRODUCTION_COLOR = "#030C24";
export const COMPENSATION_COLOR = "#05CD99";

export const ELECTRICITY_FORMULA = 212 * 0.001 * 0.001;

// export const loading = new KTDialog({
//   type: "loader",
//   placement: "top center",
//   message: "Loading ..."
// });

export const MONTH_NAMES_FULL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const MONTH_NAMES_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const emptyBarChart = {
  // "unique_id": chartId,
  type: "bar",
  chart_title: "...",
  yaxisTitleText: "...",
  categories: ["..."],
  data: [
    {
      name: "...",
      data: [0]
    }
  ]
};

export const emptyPieChart = {
  // "unique_id": chartId,
  type: "pie",
  chart_title: "...",
  yaxisTitleText: "...",
  labels: ["...", "...", "..."],
  colors: [ELECTRICITY_COLOR, HEAT_COLOR, WATER_COLOR],
  data: [33, 33, 34]
};

export const EPCTableValues = [
  {
    name: "A1", len: 3, min: 0, max: 52.5, position: -300, color: "#12793F"
  },
  {
    name: "A2",
    len: 4,
    min: 52.5,
    max: 70,
    position: -200,
    color: "#369E46"
  },
  {
    name: "B", len: 5, min: 70, max: 110, position: -100, color: "#7FB941"
  },
  {
    name: "C", len: 6, min: 110, max: 150, position: 0, color: "#CDB32E"
  },
  {
    name: "D", len: 7, min: 150, max: 190, position: 100, color: "#E2882E"
  },
  {
    name: "E", len: 8, min: 190, max: 240, position: 200, color: "#DA5430"
  },
  {
    name: "F",
    len: 10,
    min: 240,
    max: "Inf",
    position: 300,
    color: "#A3252F"
  }
];

export const DASBOARD_FILTER_LS_KEY = "fabekeDasboardFilters";
