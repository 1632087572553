import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PropTypes from "prop-types";

/**
 *
 * @param props
 */
export default function PageIndicator(props) {
  const {
    view,
    setView,
    // gettext,
    crumbs
  } = props;

  /**
   *
   * @param value
   */
  function handleClick(value) {
    if (value !== 0 && setView) {
      setView(value);
    }
  }

  const getCrumbs = () => {
    const index = crumbs.findIndex(crumb => crumb.value === view);
    const filteredCrumbs = index >= 0 ? crumbs.slice(0, index + 1) : crumbs;
    return filteredCrumbs.map((crumb) => (
      // <React.Fragment key={crumb.label}>
      <Link
        key={crumb.label}
        underline="none"
        color={view === crumb.value ? "#27272A" : "inherit"}
        onClick={() => handleClick(crumb.value)}
      >
        {crumb.label}
      </Link>
      // </React.Fragment>
    ));
  };

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small"/>}
      aria-label="breadcrumb"
      fontSize="14px"
      pb={1}
    >
      {getCrumbs()}
    </Breadcrumbs>
  );
}

PageIndicator.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })).isRequired,
  setView: PropTypes.func,
  view: PropTypes.string,
};