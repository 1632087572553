import {gql} from "@apollo/client";

export const GET_ENERGY_PRICES_FILE = gql`
  query dataResourcePrice(
    $dataDate: Date
    $country: String!
    $fileType: String
    $currency: String
  ) {
    dataResourcePrice(
      dataDate: $dataDate
      country: $country
      fileType: $fileType
      currency: $currency
    ) {
      fileUrl
    }
  }
`;