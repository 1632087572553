import { gql } from "@apollo/client";

export const GET_DEALS_LIST = gql`
  query GetAllDeals (
    $searchString:  String,
    $customer:  String,
    $building:  String,
    $meter:  String,
    $energyType: String,
    $fromDate: DateTime,
    $toDate: DateTime
    ) {
    getAllDeals (
      searchString:  $searchString,
      customer:  $customer,
      building:  $building,
      meter:  $meter,
      energyType:  $energyType,
      fromDate: $fromDate,
      toDate: $toDate
    ) {
      id: Id
      Id
      customer
      building
      commissionPrice
      commissionShare
      createdAt
      currency
      energyType
      epadKwh
      epadPrice
      epadShare
      epadType
      fromDate
      mainPriceType
      markupPrice
      markupShare
      meter
      priceKwh
      pricePrice
      priceShare
      priceType
      profileKwh
      profilePrice
      profileShare
      profileType
      systemKwh
      systemPrice
      systemShare
      systemType
      toDate
      updatedAt
    }
  }
`;
