import {OverallStatement} from "../../components";
import React from "react";

export const EnergyStatement = () => {

  return (
    <OverallStatement
      statementType="energy"
    />
  );
};