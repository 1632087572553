import React from "react";
import {UserBoxLink, UserTopBox} from "./components";

/**
 *
 * @returns {React.JSX.Element}
 * @class
 */
export function Profile() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UserBoxLink onClick={handleClick}/>
      <UserTopBox anchorEl={anchorEl} onClose={handleClose}/>
    </>
  );
}
