/**
 * Converts grid data to an exportable array format.
 * @param {Array<object>} columns - Array of column definitions.
 * @param {Array<object>} rows - Array of row data.
 * @returns {Array<object>} The formatted data ready for export.
 */
export const gridDataToExportArray = (columns, rows) => {
  const finalArray = [];
  rows.forEach((row) => {
    const finalRow = {};
    columns.forEach((column) => {
      switch (column.headerName) {
        case "Actions":
        case "Action":
        case "ID":
          // Dont add
          break;
        default:
          finalRow[column.headerName] = row[column.field];
          break;
      }

    });
    finalArray.push(finalRow);
  });
  return finalArray;
};
