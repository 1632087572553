import {gql} from "@apollo/client";

export const GET_ENERGY_PRICES = gql`
  query dataResourcePrice($dataDate: Date, $country: String!) {
    dataResourcePrice(dataDate: $dataDate, country: $country) {
      data {
        values
        yesterday
        today
        tomorrow
        countryCode
        message
      }
    }
  }
`;