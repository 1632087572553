import {initIDB} from "./";

export const indexedDbGetById = (dbName, id) => {
  return new Promise((resolve) => {
    // (resolve, reject)
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readonly");
      const store = txn.objectStore(dbName);
      const query = store.get(id);
      query.onsuccess = function (event) {
        if (!event.target.result) {
          resolve(null);
        } else {
          resolve(event.target.result);
        }
      };
      query.onerror = function () {
        // event
        resolve(null);
      };
      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};