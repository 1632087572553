import {UsersList} from "../../components";
import React from "react";

export const CompanyUsers = () => {
  return (
    <UsersList
      // showAdd
      // showEdit
      // showDeleteSingle
      // showChangePassword
      // showCompanies
      userFilter="company"
    />
  );
};
