import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {DELETE_ROLE} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to delete a role.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{deleteRole: Function, deleting: boolean}} - The function to delete a role and the loading state.
 */
export function useDeleteRole(
  onCompleted = () => {
  },
  onError = () => {
  }) {
  const [deleteRole, {loading}] = useMutation(DELETE_ROLE, {
    onCompleted,
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        "Delete Role: " + err.message,
        {
          variant: "error"
        }
      );
    },
    errorPolicy: "all"
  });

  const deleteRoleRow = useCallback(
    variables => {
      deleteRole({
        variables,
        refetchQueries: ["getAllRoles"]
      }).then();
    },
    [deleteRole]
  );

  return {
    deleteRole: deleteRoleRow,
    deleting: loading
  };
}
