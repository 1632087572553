import { useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_ROLE } from "./commands";

export const useAddRole = (
  onCompleted = () => {
  },
  onError = () => {
  }
) => {
  const [success, setSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const [addRole, { loading, data }] = useMutation(ADD_ROLE, {
    onCompleted: response => {
      if (response.comAddRole?.success) {
        setSuccess(true);
        setResetForm(true);
        setTimeout(() => {
          setSuccess(false);
          setResetForm(false);
        }, 5000);
        onCompleted();
      } else {
        setSuccess(false);
      }
    },
    onError: ({ message }) => {
      setSuccess(false);
      onError(message);
    }
  });
  return {
    addRole, loading, success, data, resetForm
  };
};
