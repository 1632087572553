import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {CommonDropdown} from "./CommonDropdown";
import {getMonthsArray} from "../../../utils";
import {useTextDomainContext} from "../../providers";

export const MonthsDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  // console.log(locale);
  const [options, setOptions] = React.useState(getMonthsArray(gettext));
  useEffect(() => {
    setOptions(getMonthsArray(gettext));
  }, [gettext]);

  return (
    <CommonDropdown
      options={options}
      componentLabel={""}
      showAll={false}
      // defaultValue={"all"}
      onChange={props.onChange}
    />
  );
};

MonthsDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
