export const getPartnerDetails = () => {
  let partnerKey = "";
  let partnerName = "";

  // const {user} = users();
  // const {partnerList} = usePartnersList();
  // if (user?.partner?.appUrl) {
  //   partnerKey = user?.partner?.key;
  //   partnerName = user?.partner?.name;
  // } else {
  //   partnerList.forEach((row, index) => {
  //     const rowDomain = (new URL(row.appUrl));
  //     const rowHostname = rowDomain.hostname;
  //     if (rowHostname === window.location.hostname) {
  //       partnerKey = row.key;
  //       partnerName = row.name;
  //     }
  //     return {
  //       partnerKey: partnerKey,
  //       partnerName: partnerName
  //     };
  //   });

  if (!partnerKey) {
    switch (window.location.hostname) {
      // case "localhost":
      case "app.absolutesight.com":
      case "app-dev.absolutesight.com":
      case "app-stage.absolutesight.com":
        partnerKey = "absolute-sight";
        partnerName = "Absolute Sight";
        break;
      // case "localhost":
      case "app.energiteam.dk":
      case "energiteam.fabeke.com":
      case "energiteam-dev.fabeke.com":
      case "energiteam-stage.fabeke.com":
      case "energyteam.fabeke.com":
      case "energyteam-dev.fabeke.com":
      case "energyteam-stage.fabeke.com":
        partnerKey = "energiteam";
        partnerName = "Energi Team";
        break;
      case "localhost":
      case "app.fabeke.com":
      case "app-dev.fabeke.com":
      case "app-stage.fabeke.com":
        partnerKey = "fabeke";
        partnerName = "Fabeke";
        break;
      default:
        partnerKey = "default";
        partnerName = "Our Company";
        break;
    }
  }
  // }

  return {
    partnerKey,
    partnerName
  };
};
