import {useQuery} from "@apollo/client";
import {GET_DEALS_LIST} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to fetch the list of deals.
 * @param {object} props - The properties to be passed to the query.
 * @returns {{dealsList: Array<object>, loading: boolean}} - The list of deals and the loading state.
 */
export function useDealsList(props) {
  const propsDefault = {
    searchString: null,
    customer: null,
    building: null,
    meter: null,
    energyType: null,
    fromDate: null,
    toDate: null
  };
  const variables = {
    ...propsDefault, ...props
  };

  const {
    data,
    loading
  } = useQuery(GET_DEALS_LIST, {
    variables: variables,
    onError: (err) => {
      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    dealsList: data?.getAllDeals || [],
    loading
  };
}
