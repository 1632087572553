import {useMutation} from "@apollo/client";
import {useUser} from "../../app/providers";
import {useCallback} from "react";
import {UPDATE_CURRENT_USER_PROFILE} from "./commands";

/**
 * Custom hook to update the user profile.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{updateProfile: Function, loading: boolean}} - The function to update the profile and the loading state.
 */
export function useUpdateProfile(
  onCompleted = () => {
  },
  onError = () => {
  }
) {
  const {setUser} = useUser();

  const [comUpdateAccount, {loading}] = useMutation(
    UPDATE_CURRENT_USER_PROFILE,
    {
      onCompleted: ({comUpdateAccount}) => {
        setUser(prev => {
          return {
            ...prev, ...comUpdateAccount?.user
          };
        });
        onCompleted(comUpdateAccount);
      },
      onError
    }
  );
  const updateProfile = useCallback(
    variables => {
      comUpdateAccount({
        variables,
        refetchQueries: ["Me"]
      });
    },
    [comUpdateAccount]
  );
  return {
    updateProfile,
    loading
  };
}
