import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import './Styles/LineChart.css';
import {toLocaleNumber} from "../../../utils";
import {useLang} from "../../providers";
import PropTypes from "prop-types";

export const LineChart = (props) => {
  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]);
  const svgRef = useRef();
  const locale = useLang();

  useEffect(() => {
    if (props?.data?.dataset) {
      setData(props?.data?.dataset);
    }
  }, [props?.data?.dataset]);

  useEffect(() => {
    if (props?.data?.series) {
      setSeries(props?.data?.series);
    }
  }, [props?.data?.series]);

  useEffect(() => {
    if (!svgRef.current) return;

    const keyColors = {};
    const keyLabels = {};
    const keyUnits = {};
    const colors = [];
    const fields = [];

    series.map((obj) => {
      fields.push(obj.dataKey);
      if (obj.color) {
        keyColors[obj.dataKey] = obj.color;
        colors.push(obj.color);
      } else {
        keyColors[obj.dataKey] = "#000";
        colors.push("#000");
      }
      if (obj.label) {
        keyLabels[obj.dataKey] = obj.label;
      } else {
        keyLabels[obj.dataKey] = "";
      }
      if (obj.unit) {
        // keyUnits[obj.dataKey] = ` (${obj.unit})`;
        keyUnits[obj.dataKey] = ` ${obj.unit}`;
      } else {
        keyUnits[obj.dataKey] = "";
      }
      return null;
    });

    d3.select(svgRef.current).selectAll('*').remove();

    // Remove any existing tooltips to avoid duplication
    d3.select(`#tooltip-${props.chartId}`).remove();

    const tooltip = d3.select('body')
      .append('div')
      .attr('id', `tooltip-${props.chartId}`)
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    const margin = {
      top: 20,
      right: 30,
      bottom: 40,
      left: 40
    };

    const width = svgRef.current.clientWidth - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const barWidth = 0;
    const gapWidth = 5;

    const x = d3.scaleBand()
      .domain(data.map(d => d.label))
      .range([0, width])
      .paddingInner(gapWidth / (barWidth + gapWidth))
      .paddingOuter(barWidth / (barWidth + gapWidth))
      .align(0.5);

    // const x = d3.scaleTime()
    //   .domain(data.map(d => d.label))
    //   .range([margin.left, width - margin.right]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => Math.max(...fields.map(field => d[field] || 0)))])
      .nice()
      .range([height, 0]);

    const color = d3.scaleOrdinal()
      .domain(fields)
      .range(colors);

    const line = d3.line()
      .x(d => x(d.label) + x.bandwidth() / 2)
      .y(d => y(d.value));

    const area = d3.area()
      .x(d => x(d.label) + x.bandwidth() / 2)
      .y0(y(0))
      .y1(d => y(d.value));

    // Add horizontal grid lines
    svg.append('g')
      .attr('class', 'grid')
      .attr('color', '#e5e7eb')
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat(''));

    // Add vertical grid lines
    svg.append('g')
      .attr('class', 'grid')
      .attr('color', '#e5e7eb')
      .call(d3.axisBottom(x)
        .tickSize(-height)
        .tickFormat(''))
      .attr('transform', `translate(0,${height})`);

    fields.forEach(field => {
      const lineData = data.map(d => ({
        label: d.label,
        value: d[field]
      }));

      // Add the area
      svg.append('path')
        .datum(lineData)
        .attr('fill', color(field))
        .attr('fill-opacity', 0.1) // Adjust the opacity for semi-transparency
        .attr('stroke', 'none')
        .attr('d', area);

      // Add the line
      svg.append('path')
        .datum(lineData)
        .attr('fill', 'none')
        .attr('stroke', color(field))
        .attr('stroke-width', 1)
        .attr('d', line);

      svg.selectAll(`circle-${field}`)
        .data(lineData)
        .enter()
        .append('circle')
        .attr('class', `circle-${field}`)
        .attr('cx', d => x(d.label) + x.bandwidth() / 2)
        .attr('cy', d => y(d.value))
        .attr('r', 4)
        .attr('stroke', color(field))
        .attr('stroke-width', 1)
        .attr('fill', "#fff");
      // .attr('fill', color(field));
    });

    svg.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat((d, i) => {
        const labelLength = d.length;
        const chartWidth = svgRef.current.clientWidth - 100;
        const extraMarginCharacters = data.length * 2;
        const totalCharacters = data.length * labelLength;
        const pixelPerCharacter = chartWidth / totalCharacters;
        const oneLabelPixels = pixelPerCharacter * labelLength;
        const maxLabels = Math.ceil(chartWidth / (oneLabelPixels + extraMarginCharacters)) - 1;

        return (data.length > 12 && i % Math.ceil(data.length / maxLabels) !== 0) ? '' : d;
      }));

    svg.append('g')
      .attr('class', 'axis')
      .call(d3.axisLeft(y));

    // Vertical line
    const verticalLine = svg.append('line')
      .attr('class', 'vertical-line')
      .attr('stroke', 'black')
      .attr('y1', 0)
      .attr('y2', height)
      .style('opacity', 0);

    // Overlay for capturing mouse movements
    svg.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('opacity', 0)
      .on('mousemove', function (event) {
        const tooltipWidth = 200;
        const mouseX = d3.pointer(event)[0];

        // const mouseX = event.pageX;
        const mouseY = event.pageY;

        const x0 = x.invert ? x.invert(mouseX) : Math.round(mouseX / (width / data.length));
        const d = data[Math.min(data.length - 1, Math.max(0, Math.round(x0)))];

        const tooltipX = event.pageX + 15;
        // const tooltipY = event.pageY - 28;

        // const tooltipX = mouseX + 15;
        // const tooltipX = mouseX;
        const tooltipY = mouseY - 28;

        if (!d) return;

        verticalLine.attr('x1', x(d.label) + x.bandwidth() / 2)
          .attr('x2', x(d.label) + x.bandwidth() / 2)
          .style('opacity', 1);

        const tooltipContent = `
          <div style="padding: 0; margin: 0">
            <div style="text-align: left; font-weight: bold; padding: 10px;">
              ${d.label}
            </div>
            <div style="border-bottom: 1px solid #ccc"></div>
            ${fields.map(field => `
              <div style="text-align: left; padding: 10px;">
                <div style="float: left; height: 12px; width: 12px; margin-right: 10px; background-color:${color(field)}; border-radius: 100%;"></div>
                ${keyLabels[field]}: ${toLocaleNumber(locale, d[field] || 0)}${keyUnits[field]}
                <!-- ${keyLabels[field]}: <span style="color:${color(field)}">${toLocaleNumber(locale, d[field] || 0)}${keyUnits[field]}</span> -->
              </div>
            `).join('')}
          </div>
        `;

        tooltip.html(tooltipContent)
          .style('left', `${Math.min(tooltipX, window.innerWidth - tooltipWidth)}px`)
          .style('top', `${Math.min(tooltipY)}px`)
          // .style('left', `${Math.min(tooltipX, window.innerWidth - 200)}px`)
          // .style('top', `${Math.min(tooltipY, window.innerHeight - 150)}px`)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        verticalLine.style('opacity', 0);
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

  }, [data, series, props.chartId, locale]);

  return (
    <div style={{
      position: 'relative'
    }}>
      <svg ref={svgRef} style={{
        width: '100%'
      }}></svg>
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.object,
  series: PropTypes.array,
  chartId: PropTypes.string,
  locale: PropTypes.string
};