import React, {useContext} from "react";
import {Button, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useTextDomainContext, useUser} from "../../providers";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";

/**
 *
 */
export function VerificationEmailSent() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const navigate = useNavigate();
  const {user} = useUser();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        marginTop: "100px"
      }}
    >
      <Grid item>
        <MarkEmailUnreadIcon
          color="primary"
          sx={{
            width: "64px",
            height: "64px"
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize: 36,
            fontWeight: 700,
            lineheight: 24
          }}
        >
          {gettext("Thanks for signing up!")}
        </Typography>
      </Grid>
      <Grid item direction="column" alignItems="center" justify="center">
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: "#52525B",
            lineheight: 14
          }}
        >
          {gettext(
            "Before getting started, could you verify your email address by "
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          sx={{
            mt: -2,
            fontSize: 18,
            fontWeight: 500,
            color: "#52525B",
            lineheight: 14
          }}
        >
          {gettext("clicking the link we just emailed you at ")}
          {user?.email}?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{
            backgroundColor: "#27272A",
            "&:hover": {
              backgroundColor: "#27272A"
            }
          }}
          variant="contained"
          size="large"
          endIcon={<ArrowForwardIcon/>}
          onClick={() => navigate("/")}
        >
          {gettext("Continue")}
        </Button>
      </Grid>
    </Grid>
  );
}
