export const success = {
  50: "#e4f8ec",
  100: "#bdeecf",
  200: "#91e2af",
  300: "#64d68e",
  400: "#43ce76",
  500: "#22c55e",
  600: "#1ebf56",
  700: "#19b84c",
  800: "#14b042",
  900: "#0ca331",
  A100: "#d1ffda",
  A200: "#9effb2",
  A400: "#6bff89",
  A700: "#51ff75"
};
