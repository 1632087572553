export const primary = {
  50: "#e4f3ec",
  100: "#bee1d0",
  200: "#98ceb3",
  300: "#75bb96",
  400: "#60ab83",
  500: "#539c72",
  600: "#4d8f67",
  700: "#457e59",
  800: "#3c6e4d",
  900: "#2e5236"
};
