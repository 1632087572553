import React, {useContext, useEffect, useState} from "react";
import {CommonDropdown} from "./CommonDropdown";
import PropTypes from "prop-types";
import {useTextDomainContext} from "../../providers";

export const ConsumptionOrCo2Dropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const options = [
    {
      value: "consumption",
      label: gettext("Energy"),
    },
    {
      value: "climate",
      label: gettext("Climate"),
    },
  ];
  const propsDefault = {
    componentLabel: gettext("Report")
  };
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);

  useEffect(() => {
    if (typeof props.componentLabel !== "undefined") {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel]);

  return (
    <CommonDropdown
      options={options}
      componentLabel={componentLabel}
      showAll={false}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
    />
  );
};

ConsumptionOrCo2Dropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};
