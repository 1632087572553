export const getSessionStorageSize = () => {
  let total = 0;
  for (const key in sessionStorage) {
    // if (sessionStorage.hasOwnProperty(key)) {
    if (Object.prototype.hasOwnProperty.call(sessionStorage, key)) {
      const value = sessionStorage[key];
      total += key.length + value.length;
    }
  }
  return total;
};
