import {Grid} from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import {useDataHubMeterDetailsYearly} from "./hooks";
import PropTypes from "prop-types";
import React from "react";

const MeterDetailsYearly = props => {
  const {
    view,
    setView,
    meteringPointId,
    date,
    setDate,
    selectedSegment,
    setSelectedSegment,
    gettext
  } = props;
  const {meterDetailYearly, loading} = useDataHubMeterDetailsYearly(
    meteringPointId,
    date
  );

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={meterDetailYearly}
        selectededDate={date}
        segment={selectedSegment}
        getSelectedDate={(date) => {
          setDate(date);
        }}
        onSegmentChange={selectedSegment => {
          setDate(date);
          setSelectedSegment(selectedSegment);
        }}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default MeterDetailsYearly;


MeterDetailsYearly.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  meteringPointId: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  selectedSegment: PropTypes.string,
  setSelectedSegment: PropTypes.func,
  gettext: PropTypes.func
};