import {UsersList} from "../../components";
import React from "react";

export const PartnerUsers = () => {
  return (
    <UsersList
      // showAdd
      // showEdit
      // showDeleteSingle
      // showChangePassword
      // showCompanies
      userFilter="partner"
    />
  );
};
