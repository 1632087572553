import {Grid} from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import {useDataHubBuildingMeters} from "./hooks";
import PropTypes from "prop-types";
import React from "react";

const DatahubMeters = props => {
  const {
    view,
    setView,
    meterIdList,
    setSelectedItem,
    selectedItem,
    gettext
  } = props;
  const {metersList, loading} = useDataHubBuildingMeters(meterIdList);

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={metersList}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default DatahubMeters;

DatahubMeters.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  meterIdList: PropTypes.array.isRequired,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
  gettext: PropTypes.func
};