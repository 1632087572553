import {useQuery} from "@apollo/client";
import {GET_COMPANIES_BUILDINGS} from "./commands";

/**
 * Custom hook to fetch company buildings.
 * @param {string} customerNamesString - The customer names as a comma-separated string.
 * @param {string} customerSubsidiaryString - The customer subsidiaries as a comma-separated string.
 * @param {Function} [onCompleted] - Callback function to be called when the query completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the query.
 * @returns {{buildings: Array<object>, loading: boolean, error: object}} - The list of company buildings, loading state, and error.
 */
export function useCompanyBuildings(
  customerNamesString,
  customerSubsidiaryString,
  onCompleted = () => {
  },
  onError = () => {
  }
) {
  const {
    data,
    loading
  } = useQuery(GET_COMPANIES_BUILDINGS, {
    variables: {
      customerName: customerNamesString,
      subsidiaryName: customerSubsidiaryString
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    onCompleted: ({comCustomerAddresses}) => {
      onCompleted(comCustomerAddresses || []);
    },
    onError
  });

  return {
    buildings: data?.getBuildings || [],
    loading
  };
}
