import { gql } from "@apollo/client";

export const GET_ALL_PARTNERS = gql`
query getPartners {
    getPartners{
        id
        createdAt
        createdBy
        updatedAt
        updatedBy
        key
        name
        companyUrl
        appUrl 
    }
}
`;