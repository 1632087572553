import {useQuery} from "@apollo/client";
import {GET_SETTINGS} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to fetch the application version.
 * @returns {{version: string, loading: boolean}} - The application version and the loading state.
 */
export function useGetVersion() {
  const variables = {
    key: "version"
  };

  const {
    data,
    loading
  } = useQuery(GET_SETTINGS, {
    variables: variables,
    onError: (err) => {
      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    settings: data?.settings ? JSON.parse(data?.settings) : {},
    loading
  };
}
