// LayoutSplashScreen.js
import React, {useEffect, useState} from "react";
import {Box, CircularProgress} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {PartnerImagePath} from "../../../hooks";
import {getPartnerKey} from "../../../utils";


export const LayoutSplashScreen = () => {
  const theme = useTheme();
  const [logo, setLogo] = useState(null);
  const partnerKey = getPartnerKey();

  useEffect(() => {
    PartnerImagePath(partnerKey, "logos/logo-light.png").then((imageUrl) => {
      setLogo(imageUrl);
    });
  }, [partnerKey]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {logo ? (
        <Box
          component="img"
          src={logo}
          alt="Logo"
          sx={{
            maxHeight: "25%",
            maxWidth: "50%",
          }}
        />
      ) : (
        <CircularProgress/>
      )}
    </Box>
  );
};