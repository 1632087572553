export const cellMaxWidth = (value, maxWidth = 15) => {
  if (typeof value === 'string') {
    const width = value.length * 1.2; // Adjust this value as needed
    maxWidth = Math.max(maxWidth, width);
  } else if (typeof value === 'number') {
    const width = Math.max(5, value.toString().length * 0.8); // Adjust this value as needed
    maxWidth = Math.max(maxWidth, width);
  } else {
    // typeof value === 'object'
  }
  return maxWidth;
};