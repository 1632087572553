import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_MODULE } from "./commands";

export const useUpdateModule = (
  onCompleted = () => {
  },
  onError = () => {
  }
) => {
  const [success, setSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const [updateModule, { loading, data }] = useMutation(UPDATE_MODULE, {
    onCompleted: response => {
      if (response.comUpdateModule?.success) {
        setSuccess(true);
        setResetForm(true);
        setTimeout(() => {
          setSuccess(false);
          setResetForm(false);
        }, 5000);
        onCompleted();
      } else {
        setSuccess(false);
      }
    },
    onError: ({ message }) => {
      setSuccess(false);
      onError(message);
    }
  });
  return {
    updateModule: updateModule, loading, success, data, resetForm
  };
};
