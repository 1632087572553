import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import ReactGA from "react-ga4";
import {LOGIN} from "./commands";
import {useAuth} from "../../app/providers";

/**
 * Custom hook to handle user login.
 * @param {Function} [onCompleted] - Callback function to be called when the login is successful.
 * @param {Function} [onError] - Callback function to be called when an error occurs during login.
 * @returns {{submit: Function, isSubmitting: boolean}} - The function to submit the login request and the loading state.
 */
export function useLogin(
  onCompleted = () => {
  },
  onError = () => {
  }
) {
  const {setToken} = useAuth();

  const [comLogin, {loading}] = useMutation(LOGIN, {
    onCompleted: ({comLogin}) => {
      // localStorage.clear();

      if (comLogin?.success) {
        if (comLogin?.token) {
          setToken(comLogin?.token);
          onCompleted();
        }
      } else {
        onError(comLogin?.message[0]);
      }
    },
    onError: error => {
      onError(error?.message);
    },
    // refetchQueries: ["useMe"]
  });

  const submit = useCallback(
    async credentials => {
      try {
        await comLogin({
          variables: credentials
        });

        ReactGA.event({
          category: "Authentication",
          action: "Login",
          label: credentials?.email.replace("@", "[at]")
        });
      } catch { /* empty */
      }
    },
    [comLogin]
  );

  return {
    submit,
    isSubmitting: loading
  };
}
