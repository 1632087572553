import {gql} from "@apollo/client";

export const CONSUMPTION_ENERGY_DATA_AGGREGATED_DATA = gql`
  query ConsumptionEnergyData (
      $meterType:  String!,
      $customer:  String!,
      $group:  String!,
      $year:  String!,
      $isClimate: Boolean
      $month:  String
    ) {
    consumptionEnergyData(
      meterType: $meterType
      customer: $customer
      group: $group
      year: $year
      isClimate: $isClimate
      month: $month
    ) {
      aggregatedData {
        meter
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
        total
        customer
        consumerCVR
        meterNumber
        streetName
        buildingNumber
        postcode
        cityName
        email
        address
      }
    }
  }
`;