import { gql } from "@apollo/client";

export const GET_ALL_MODULES = gql`
  query getAllModules {
    comModules {
      Id: id
      id: code
      code
      name
      desc
      createdAt
      updatedAt
    }
  }
`;
