import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {
  energyMonthlyColumns,
  energyStatementKey,
  getMonthAbbreviation,
  getShortMonthNameFromNumber,
  imageToBase64,
  indexedDbGetById
} from '../';
import {
  arrangeDataForSheet,
  cellFill,
  cellMaxWidth,
  leftRightBorders,
  monthTotalOnYearSheet,
  setLocaleNumFmt,
  setLocaleNumFmtForced,
  sheetFont,
  yearColumns
} from "./energyStatement";
import {enqueueSnackbar} from "notistack";
import {allBorders} from "./energyStatement/allBorders";
import {noBorders} from "./energyStatement/noBorders";

export const exportEnergyStatement = async (data, fileName, gettext, locale) => {
  enqueueSnackbar(gettext("Generating Excel file"), {
    variant: "info",
  });

  let reportUnits = gettext("Consumption (kWh)");
  let yearFilter = data[0].filter;
  if (yearFilter.isClimate) {
    reportUnits = gettext("Kg of CO₂e");
  }

  const keyYearRows = energyStatementKey("aggregated", yearFilter);
  const yearRows = await indexedDbGetById("cache", keyYearRows).then((result) => {
    return result;
  }).catch();

  const varMonthColumns = energyMonthlyColumns(yearRows, gettext, locale, "year");

  const workbook = new ExcelJS.Workbook();

  for (let index = 0; index < 13; index++) {
    const sheetData = data[index];

    if (index === 0) {
      // For Year sheet
      const varYearColumns = yearColumns(gettext, locale);
      // const worksheet = workbook.addWorksheet(gettext("Overall statement"));
      const worksheet = workbook.addWorksheet(yearFilter.year.toString());
      // const worksheet = workbook.addWorksheet(yearFilter.year.toString(), {
      //   properties: {tabColor: {argb: 'FF00FF00'}}, views: [
      //     {state: 'frozen', ySplit: 5, activeCell: 'A1', showGridLines: false}
      //   ]
      // });

      //#region First row
      worksheet.getRow(1).height = 50;

      if (sheetData.customer.logo?.file) {
        const logo1 = await imageToBase64(sheetData.customer.logo.file);
        const imageId1 = workbook.addImage({
          base64: logo1,
          extension: sheetData.customer.logo.extension,
        });
        worksheet.addImage(imageId1, {
          tl: {
            col: 0,
            row: 0
          },
          ext: {
            width: sheetData.customer.logo.width,
            height: sheetData.customer.logo.height
          },
          editAs: 'oneCell',
        });
      } else {
        const cell = worksheet.getCell('A1');
        cell.value = sheetData.customer.name;
        cell.font = {
          name: 'Arial',
          size: 20,
          bold: true
        };
        worksheet.mergeCells(1, 1, 1, 5); // Merge the first three columns for the customer name
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        };
      }

      const lastColumn = varYearColumns.length;
      if (sheetData.partner.logo?.file) {
        const logo2 = await imageToBase64(sheetData.partner.logo.file);
        const imageId2 = workbook.addImage({
          base64: logo2,
          extension: sheetData.partner.logo.extension,
        });

        worksheet.mergeCells(1, lastColumn - 2, 1, lastColumn); // Merge last three cells in the first row

        worksheet.addImage(imageId2, {
          tl: {
            col: lastColumn - 4,
            row: 0
          },
          ext: {
            width: sheetData.partner.logo.width,
            height: sheetData.partner.logo.height
          },
          editAs: 'oneCell',
          hyperlinks: {
            hyperlink: sheetData.partner.logo.link
          },
        });
      } else {
        const cell = worksheet.getCell(`A${lastColumn}`);

        cell.value = sheetData.partner.name;
        cell.font = {
          name: 'Arial',
          size: 20,
          bold: true
        };
        worksheet.mergeCells(1, lastColumn - 2, 1, lastColumn);
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        };
      }
      //#endregion First row

      //#region Remove outlines (borders) from the first row by setting border color to white
      const removeBorderFromRow = (row) => {
        row.eachCell({
          includeEmpty: true
        }, (cell) => {
          cell.border = noBorders('FFFFFFFF');
        });
      };
      removeBorderFromRow(worksheet.getRow(1)); // Ensure borders are removed from the first row
      //#endregion

      //#region Header Row
      const rowHeader0Number = 2;
      const headerRow0 = worksheet.getRow(rowHeader0Number);
      varYearColumns.forEach((col, colIndex) => {
        if (col.field === "q1") {
          worksheet.mergeCells(rowHeader0Number, 1, rowHeader0Number, colIndex);
          worksheet.mergeCells(rowHeader0Number, colIndex + 1, rowHeader0Number, varYearColumns.length);
          const cell = headerRow0.getCell(colIndex + 1);
          cell.value = reportUnits;
          cell.font = sheetFont(true);
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center'
          };
          cell.border = allBorders();
        }
      });

      const headerRow = worksheet.getRow(3);
      varYearColumns.forEach((col, colIndex) => {
        const cell = headerRow.getCell(colIndex + 1);
        cell.value = col.headerName;
        cell.font = sheetFont(true);
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = allBorders();
        worksheet.getColumn(colIndex + 1).width = col.width ? col.width / 10 : 10;
      });
      //#endregion Header Row

      // Add the data starting from row 3 and apply background colors for the first sheet only
      yearRows.forEach((row) => {
        const meterNumber = row.meter;
        const monthColumnRowIndex = varMonthColumns.findIndex((col) => col.field === meterNumber);
        const monthSheetColumn = worksheet.getColumn(monthColumnRowIndex + 1).letter;
        const rowData = arrangeDataForSheet(row, varYearColumns, gettext);
        const addedRow = worksheet.addRow(rowData);

        addedRow.eachCell((cell, colNumber) => {
          const field = varYearColumns[colNumber - 1].field;
          cell = setLocaleNumFmt(locale, cell);
          cell = monthTotalOnYearSheet(cell, field, monthSheetColumn);
          cell.border = leftRightBorders("FFFFFFFF");
          cell.font = sheetFont(false);

          //#region Total
          if (varYearColumns[colNumber - 1].field === 'total') {
            const firstLetter = worksheet.getColumn(colNumber + 1).letter;
            const lastLetter = worksheet.getColumn(colNumber + 12).letter;
            cell = cellFill(cell, "FFD8E4BC");
            cell.font = sheetFont(true);
            cell.value = {
              formula: `SUM(${firstLetter}${cell.row}:${lastLetter}${cell.row})`
            };
            cell = setLocaleNumFmt(locale, cell);
          }
          //#endregion Total

          //#region Quarter 1
          if (varYearColumns[colNumber - 1].field === 'q1') {
            const firstLetter = worksheet.getColumn(colNumber + 5).letter;
            const lastLetter = worksheet.getColumn(colNumber + 7).letter;
            cell.font = sheetFont(true);
            cell = cellFill(cell, "FFD8E4BC");
            cell.value = {
              formula: `SUM(${firstLetter}${cell.row}:${lastLetter}${cell.row})`
            };
            cell = setLocaleNumFmt(locale, cell);
          }
          //#endregion Quarter 1

          //#region Quarter 2
          if (varYearColumns[colNumber - 1].field === 'q2') {
            const firstLetter = worksheet.getColumn(colNumber + 7).letter;
            const lastLetter = worksheet.getColumn(colNumber + 9).letter;
            cell.font = sheetFont(true);
            cell = cellFill(cell, "FFD8E4BC");
            cell.value = {
              formula: `SUM(${firstLetter}${cell.row}:${lastLetter}${cell.row})`
            };
            cell = setLocaleNumFmt(locale, cell);
          }
          //#endregion Quarter 2

          //#region Quarter 3
          if (varYearColumns[colNumber - 1].field === 'q3') {
            const firstLetter = worksheet.getColumn(colNumber + 9).letter;
            const lastLetter = worksheet.getColumn(colNumber + 11).letter;
            cell.font = sheetFont(true);
            cell = cellFill(cell, "FFD8E4BC");
            cell.value = {
              formula: `SUM(${firstLetter}${cell.row}:${lastLetter}${cell.row})`
            };
            cell = setLocaleNumFmt(locale, cell);
          }
          //#endregion Quarter 3

          //#region Quarter 4
          if (varYearColumns[colNumber - 1].field === 'q4') {
            const firstLetter = worksheet.getColumn(colNumber + 11).letter;
            const lastLetter = worksheet.getColumn(colNumber + 13).letter;
            cell.font = sheetFont(true);
            cell = cellFill(cell, "FFD8E4BC");
            cell.value = {
              formula: `SUM(${firstLetter}${cell.row}:${lastLetter}${cell.row})`
            };
            cell = setLocaleNumFmt(locale, cell);
          }
          //#endregion Quarter 4

          //#region Background color for columns after "total"
          if (colNumber > varYearColumns.findIndex(col => col.field === 'total') + 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFEBF1DE'
              }, // Background color for columns after "total"
            };
            cell.border = leftRightBorders("FFEBF1DE");
          }
          //#endregion Background color for columns after "total"
        });
      });

      //#region Add sum row
      const lastRow = worksheet.lastRow.number + 1;
      const sumRow = worksheet.getRow(lastRow);

      for (let colIndex = 1; colIndex <= varYearColumns.length; colIndex++) {
        let cell = sumRow.getCell(colIndex);
        if (colIndex > varYearColumns.findIndex(col => col.field === 'q1')) {
          const letter = worksheet.getColumn(colIndex).letter;

          cell.value = {
            formula: `SUM(${letter}3:${letter}${lastRow - 1})`
          };
          cell.font = sheetFont(true);
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FFD8E4BC'
            }
          };
          cell.border = allBorders();
          cell = setLocaleNumFmtForced(locale, cell);
        }
      }
      //#endregion Add sum row

      //#region Merge cells before sum
      const totalColIndex = varYearColumns.findIndex(col => col.field === 'q1') + 1;
      if (totalColIndex > 1) {
        worksheet.mergeCells(lastRow, 1, lastRow, totalColIndex - 1);
        const mergedCell = sumRow.getCell(1);
        mergedCell.value = yearFilter.year.toString();
        mergedCell.border = allBorders();
        mergedCell.font = sheetFont(true);
      }
      //#endregion Merge cells before sum

      worksheet.views = [{
        showGridLines: false,
        state: 'frozen',
        xSplit: 2,
        ySplit: 3
      }];

      worksheet.autoFilter = {
        from: {
          row: index === 0 ? 3 : 1,
          column: 1
        },
        to: {
          row: index === 0 ? 3 : 1,
          column: varYearColumns.length
        },
      };

      //#region Set column width
      worksheet.columns.forEach((column, columnIndex) => {
        let maxWidth = 15;
        let rowCounter = 0;

        column.eachCell((cell) => {
          if (rowCounter > 0) {
            maxWidth = cellMaxWidth(cell.value, maxWidth);
          }
          rowCounter++;
        });

        worksheet.getColumn(columnIndex + 1).width = maxWidth;
      });
      //#endregion Set column width
    } else {
      // For Monthly sheets
      const keyMonthRow = energyStatementKey(getMonthAbbreviation(index), yearFilter);
      const monthRows = await indexedDbGetById("cache", keyMonthRow).then((result) => {
        return result;
      }).catch();

      const sheetName = getShortMonthNameFromNumber(index);
      const worksheet = workbook.addWorksheet(sheetName);

      worksheet.columns = varMonthColumns.map((col) => ({
        header: col.headerName,
        key: col.field,
        type: col.type,
        width: col.width ? col.width / 10 : 10,
      }));

      const headerRow = worksheet.getRow(1);

      headerRow.font = sheetFont(true);
      headerRow.eachCell((cell) => {
        cell.border = allBorders();
      });

      monthRows.forEach((row) => {
        // (row, rowIndex)
        const rowData = arrangeDataForSheet(row, varMonthColumns, gettext);

        const addedRow = worksheet.addRow(rowData);
        const totalRows = monthRows.length;

        addedRow.eachCell((cell, colNumber) => {
          cell = setLocaleNumFmt(locale, cell);
          if (varMonthColumns[colNumber - 1].field === "ts") {
            cell.border = leftRightBorders("FFFFFFFF");
          } else {
            cell = cellFill(cell, "FFEBF1DE");
            cell.border = leftRightBorders("FFEBF1DE");
          }
          cell.font = sheetFont(false);

          //#region Month Sum
          if (varMonthColumns[colNumber - 1].field === 'sum') {
            cell.font = sheetFont(true);
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFD8E4BC'
              }, // Background color for columns after "total"
            };
            const firstLetter = worksheet.getColumn(3).letter;
            const lastLetter = worksheet.getColumn(rowData.length).letter;
            cell.value = {
              formula: `SUM(${firstLetter}${cell.row}:${lastLetter}${cell.row})`
            };
          }
          if (colNumber > 2 && parseInt(cell.row) === 2) {
            const letter = worksheet.getColumn(colNumber).letter;
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFD8E4BC'
              }, // Background color for columns after "total"
            };
            cell.value = {
              formula: `SUM(${letter}3:${letter}${totalRows + 1})`
            };
          }
          if (cell.row === totalRows + 1) {
            cell.border = {
              left: {
                style: 'thin'
              },
              bottom: {
                style: 'thin',
              },
              right: {
                style: 'thin'
              },
            };
          }
          //#endregion Month Sum
        });

        if (row.ts === "summary") {
          addedRow.font = sheetFont(true);
        }

      });


      // Freeze panes for other sheets: freeze the first two rows and the first two columns
      worksheet.views = [{
        showGridLines: false,
        state: 'frozen',
        xSplit: 2,
        ySplit: 2
      }];

      // Set auto filter on the headers (row 2 for first sheet, row 1 for others)
      worksheet.autoFilter = {
        from: {
          row: index === 0 ? 2 : 1,
          column: 1
        },
        to: {
          row: index === 0 ? 2 : 1,
          column: varMonthColumns.length
        },
      };

      worksheet.columns.forEach((column, columnIndex) => {
        let maxWidth = 15;
        column.eachCell((cell) => {
          maxWidth = cellMaxWidth(cell.value, maxWidth);
        });
        worksheet.getColumn(columnIndex + 1).width = maxWidth;
      });
    }
  }

  // export the Excel workbook to a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/octet-stream'
  });
  saveAs(blob, fileName);
  enqueueSnackbar(gettext("File exported successfully"), {
    variant: "success",
  });
};
