import { gql } from "@apollo/client";

export const GET_SYSTEM_USERS_LIST = gql`
  query SystemUsers {
    systemUsers {
      id
      name
      email
      countryCode
      timezone
      roles
      lang
      companyId
      partner {
        id
        name
        key
        companyUrl
        appUrl
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }

`;
