import {initIDB} from "./";

export const indexedDbUpdate = (dbName, data, key) => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readwrite");
      const store = txn.objectStore(dbName);
      const query = store.put(data, key);
      query.onsuccess = function (event) {
        resolve(event);
      };
      query.onerror = function (event) {
        reject(event);
      };
      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};