export const DBConfig = {
  name: "fabeke",
  version: 1.1,
  objectStoresMeta: [
    {
      store: "cache",
      storeConfigstoreConfig: {
        autoIncrement: false
      },
      storeSchema: [
        {
          name: "key",
          keyPath: "key",
          options: {
            unique: true
          }
        },
        {
          name: "value",
          keyPath: "value",
          options: {
            unique: false
          }
        },
        {
          name: "expiry",
          keyPath: "expiry",
          options: {
            unique: false
          }
        }
      ]
    },
    {
      store: "persistent-data",
      storeConfigstoreConfig: {
        autoIncrement: false
      },
      storeSchema: [
        {
          name: "key",
          keyPath: "key",
          options: {
            unique: true
          }
        },
        {
          name: "value",
          keyPath: "value",
          options: {
            unique: false
          }
        }
      ]
    }
  ]
};
