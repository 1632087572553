import {useQuery} from "@apollo/client";
import {TAXES_INVOICES} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to create taxes invoices.
 * @param {object} variables - The variables to be passed to the mutation.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{createInvoice: Function, creating: boolean}} - The function to create the invoice and the loading state.
 */
export function useTaxesInvoices(
  variables,
  onCompleted = () => {
  },
  onError = () => {

  }
) {
  const {
    data,
    loading
  } = useQuery(TAXES_INVOICES, {
    variables,
    onCompleted: ({climateData}) => {
      onCompleted(climateData);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    data: data?.taxesInvoice || [],
    loading
  };
}
