import {useMutation} from "@apollo/client";
import {useCallback} from "react";
import {DELETE_MODULE} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to delete a module.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{deleteModule: Function, deleting: boolean}} - The function to delete a module and the loading state.
 */
export function useDeleteModule(
  onCompleted = () => {
  },
  onError = () => {
  }) {
  const [deleteModule, {loading}] = useMutation(DELETE_MODULE, {
    onCompleted,
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        "Delete Module: " + err.message,
        {
          variant: "error"
        }
      );
    },
    errorPolicy: "all"
  });

  const deleteModuleRow = useCallback(
    variables => {
      deleteModule({
        variables,
        refetchQueries: ["getAllModules"]
      }).then();
    },
    [deleteModule]
  );

  return {
    deleteModule: deleteModuleRow,
    deleting: loading
  };
}
