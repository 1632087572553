import { gql } from "@apollo/client";

export const UPDATE_ROLE = gql`
mutation comUpdateRole (
    $key: String!
    $role: String!
    ) 
    {
    comUpdateRole(
      key: $key
      role:$role,
    )
    {
      success
      message
    }
  }`;
