import {ModulesList} from "../../components";
import React from "react";

export const DeveloperModules = () => {

  return (
    <ModulesList
      showEdit={true}
      showDelete={true}
    />
  );
};
