import { gql } from "@apollo/client";

export const UPDATE_MODULE = gql`
mutation comUpdateModule (
    $code: String!
    $name: String!
    $desc: String!
    ) 
    {
    comUpdateModule(
      code: $code
      name:$name,
      desc:$desc
    )
    {
      success
      message
    }
  }
  `;
