import React, {useCallback, useEffect, useState} from "react";
import {Button, Grid, Menu, MenuItem, Typography} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {IoCalendar, IoChevronDown} from "react-icons/io5";
import "./datepickerStyles.css";
import PropTypes from "prop-types";

const NewDatePicker = props => {
  const {
    getSelectedDate,
    onSegmentChange,
    gettext,
    segment,
    selectededDate,
    view,
    futureDates,
    disableSegments
  } = props;

  const [selectedSegment, setSelectedSegment] = useState("year");
  //  const [selectedDate, setSelectedDate] = useState(
  //    selectededDate?.date ? new Date(selectededDate?.date) : new Date()
  //  );

  const [startDate, setStartDate] = useState(
    selectededDate?.startDate ? new Date(selectededDate?.startDate) : new Date()
  );
  const [endDate, setEndDate] = useState(
    selectededDate?.endDate ? new Date(selectededDate?.endDate) : new Date()
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleSegmentClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSegmentClose = () => {
    setAnchorEl(null);
  };
  const handleDateChange = event => {
    const [start, end] = event;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      // setSelectedDate(event);
      getSelectedDate(event, selectedSegment);
      handleSegmentClose();
    }
  };

  const handleSegmentSelect = segment => {
    setSelectedSegment(segment);
    if (segment === "day") {
      onSegmentChange(0);
    } else if (segment === "month") {
      onSegmentChange(1);
    } else {
      onSegmentChange(2);
    }
    handleSegmentClose();
  };

  const formattedDate = () => {
    let dateStart = new Date();
    let dateEnd = new Date();
    // if(selectedDate) {
    //   date = new Date(selectedDate);
    // } else {
    dateStart = new Date(startDate);
    dateEnd = new Date(endDate);
    // }

    const dayStart = dateStart.getDate();
    const monthStart = dateStart.toLocaleString("default", {
      month: "short"
    });
    const yearStart = dateStart.getFullYear();

    const dayEnd = dateEnd.getDate();
    const monthEnd = dateEnd.toLocaleString("default", {
      month: "short"
    });
    const yearEnd = dateEnd.getFullYear();

    if (selectedSegment === "year") {
      return `${yearStart} - ${yearEnd}`;
    } else if (selectedSegment === "month") {
      return `${monthStart} ${yearStart} - ${monthEnd} ${yearEnd}`;
    } else {
      return `${dayStart} ${monthStart} ${yearStart} - ${dayEnd} ${monthEnd} ${yearEnd}`;
    }
  };

  const renderDatePicker = () => {
    switch (selectedSegment) {
      case "year":
        return (
          <DatePicker
            className="my-datepicker"
            //            selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="yyyy"
            showMonthDropdown={false}
            showYearPicker
            dropdownMode="select"
            selectsRange
            inline
            // minDate={
            //     futureDates === false &&
            //     new Date().setMonth(new Date().getMonth())
            // }
            maxDate={
              futureDates !== false
                ? new Date()
                : new Date().setMonth(new Date().getMonth() + 3)
            }
            // maxDate={futureDates !== false && new Date()}
            dayClassName={date => {
              return date.getMonth() !== startDate.getMonth() ? "disabled" : "";
            }}
          />
        );
      case "month":
        return (
          <DatePicker
            className="my-datepicker"
            // selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            selectsRange
            inline
            // minDate={
            //     futureDates === false &&
            //     new Date().setMonth(new Date().getMonth())
            // }
            maxDate={
              futureDates !== false
                ? new Date()
                : new Date().setMonth(new Date().getMonth() + 3)
            }
            dayClassName={date => {
              return date.getMonth() !== startDate.getMonth() ||
              date.getFullYear() !== startDate.getFullYear()
                ? "disabled"
                : "";
            }}
          />
        );
      case "day":
      default:
        return (
          <DatePicker
            // className="my-datepicker"
            // selected={selectedDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            dateFormat="dd MMMM yyyy"
            selectsRange
            inline
            maxDate={futureDates !== false && new Date()}
            dayClassName={date => {
              return date.getMonth() !== startDate.getMonth() ? "disabled" : "";
            }}
          />
        );
    }
  };

  const getSelectedSegment = useCallback(() => {
    if (segment === 0) {
      setSelectedSegment("day");
    } else if (segment === 1) {
      setSelectedSegment("month");
    } else {
      setSelectedSegment("year");
    }
  }, [segment]);

  useEffect(() => {
    getSelectedSegment();
  }, [segment, getSelectedSegment]);

  return (
    <>
      {view === "table" ? (
        <Button
          endIcon={<IoChevronDown size={12}/>}
          size="small"
          onClick={handleSegmentClick}
          sx={{
            width: "160px",
            "&:hover": {
              backgroundColor: "#fff",
              border: "1px solid #E4E4E7"
            }
          }}
        >
          <Typography fontSize="14px" fontWeight="500" color="secondary.800">
            Calender
          </Typography>
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{
            height: "32px"
          }}
          endIcon={<IoCalendar/>}
          onClick={handleSegmentClick}
        >
          <Typography fontSize="14px" fontWeight="fontWeightMedium">
            {formattedDate()}
          </Typography>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSegmentClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          ".MuiMenu-list": {
            padding: 0,
            height: "100%"
          },
          "& .MuiMenu-paper": {
            width: "412px",
            borderRadius: "8px",
            boxShadow:
              "0px 10px 15px -3px #0000001A ,0px 4px 6px -2px #0000000F",
            overflow: "hidden"
          },
          "& .MuiMenuItem-root": {
            fontWeight: 400,
            fontSize: "14px"
          },
          "& .MuiMenuItem-root.Mui-selected": {
            backgroundColor: "secondary.900",
            fontWeight: 600,
            fontSize: "14px",
            color: "white"
          }
        }}
      >
        {/*<Grid container columnSpacing={1} xs={12}>*/}
        <Grid container columnSpacing={1}>
          <Grid item xs={4} sx={{
            borderRight: "1px solid #E4E4E7"
          }}>
            <MenuItem
              disabled={disableSegments && segment !== 0}
              selected={selectedSegment === "day"}
              onClick={() => handleSegmentSelect("day")}
            >
              {gettext("Daily")}
            </MenuItem>
            <MenuItem
              disabled={disableSegments && segment !== 1}
              selected={selectedSegment === "month"}
              onClick={() => handleSegmentSelect("month")}
            >
              {gettext("Monthly")}
            </MenuItem>
            <MenuItem
              disabled={disableSegments && segment !== 2}
              selected={selectedSegment === "year"}
              onClick={() => handleSegmentSelect("year")}
            >
              {gettext("Yearly")}
            </MenuItem>
          </Grid>
          <Grid item xs={8}>
            {renderDatePicker()}
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

NewDatePicker.propTypes = {
  getSelectedDate: PropTypes.func,
  onSegmentChange: PropTypes.func,
  gettext: PropTypes.func,
  segment: PropTypes.number,
  selectededDate: PropTypes.object,
  view: PropTypes.string,
  futureDates: PropTypes.bool,
  disableSegments: PropTypes.bool
};

export default NewDatePicker;
