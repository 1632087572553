import {IconButton, InputAdornment, OutlinedInput} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useContext, useState} from "react";
import {useTextDomainContext} from "../../providers";

export const PasswordInput = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      type={showPassword ? "text" : "password"}
      placeholder={gettext("Password")}
      endAdornment={
        <InputAdornment position="end" sx={{
          color: "#D4D4D8"
        }}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            color="inherit"
            size="small"
          >
            {showPassword ? <Visibility/> : <VisibilityOff/>}
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  );
};