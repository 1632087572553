import gql from "graphql-tag";
// import { SCOPE_TYPE } from "./Types";

export const GET_PREDICTION_DATA_QUERY = gql`
  query metersDataPredicted(
    $meterType: String!
    $meteringPointId: String
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
    $fileType: String!
  ) {
    metersDataPredicted(
      meterType: $meterType
      meteringPointId: $meteringPointId
      address: $address
      customer: $customer
      fromDate: $fromDate
      toDate: $toDate
      resolution: $resolution
      fileType: $fileType
    ) {
      Id
      address
      meteringPointId
      ident
      customer
      cvr
      ident
      meteringPointId
      period {
        fromDate
        month
        toDate
      }
      unit
      type
      typeOfMP
      electricityGreen
      fileUrl
      colorSettings {
        electricity {
          background
          text
        }
        heating {
          background
          text
        }
        water {
          background
          text
        }
        gas {
          background
          text
        }
        energy {
          background
          text
        }
        co2 {
          background
          text
        }
      }
      data {
        label
        productionDkk
        consumptionDkk
        productionConversionRate
        consumptionConversionRate
        productionCostDkk
        consumptionCostDkk
        co2PerM2
        consumptionCo2
        consumptionV
        productionCo2
        productionV
        ts
        vPerM2
      }
    }
  }
`;

export const GET_CUSTOMER_ADDRESS = gql`
  query customersAddress($customer: String!) {
    customersAddress(customer: $customer) {
      Id
      access
      address
      altitude
      autoId0
      country
      customer
      danishAddresses
      electricity
      gas
      heating
      ident
      locationDescription
      meteringpoints
      municipality
      propertyId
      tags
      water
    }
  }
`;

export const GET_ACCOUNT_GROUPS = gql`
  query accountGroups {
    accountGroups {
      Id
      createdAt
      name
      type
      updatedAt
    }
  }
`;

export const GET_ACCOUNT_GROUP = gql`
  query accountGroup($id: String!) {
    accountGroup(id: $id) {
      Id
      autoId0
      createdAt
      nameDa
      nameDe
      nameEn
      type
      updatedAt
    }
  }
`;

export const GET_ACCOUNT_CHARTS = gql`
  query chartOfAccounts {
    chartOfAccounts {
      Id
      accountNumber
      name
      createdAt
      updatedAt
      accountGroupId
      category
      tab
    }
  }
`;

export const GET_ACCOUNT_CHART = gql`
  query chartOfAccount($id: String!) {
    chartOfAccount(id: $id) {
      Id
      accountNumber
      nameEn
      nameDe
      nameDa
      createdAt
      updatedAt
      accountGroupId
      category
      tab
    }
  }
`;

export const GET_SCRAPPER_LOGS = gql`
  query scrapperLogs($scrapperName: String, $createdAt: String) {
    scrapperLogs(scrapperName: $scrapperName, createdAt: $createdAt) {
      Id
      autoId0
      createdAt
      loginId
      description
      scrapperName
      status
      updatedAt
      url
    }
  }
`;

export const GET_SCRAPPER_LIST = gql`
  query scrapperInfo {
    scrapperInfo {
      Id
      autoId0
      createdAt
      description
      loginId
      scrapperName
      status
      updatedAt
      url
    }
  }
`;

// export const GET_TEAM_MEMBERS = gql`
//   query teamMembers($teamId: Int!) {
//     teamMembers(teamId: $teamId) {
//       userId
//       teamId
//       name
//       email
//       read
//       write
//       delete
//     }
//   }
// `;

export const GET_ALL_COUNTRIES = gql`
  query mongoCountries {
    mongoCountries {
      id
      code
      phone
      name
      symbol
      capital
      currency
      continent
      continentCode
      alpha3
      status
    }
  }
`;

export const GET_ALL_TIME_ZONES = gql`
  query mongoTimezones {
    mongoTimezones {
      id
      zoneName
      countryCode
      gmtOffset
      dstOffset
      rawOffset
    }
  }
`;

// export const GET_ALL_ROLES = gql`
//   query roles {
//     roles {
//       id
//       name
//       isSystem
//       createdAt
//       updatedAt
//     }
//   }
// `;

export const GET_EMISSION_FACTOR_LOOKUP = gql`
  query emissionFactorLookup {
    emissionFactorLookup {
      Id
      autoId0
      createdAt
      group
      key
      parameter1
      parameter2
      unit
      updatedAt
    }
  }
`;

export const GET_EMISSION_FACTOR_LIST = gql`
  query emissionFactors($year: Int) {
    emissionFactors(year: $year) {
      Id
      key
      group
      parameter1
      parameter2
      unit
      createdAt
      updatedAt
      data {
        scope1
        scope2
        scope3
        source
        year
      }
    }
  }
`;

export const GET_EMISSION_FACTOR = gql`
  query emissionFactor($id: String!, $year: Int) {
    emissionFactor(id: $id, year: $year) {
      Id
      key
      group
      parameter1
      parameter2
      unit
      createdAt
      updatedAt
      data {
        scope1
        scope2
        scope3
        source
        year
      }
    }
  }
`;

export const GET_SUMMARY_ACCOUNT_DATA = gql`
  query summaryAccountData(
    $fromDate: String
    $toDate: String
    $customerId: String
  ) {
    summaryAccountData(
      fromDate: $fromDate
      toDate: $toDate
      customerId: $customerId
    ) {
      scope1 {
        value
        percentage
      }
      scope2 {
        value
        percentage
      }
      scope3 {
        value
        percentage
      }
      outOfScope {
        value
        percentage
      }
    }
  }
`;

export const GET_ACCOUNT_DATA_JSON = gql`
  query accountDataJson(
    $customerId: String
    $fromDate: String
    $toDate: String
  ) {
    accountDataJson(
      customerId: $customerId
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const GET_SETTINGS = gql`
  query settings($key: String) {
    settings(key: $key)
  }
`;

export const GET_DEFAULT_SETTINGS = gql`
  query allSettings {
    allSettings {
      Id
      key
      value
    }
  }
`;

export const GET_NOT_MEMBERS = gql`
  query comGetNotMembers($customerKey: String!) {
    comGetNotMembers(customerKey: $customerKey) {
      address
      autoId0
      city
      country
      customerKey
      cvr
      id
      name
      personalTeam
      phone
      phone2
      pnumber
      sef
      type
      userId
      zip
    }
  }
`;

export const GET_API_KEY = gql`
  query comGetUserApiKeys($email: String!) {
    comGetUserApiKeys(email: $email) {
      token
      label
    }
  }
`;

export const BENCHMARK_DATA = gql`
  query benchmarkData(
    $meterType: String!
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
    $fileType: String
  ) {
    benchmarkData(
      meterType: $meterType
      address: $address
      customer: $customer
      fromDate: $fromDate
      toDate: $toDate
      resolution: $resolution
      fileType: $fileType
    )
  }
`;
