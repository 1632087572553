import {gql} from "@apollo/client";

export const GET_BUILDING_ENERGY_PRICES_FILE = gql`
  query meterDataWithTaxes(
    $date: Date
    $buildingName: String!
    $fileType: String
  ) {
    meterDataWithTaxes(
      date: $date
      buildingName: $buildingName
      fileType: $fileType
    ) {
      fileUrl
    }
  }
`;