import gql from "graphql-tag";

export const ADD_USER_BY_ADMIN = gql`
  mutation comCreateUserAdmin(
    $countryCode: String
    $email: String
    $lang: String
    $name: String
    $password: String
    $roles: [String]
    $partnerId: String
    $status: String
    $timezone: String
    
  ) {
    comCreateUserAdmin(
      countryCode: $countryCode
      email: $email
      lang: $lang
      name: $name
      password: $password
      roles: $roles
      partnerId: $partnerId
      status: $status
      timezone: $timezone
    ) {
      success
      message
    }
  }
`;