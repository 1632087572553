export const info = {
  50: "fef3e2",
  100: "#fbe1b6",
  200: "#f9ce85",
  300: "#f7ba54",
  400: "#f5ab2f",
  500: "#f39c0a",
  600: "#f19409",
  700: "#ef8a07",
  800: "#ed8005",
  900: "#ea6e03",
  A100: "#ffffff",
  A200: "#ffebde",
  A400: "#ffcdab",
  A700: "#ffbe91"
};
