import {useUser} from "../../providers";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {isEqual} from "lodash";
import {Forbidden} from "../../pages";
// import { usePagePrivileges } from "../../../hooks/privileges";

export const ProtectedRoute = (props) => {
  const mergedCodes = useMemo(() => {
    const commonPermissions = ["developer"];
    let returnValue = [];
    if (props?.codes?.length) {
      returnValue = commonPermissions.concat(props.codes);
    } else {
      returnValue = commonPermissions;
    }
    return returnValue;
  }, [props.codes]);

  const [permissions, setPermissions] = useState([]);
  const [codes, setCodes] = useState(mergedCodes);
  const [returnElement, setReturnElement] = useState(<></>);

  const {user} = useUser();

  useEffect(() => {
    if (mergedCodes?.length && !isEqual(mergedCodes, codes)) {
      setCodes(mergedCodes);
    }
  }, [codes, mergedCodes]);

  useEffect(() => {
    let p = [];
    p = permissions;
    if (user?.permissions?.length) {
      user?.permissions.forEach(role => {
        role?.modules.forEach(permission => {
          if (!p.includes(permission)) {
            p.push(permission);
          }
        });
      });
    }

    if (!isEqual(permissions, p)) {
      setPermissions(p);
    }
  }, [permissions, user?.permissions]);

  useEffect(() => {
    if (codes?.length) {
      setReturnElement(<Forbidden/>);
      codes.forEach((moduleName) => {
        if (moduleName && permissions && permissions.length > 0) {
          const module = permissions.find(item => item === moduleName);
          if (module) {
            setReturnElement(props.element);
          } else {
            // setReturnElement(<Forbidden />);
          }
        } else if (moduleName === "allow-to-all") {
          setReturnElement(props.element);
        } else {
          setReturnElement(<Forbidden/>);
        }
      });
    }
  }, [permissions, codes, props.element]);

  return (
    returnElement
  );
};

ProtectedRoute.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  element: PropTypes.object.isRequired
};
