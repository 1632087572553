import {shortSegmentDateFormat} from "../";
import moment from "moment/moment";

export const meterApiDataToBarChartData = (apiData, segment) => {
  const returnData = [];
  // const segmentList = Segments();
  apiData.forEach((arrayItem) => {
    // const label = moment(arrayItem.ts).format(segmentList[segment].dateFormat);
    const label = moment(arrayItem.ts).format(shortSegmentDateFormat(segment));
    //moment(value).format(shortSegmentDateFormat(segment));

    let subObj = {
      label: label,
    };
    subObj = {
      ...arrayItem,
      ...subObj,
    };

    returnData.push(subObj);
  });

  return returnData;
};
