import md5 from "md5";
import {sortObjectByKey} from "../";

/**
 * Generates a hashed key for energy data caching.
 * @param {string} period - The period for which the data is relevant.
 * @param {object} variables - An object containing additional variables for key generation.
 * @returns {string} - A hashed string key.
 */
export const energyStatementKey = (period = "", variables = {
}) => {
  return md5(`energy-data-${period}-` + JSON.stringify(sortObjectByKey(variables)));
};