import {gql} from "@apollo/client";
import {CUSTOMER_FIELDS} from "./fragments";

export const GET_GROUPS = gql`
  query GetGroups ($customerName: String!) {
      getGroups(customerName: $customerName) {
          ...CustomerFields
      }
  }
  ${CUSTOMER_FIELDS}
`;