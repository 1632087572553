import React, {useEffect} from "react";
import {useLocation} from "react-router";
import ReactGA from "react-ga4";
import {AuthPageLayout} from "./components";
import {ForgotPasswordForm} from "../../components";

/**
 *
 * @param props
 */
export const ForgotPassword = (props) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  }, [props, location.pathname]);

  return (
    <AuthPageLayout>
      <ForgotPasswordForm/>
    </AuthPageLayout>
  );
};
