// ==============================|| OVERRIDES - AppBar ||============================== //

/**
 *
 */
export default function AppBar() {
  // theme
  return {
    MuiAppBar: {
      // colorPrimary: {
      //   backgroundColor: "#FFFFFF"
      // },
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF"
        }
      }
    }
  };
}
