import {useQuery} from "@apollo/client";
import {CUSTOMERS} from "./commands";

/**
 *
 * @param variables
 */
export function useCompanies(variables) {
  const userId = variables?.userId || null;
  const customerId = variables?.customerId || null;
  const {
    data,
    loading
  } = useQuery(CUSTOMERS, {
    variables: {
      userId,
      customerId
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  return {
    customers: data?.getCustomers || [],
    loading
  };
}
