import {useQuery} from "@apollo/client";
import {GET_ALL_PARTNERS} from "./commands";

/**
 * Custom hook to fetch and filter the partners list.
 * @returns {{partnerList: Array<{id: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string, key: string, name: string, companyUrl: string, appUrl: string}>, loading: boolean}} - The filtered partners list and loading state.
 */
export function usePartnersList() {
  const {
    data,
    loading
  } = useQuery(GET_ALL_PARTNERS);

  return {
    partnerList: data?.getPartners.filter(item => item.key === "energiteam") || [],
    // partnerList: data?.getPartners || [],
    loading
  };
}
