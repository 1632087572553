import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Box, Typography} from "@mui/material";
import {usePagePrivileges} from "../../../../../../../../../../../hooks";
import PropTypes from "prop-types";

/**
 *
 * @param link.link
 * @param link
 * @param icon
 * @param codes
 * @param label
 * @param desc
 * @param link.icon
 * @param link.codes
 * @param link.label
 * @param link.desc
 * @returns {React.JSX.Element}
 * @class
 */
export function ProfileMenuItem({
                                  link,
                                  icon,
                                  codes,
                                  label,
                                  desc
                                }) {
  const [displayOption, setDisplayOption] = useState(false);
  const allow = usePagePrivileges(codes);

  useEffect(() => {
    if (allow) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [allow]);

  return (
    <>
      {displayOption && (
        <NavLink to={link}>
          <MenuItem sx={{
            color: "primary.100"
          }}>
            <ListItemIcon sx={{
              color: "inherit"
            }}>{icon}</ListItemIcon>
            <Box>
              <Typography
                variant="body1"
                color="primary.100"
                sx={{
                  fontSize: 16,
                  fontWeight: "light"
                }}
              >
                {label}
              </Typography>
              <Typography variant="caption" color="gray">
                {desc}
              </Typography>
            </Box>
          </MenuItem>
        </NavLink>
      )}
    </>
  );
}

ProfileMenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
};