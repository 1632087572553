import {UsersList} from "../../components";
import React from "react";

export const AllUsers = () => {
  return (
    <UsersList
      showAdd
      showEdit
      // showDeleteSingle
      showChangePassword
      showCompanies
      userFilter="all"
    />
  );
};
