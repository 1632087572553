import {gql} from "@apollo/client";

export const ADD_CUSTOMER_USER = gql`
  mutation comAddCustomerUser(
    $customerName: String
    $userId: String
  ) {
    comAddCustomerUser(
      customerName: $customerName
      userId: $userId
    ) {
      success
      message
    }
  }
`;
