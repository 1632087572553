export const setLocaleNumFmtForced = (locale, cell) => {
  // const systemLocale = Intl.DateTimeFormat().resolvedOptions().locale;

  // switch (locale) {
  //   case 'en':
  //     cell.numFmt = '#,##0.00';
  //     break;
  //   case 'da':
  //     if (systemLocale === 'da-DK') {
  //       cell.numFmt = '#.##0,00';
  //     } else {
  //       cell.numFmt = '#,##0.00';
  //     }
  //     break;
  //   // Add more locales as needed
  //   default:
  //     cell.numFmt = '#,##0.00';
  // }

  cell.numFmt = '#,##0.00';
  return cell;
};