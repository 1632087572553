import {Box, Skeleton} from "@mui/material";
import React from "react";

/**
 *
 */
export function LineChartLoading() {
  return (
    <Box
      width="100%"
      // height="100%"
      height="300px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1
      }}
    >
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="60%"
        height="10%"
      />
      <Box
        width="100%"
        height="10%"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1
        }}
      >
        <Skeleton
          variant="rounded"
          animation="wave"
          width={"15%"}
          height="100%"
        />
        <Skeleton
          variant="rounded"
          animation="wave"
          width={"15%"}
          height="100%"
        />
      </Box>
      <Skeleton variant="rounded" animation="wave" width="100%" height="80%"/>
    </Box>
  );
}
