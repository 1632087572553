import {Grid} from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import {useDataHubMeterDetailsMonthly} from "./hooks";
import PropTypes from "prop-types";
import React from "react";

const MeterDetailsMonthly = props => {
  const {
    view,
    setView,
    meteringPointId,
    date,
    setDate,
    selectedSegment,
    setSelectedSegment,
    gettext
  } = props;
  const {meterDetailMonthly, period, loading} = useDataHubMeterDetailsMonthly(
    meteringPointId,
    date
  );

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={meterDetailMonthly}
        selectededDate={date}
        segment={selectedSegment}
        period={period}
        getSelectedDate={(date) => {
          setDate(date);
        }}
        onSegmentChange={selectedSegment => {
          setDate(date);
          setSelectedSegment(selectedSegment);
        }}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default MeterDetailsMonthly;

MeterDetailsMonthly.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  meteringPointId: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  selectedSegment: PropTypes.number.isRequired,
  setSelectedSegment: PropTypes.func.isRequired,
  gettext: PropTypes.func.isRequired
};