import { gql } from "@apollo/client";

export const DELETE_USERS_CUSTOMER_ADMIN = gql`
  mutation comDeleteUsersCustomerAdmin(
    $customerName: String!
    $userId: String!
  ) {
    comDeleteUsersCustomerAdmin(customerName: $customerName, userId: $userId) {
      success
      message
    }
  }
`;
