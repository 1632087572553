import React, {forwardRef} from "react";
import {Button, Chip, Typography} from "@mui/material";
import PropTypes from "prop-types";

const ButtonInput = forwardRef((props, ref) => {
  return (
    <Button
      type={"button"}
      // size="small"
      color="secondary"
      onClick={props.onClick}
      ref={ref}
    >
      <Typography
        color="secondary.800"
        fontWeight="fontWeightMedium"
        fontSize="14px"
      >
        {props.componentLabel}
      </Typography>
      <Chip
        sx={{
          ml: 0.5,
          backgroundColor: "primary.50",
          color: "primary.main",
          fontSize: "14px"
        }}
        size="small"
        label={<Typography>{props.value}</Typography>}
      />
    </Button>
  );
});

ButtonInput.displayName = 'ButtonInput';
ButtonInput.propTypes = {
  onClick: PropTypes.func,
  componentLabel: PropTypes.string,
  value: PropTypes.string
};

export default ButtonInput;

