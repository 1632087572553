import {TableView1} from "../Table/TableView1";
import React, {useContext, useEffect, useState} from "react";
import {ConfirmNotification} from "../Dialogs";
// import { Spinner } from "react-bootstrap";
import {useDeleteRole, useRolesList} from "../../../hooks";
import {useTextDomainContext} from "../../providers";
import {Button, IconButton, Tooltip} from "@mui/material";
import {FaUserEdit} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {AddRole} from "./AddRole";
import PropTypes from "prop-types";
import {IoKeySharp} from "react-icons/io5";
import {useNavigate} from "react-router";

export const RolesList = (props) => {
  const navigate = useNavigate();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // TODO: replace deal with role
  const {
    deleteRole,
    deleting
  } = useDeleteRole();
  const {
    rolesList,
    loading
  } = useRolesList();
  //region React Hook (useState)
  const [showConfirm, setShowConfirm] = useState(false);
  const [dialogParameters, setDialogParameters] = useState({
  });
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editRow, setEditRow] = useState({
  });
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  //endregion React Hook (useState)

  //region React Hook (useEffect)
  useEffect(() => {
    setShowAdd(props.showAdd);
  }, [props.showAdd]);

  useEffect(() => {
    setShowEdit(props.showEdit);
  }, [props.showEdit]);

  useEffect(() => {
    setShowDelete(props.showDelete);
  }, [props.showDelete]);

  useEffect(() => {
    setShowPermissions(props.showPermissions);
  }, [props.showPermissions]);

  //endregion React Hook (useEffect)

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    },
    columns: {
      columnVisibilityModel: {
        id: false,
        role: true,
        key: true,
        createdAt: false,
        updatedAt: false
      }
    }
  };
  const columns = [
    {
      field: "id",
      headerName: gettext("ID"),
      checkboxSelection: true
    },
    {
      field: "role",
      headerName: gettext("Role"),
      flex: 1,
      // minWidth: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "key",
      headerName: gettext("Key"),
      flex: 1,
      // minWidth: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "createdAt",
      headerName: gettext("Created At"),
      headerAlign: "left",
      type: "date",
      valueGetter: ({value}) => value && new Date(value)
    },
    {
      field: "updatedAt",
      headerName: gettext("Updated At"),
      headerAlign: "left",
      type: "date",
      valueGetter: ({value}) => value && new Date(value)
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      hideable: false,
      minWidth: 120,
      maxWidth: 200,
      // flex: 1,
      // minWidth: 80,
      // maxWidth: 80,
      // headerAlign: "center",
      // type: "actions",
      renderCell: params => columnFormatter(params)
    }
  ];

  const performAction = (row, action) => {
    switch (action) {
      case "edit":
        handleEdit(row);
        break;
      case "delete":
        handleOnClickDeleteSingle(row);
        break;
      case "permissions":
        handleOnClickPermission(row);
        break;
      default:
        break;
    }
  };

  const columnFormatter = (params) => {
    return (
      <div className="w-100 d-flex justify-content-center">
        {showEdit && (
          <Tooltip title={gettext("Edit")} arrow>
            <IconButton onClick={() => performAction(params.row, "edit")}>
              <FaUserEdit size={16}/>
            </IconButton>
          </Tooltip>
        )}

        {showDelete && (
          <Tooltip title={gettext("Delete")} arrow>
            <IconButton onClick={() => performAction(params.row, "delete")}>
              <MdDelete size={16}/>
            </IconButton>
          </Tooltip>
        )}

        {showPermissions && (
          <Tooltip title={gettext("Permissions")} arrow>
            <IconButton onClick={() => performAction(params.row, "permissions")}>
              <IoKeySharp size={16}/>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  //region Handlers
  const handleEdit = (row) => {
    setEditRow(row);
    setAddFormOpen(true);
  };

  const handleOnClickDeleteSingle = (row) => {
    setDialogParameters({
      action: "delete-single",
      key: row.key
    });
    setShowConfirm(true);
  };

  const handleOnClickDeleteMultiple = (ids) => {
    setDialogParameters({
      action: "delete-multi",
      ids: ids
    });
    setShowConfirm(true);
  };

  const handleGetResult = (obj) => {
    if (obj.result === "yes") {
      deleteRole({
        key: obj?.parameters?.key
      });
    }
    setShowConfirm(false);
  };

  const handleOnClickPermission = (row) => {
    const key = row.key;
    navigate(`/system-admin/roles/permissions/${key}`);
  };

  const postToolbar = () => {
    return (
      <>
        {showAdd && (
          <Button variant="contained" onClick={() => {
            setAddFormOpen(true);
          }}>
            {gettext("Add role")}
          </Button>
        )}
      </>
    );
  };

  const handleCloseAddForm = (event) => {
    setAddFormOpen(event);
    setEditRow({
    });
  };
  //endregion Handlers

  return (
    <>
      <TableView1
        title={gettext("Roles")}
        rows={rolesList}
        // rows={[]}
        columns={columns}
        loading={loading}
        checkboxSelection={false}
        initialState={initialState}
        // preToolbar={preToolbar()}
        postToolbar={postToolbar()}
        // headerRight={headerRight()}
        showGridToolbar={true}
        showGridToolbarDeleteButton={false}
        showGridToolbarDensitySelector={false}
        showGridToolbarColumnsButton={false}
        showGridToolbarQuickFilter={false}
        showGridToolbarFilterButton={false}
        showGridToolbarExport={false}
        // onRowSelectionModelChange={handleOnRowSelectionModelChange}
        onClickDeleteMultiple={handleOnClickDeleteMultiple}
      />

      <AddRole open={addFormOpen} handleClose={handleCloseAddForm} row={editRow}/>

      <ConfirmNotification
        isOpene={showConfirm}
        parameters={dialogParameters}
        getResult={handleGetResult}
      />
      {deleting ? (
        <div
          className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          {/*<Spinner animation="border" variant="light" />*/}
        </div>
      ) : null}
    </>
  );
};

RolesList.propTypes = {
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
  showPermissions: PropTypes.bool
};
