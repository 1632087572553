import React, {useContext} from "react";
import {useTextDomainContext} from "../../providers";
import {toAbsoluteUrl} from "../../../utils";

// import { toAbsoluteUrl } from "../../../_metronic/_helpers";

/**
 *
 */
export const Forbidden = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{
            fontSize: "150px"
          }}
        >
          403
        </h1>
        <p className="font-size-h3 font-weight-light">
          {gettext("This area is forbidden. Turn back now!")}
        </p>
      </div>
    </div>
  );
};
