export const noBorders = (color) => {
  return {
    top: {
      style: 'thin',
      color: {
        argb: color
      }
    },
    left: {
      style: 'thin',
      color: {
        argb: color
      }
    },
    bottom: {
      style: 'thin',
      color: {
        argb: color
      }
    },
    right: {
      style: 'thin',
      color: {
        argb: color
      }
    },
  };
}; 