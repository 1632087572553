// ==============================|| OVERRIDES - BUTTON ||============================== //

/**
 *
 * @param theme
 */
export default function Alert(theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
          lineHeight: 1,
          marginTop: "5px",
          marginBottom: "5px"
        },
        outlined: {
          border: "none",
          borderLeft: "2.5px solid",
          "&.MuiAlert-outlinedSuccess": {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main
          },
          "&.MuiAlert-outlinedInfo": {
            color: theme.palette.info.main,
            borderColor: theme.palette.info.main
          },
          "&.MuiAlert-outlinedWarning": {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main
          },
          "&.MuiAlert-outlinedError": {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main
          }
        },
        standard: {
          "&.MuiAlert-standardSuccess": {
            // color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            "& .MuiAlert-icon": {
              color: "#FFFFFF",
              backgroundColor: theme.palette.success.main,
              borderRadius: "50%",
              padding: "8px"
            }
          },
          "&.MuiAlert-standardInfo": {
            // color: theme.palette.info.main,
            borderColor: theme.palette.info.main,
            "& .MuiAlert-icon": {
              color: "#FFFFFF",
              backgroundColor: theme.palette.info.main,
              borderRadius: "50%",
              padding: "8px"
            }
          },
          "&.MuiAlert-standardWarning": {
            // color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
            "& .MuiAlert-icon": {
              color: "#FFFFFF",
              backgroundColor: theme.palette.warning.main,
              borderRadius: "50%",
              padding: "8px"
            }
          },
          "&.MuiAlert-standardError": {
            // color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            "& .MuiAlert-icon": {
              color: "#FFFFFF",
              backgroundColor: theme.palette.error.main,
              borderRadius: "50%",
              padding: "8px"
            }
          }
        }
      }
    }
  };
}
