import React from "react";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import * as AppConfig from "../../../config/AppConfig";
import dayjs from "dayjs";
import PropTypes from "prop-types";

/**
 *
 * @param props
 */
export const SingleDatePicker = (props) => {
  const selectedDateValue = props.selectedDate.toISOString().split('T')[0];
  const defaultOptions = {
    label: props.componentLabel,
    defaultValue: dayjs(selectedDateValue),
    format: AppConfig.dateFormat2
  };

  const selectedOptions = {
    ...defaultOptions, ...props
  };

  const dateValueChange = event => {
    if (event) {
      props.onValueChange(new Date(event));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
      <DatePicker
        onChange={dateValueChange}
        {...selectedOptions}
        slotProps={{
          textField: {
            fullWidth: true
          }
        }}
        sx={{
          label: {
            lineHeight: 1
          }
        }}
      />
    </LocalizationProvider>
  );
};

SingleDatePicker.propTypes = {
  componentLabel: PropTypes.string,
  selectedDate: PropTypes.object,
  onValueChange: PropTypes.func,
};