import React, {createContext, useContext, useState} from "react";
import PropTypes from "prop-types";

const LocaleContext = createContext({
  locale: null,
  setLocale: () => null
});

/**
 *
 */
export function useLocale() {
  const {locale, setLocale} = useContext(LocaleContext);
  return {
    locale,
    setLocale
  };
}

/**
 *
 * @param root0
 * @param root0.children
 */
export default function LocaleProvider({children}) {
  const [locale, setLocale] = useState(undefined);

  const value = {
    locale,
    setLocale
  };

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};