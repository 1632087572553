import React, {useContext, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTextDomainContext} from "../../providers";
import PropTypes from "prop-types";

export const ConfirmNotification = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const propsDefault = {
    isOpen: false,
    title: gettext("Confirmation"),
    message: gettext("Are you sure you want to delete?"),
    parameters: {},
    result: "",
    showButtonYes: true,
    showButtonNo: true
  };

  //region useState (React Hook)
  const [isOpen, setIsOpen] = useState(typeof props.isOpen === "undefined" ? propsDefault.isOpen : props.isOpen);
  const [title, setTitle] = useState(typeof props.title === "undefined" ? propsDefault.title : props.title);
  const [message, setMessage] = useState(typeof props.message === "undefined" ? propsDefault.message : props.message);
  const [parameters, setParameters] = useState(typeof props.parameters === "undefined" ? propsDefault.parameters : props.parameters);
  const [result, setResult] = useState(propsDefault.result);
  const [showButtonYes, setShowButtonYes] = useState(typeof props.showButtonYes === "undefined" ? propsDefault.showButtonYes : props.showButtonYes);
  const [showButtonNo, setShowButtonNo] = useState(typeof props.showButtonNo === "undefined" ? propsDefault.showButtonNo : props.showButtonNo);
  //endregion useState (React Hook)

  //region useEffect (React Hook)
  useEffect(() => {
    if (!isOpen) {
      if (typeof props.getResult === "function" && result) {
        props.getResult({
          result: result,
          parameters: parameters
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (typeof props.showButtonYes !== "undefined") {
      setShowButtonYes(props.showButtonYes);
    }
  }, [props.showButtonYes]);

  useEffect(() => {
    if (typeof props.showButtonNo !== "undefined") {
      setShowButtonNo(props.showButtonNo);
    }
  }, [props.showButtonNo]);

  useEffect(() => {
    if (typeof props.parameters !== "undefined") {
      setParameters(props.parameters);
    }
  }, [props.parameters]);

  useEffect(() => {
    if (typeof props.isOpen !== "undefined") {
      setIsOpen(props.isOpen);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (typeof props.title !== "undefined") {
      setTitle(props.title);
    }
  }, [props.title]);

  useEffect(() => {
    if (typeof props.message !== "undefined") {
      setMessage(props.message);
    }
  }, [props.message]);
  //endregion useEffect (React Hook)

  const handleClick = (event) => {
    setResult(event.target.innerText.toLowerCase());
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        {showButtonYes && (
          <Button variant="contained" onClick={handleClick}>
            {gettext("No")}
          </Button>
        )}
        {showButtonNo && (
          <Button variant="contained" onClick={handleClick} color="error">
            {gettext("Yes")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmNotification.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  parameters: PropTypes.object,
  subMessage: PropTypes.string,
  getResult: PropTypes.func,
  showButtonYes: PropTypes.bool,
  showButtonNo: PropTypes.bool,
};
