export const segmentList = () => {
  return {
    year: {
      shortSegment: "m",
      dateFormat: "MMM YYYY"
    },
    month: {
      shortSegment: "d",
      dateFormat: "DD MMM YYYY"
    },
    day: {
      shortSegment: "h",
      dateFormat: "DD MMM YYYY, HH:mm"
    }
  };
};
