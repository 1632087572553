import { gql } from "@apollo/client";

export const GET_DEAL_BY_ID = gql`
query getDealById ($id: String!) {
    getDealById(
        Id: $id
    ) {
        Id
        customer
        building
        commissionPrice
        commissionShare
        currency
        energyType
        mainPriceType
        epadKwh
        epadPrice
        epadShare
        epadType
        fromDate
        mainPriceType
        markupPrice
        markupShare
        meter
        priceKwh
        pricePrice
        priceShare
        priceType
        profileKwh
        profilePrice
        profileShare
        profileType
        systemKwh
        systemPrice
        systemShare
        systemType
        toDate
        meters
        updatedAt
        createdAt
        updatedBy
        createdBy
    }
}
`;
