import React, {useContext, useState} from "react";
// import { ActiveUserListTable } from "../../../features";
import {Grid, Typography} from "@mui/material";
import {ActiveUsersTableControls} from "./components";
import {useTextDomainContext} from "../../../providers";

/**
 *
 */
export function ActiveUserList() {
  const {TextDomainContext} = useTextDomainContext();

  const {gettext} = useContext(TextDomainContext);

  const [searchString, setSearchString] = useState("");
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography fontSize={22} fontWeight={700} marginLeft={1.5}>
                {gettext("Active Users List")}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <ActiveUsersTableControls setSearchString={setSearchString}/>
              <ActiveUsersTableControls/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/*<ActiveUserListTable searchString={searchString}/>*/}
          Active user list is pending {searchString}
        </Grid>
      </Grid>
    </>
  );
}
