import { gql } from "@apollo/client";

export const ADD_MODULE = gql`
mutation comAddModule (
    $code: String!
    $name: String!
    $desc: String!
    ) 
    {
    comAddModule(
      code: $code
      name:$name,
      desc:$desc
    )
    {
      success
      message
    }
  }`;
