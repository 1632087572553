import { gql } from "@apollo/client";

export const GET_ALL_ROLES = gql`
  query getAllRoles($role: String) {
    comRoles(role: $role) {
      id
      role
      key
      modules
      createdAt
      updatedAt
    }
  }
`;

// export const GET_ALL_ROLES = gql`
//   query getAllRoles($role: String) {
//     comRoles(role: $role) {
//       id
//       role
//       key
//       isSystem
//       modules {
//         name
//         code
//       }
//       createdAt
//       updatedAt
//     }
//   }
// `;
