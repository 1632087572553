import { gql } from "@apollo/client";

export const DELETE_MODULE = gql`
  mutation comDeleteModule (
    $code: String!
  ) {
    comDeleteModule(
      code: $code
    ) {
      success
      message
    }
  }
`;
