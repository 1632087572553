import React from "react";
// import {FilterBar1} from "../../components";
import BarChart from "./BarChart";

const data = {
  "loading": false,
  "title": "Consumption measured in kWh",
  "data": {
    "dataset": [
      {
        "label": "Jan 2022",
        "consumption": 14140.63,
        "co2": 2112.877476205197,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Feb 2022",
        "consumption": 12946.39,
        "co2": 1480.81720718502,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Mar 2022",
        "consumption": 14776.595,
        "co2": 3230.6532370048235,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Apr 2022",
        "consumption": 12735.216,
        "co2": 1918.6973708499997,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "May 2022",
        "consumption": 13314.028,
        "co2": 1826.9089280931898,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Jun 2022",
        "consumption": 13723.897,
        "co2": 1705.6643727953706,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Jul 2022",
        "consumption": 7630.711,
        "co2": 757.75326956306,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Aug 2022",
        "consumption": 12230.614,
        "co2": 1668.545897902554,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Sep 2022",
        "consumption": 13105.128,
        "co2": 2041.9306221222218,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Oct 2022",
        "consumption": 12795.247,
        "co2": 1367.2479974411633,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Nov 2022",
        "consumption": 13516.984,
        "co2": 1362.8233158824075,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      },
      {
        "label": "Dec 2022",
        "consumption": 10412.612,
        "co2": 1751.983782220878,
        "water": 12000,
        "gas": 13000,
        "energy": 12500
      }
    ],
    "xDataKey": "label",
    "yLabel": "kWh",
    "series": [
      {
        "dataKey": "consumption",
        "label": "Consumption",
        "color": "#FFBF00",
        "unit": "unit1"
      },
      {
        "dataKey": "co2",
        "label": "CO₂",
        "color": "#c0c7b5",
        "unit": "unit2"
      },
      {
        "dataKey": "water",
        "label": "Water",
        "color": "#004bff",
        "unit": "unit3"
      },
      {
        "dataKey": "gas",
        "label": "Gas",
        "color": "#ED7D31",
        "unit": "unit4"
      },
      {
        "dataKey": "energy",
        "label": "Energy",
        "color": "#FA435C",
        "unit": "unit5"
      }
    ]
  },
  "dataKey": "consumption",
  "dataLabel": "Consumption",
  "energyType": "electricity"
};


/**
 *
 */
export const Page1 = () => {
  // const data = [
  //   {
  //     "label": "Jan 2022",
  //     "consumption": 14140.63,
  //     "co2": 2112.877476205197,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Feb 2022",
  //     "consumption": 12946.39,
  //     "co2": 1480.81720718502,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Mar 2022",
  //     "consumption": 14776.595,
  //     "co2": 3230.6532370048235,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Apr 2022",
  //     "consumption": 12735.216,
  //     "co2": 1918.6973708499997,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "May 2022",
  //     "consumption": 13314.028,
  //     "co2": 1826.9089280931898,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Jun 2022",
  //     "consumption": 13723.897,
  //     "co2": 1705.6643727953706,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Jul 2022",
  //     "consumption": 7630.711,
  //     "co2": 757.75326956306,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Aug 2022",
  //     "consumption": 12230.614,
  //     "co2": 1668.545897902554,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Sep 2022",
  //     "consumption": 13105.128,
  //     "co2": 2041.9306221222218,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Oct 2022",
  //     "consumption": 12795.247,
  //     "co2": 1367.2479974411633,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Nov 2022",
  //     "consumption": 13516.984,
  //     "co2": 1362.8233158824075,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   },
  //   {
  //     "label": "Dec 2022",
  //     "consumption": 10412.612,
  //     "co2": 1751.983782220878,
  //     "water": 12000,
  //     "gas": 13000,
  //     "energy": 12500
  //   }
  // ];


  // let fromDate = new Date("2018-01-01");
  // let toDate = new Date("2020-01-01");
  //
  // let dateRange = { startDate: fromDate, endDate: toDate };

  // const [building, setBuilding] = useState({});
  // const [meter, setMeter] = useState({});
  // const [dateRange, setDateRange] = useState({ startDate: fromDate, endDate: toDate });
  // useEffect(() => {
  // if (building.value) {
  //
  // }
  //
  // if (meter.value) {
  //
  // }
  // }, [building, meter]);

  // useEffect(() => {
  //   setDateRange({ startDate: fromDate, endDate: toDate });
  // }, [fromDate, toDate]);

  // const handleGetSelectedValues = (selectedValues) => {
  //
  // };

  return (
    <>
      {/*<FilterBar1*/}
      {/*  getSelectedValues={handleGetSelectedValues}*/}
      {/*/>*/}
      {/*<span></span>*/}

      <BarChart data={data}/>
    </>
  );
};
