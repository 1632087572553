import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_DEAL } from "./commands";
import { enqueueSnackbar } from "notistack";

export const useEditDeal = (
  onCompleted = () => {
  },
  onError = () => {
  }
) => {
  const [success, setSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const [editDeal, { loading, data }] = useMutation(UPDATE_DEAL, {
    onCompleted: response => {
      if (response.editEnergyDeal?.success) {
        setSuccess(true);
        setResetForm(true);
        setTimeout(() => {
          setSuccess(false);
          setResetForm(false);
        }, 5000);
        onCompleted();
      } else {
        setSuccess(false);
      }
    },
    onError: (err) => {
      setSuccess(false);
      onError(err);

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    errorPolicy: "all"
  });
  return {
    editDeal, loading, success, data, resetForm
  };
};
