import React, {useContext, useEffect, useState} from "react";
import {CommonDropdown} from "./CommonDropdown";
import PropTypes from "prop-types";
import {useTextDomainContext} from "../../providers";
import {SimpleDropdown} from "./SimpleDropdown";

export const EnergyTypeDropdownCommon = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const options = [
    {
      value: "electricity",
      label: gettext("Electricity")
    }
  ];
  const propsDefault = {
    componentLabel: gettext("Energy Type")
  };
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);

  useEffect(() => {
    if (typeof props.componentLabel !== "undefined") {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel]);

  return (
    <>
      {props.visualStyle === "simple" && (
        <SimpleDropdown
          options={options}
          componentLabel={componentLabel}
          showAll={props.showAll}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
      )}

      {props.visualStyle === "old" && (
        <CommonDropdown
          options={options}
          componentLabel={componentLabel}
          showAll={props.showAll}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
        />
      )}
    </>
  );
};

EnergyTypeDropdownCommon.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func,
  visualStyle: PropTypes.oneOf(["simple", "old"]).isRequired
};
