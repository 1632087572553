export const getMonthsArray = (gettext) => {

  return [
    {
      "value": "all",
      "label": gettext("Full Year")
    },
    {
      "value": "jan",
      "label": gettext("January")
    },
    {
      "value": "feb",
      "label": gettext("February")
    },
    {
      "value": "mar",
      "label": gettext("March")
    },
    {
      "value": "apr",
      "label": gettext("April")
    },
    {
      "value": "may",
      "label": gettext("May")
    },
    {
      "value": "jun",
      "label": gettext("June")
    },
    {
      "value": "jul",
      "label": gettext("July")
    },
    {
      "value": "aug",
      "label": gettext("August")
    },
    {
      "value": "sep",
      "label": gettext("September")
    },
    {
      "value": "oct",
      "label": gettext("October")
    },
    {
      "value": "nov",
      "label": gettext("November")
    },
    {
      "value": "dec",
      "label": gettext("December")
    }
  ];

  // {
  //   value: "",
  //     label: gettext("Select")
  // }

  // return moment.months().map(month => {
  //   const key = moment(month, 'MMMM').format('MMM').toLowerCase();
  //   return {
  //     value: key,
  //     label: month
  //   };
  // });
};