import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import {usePagePrivileges} from "../../../../../../../hooks";
import {isLocationActive} from "../../../../../../common/Methods";
import {alpha, ListItemText} from "@mui/material";
import PropTypes from "prop-types";
// import { users } from "app/providers";

/**
 *
 * @param props
 * @returns {Element}
 * @class
 */
const Level2MenuItem = (props) => {
  const {
    link,
    label,
    codes
  } = props;
  const location = useLocation();

  // const { user } = users();
  // const isSuperAdmin = Boolean(user?.isSuperAdmin);

  const allow = usePagePrivileges(codes);

  const [displayOption, setDisplayOption] = useState(false);

  const [activeColor, setActiveColor] = useState("");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isLocationActive(location, link));
  }, [location, link]);

  useEffect(() => {
    setActiveColor(isActive ? "primary.50" : "primary.100");
  }, [isActive]);

  useEffect(() => {
    if (allow) {
      setDisplayOption(true);
    } else {
      setDisplayOption(false);
    }
  }, [allow]);

  return (
    <>
      {displayOption && (
        <NavLink to={link} style={{
          textDecoration: "none"
        }}>
          <ListItem sx={{
            pl: 1.5,
            pr: 2,
            py: 0,
            color: activeColor
          }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                marginX: 1,
                borderWidth: "1px",
                borderColor: isActive ? "primary.200" : "primary.700"
                // borderColor: isActive ? "#abb8c3" : "primary.900"
              }}
            />
            <ListItemButton
              sx={{
                p: 1,
                borderRadius: "8px",
                backgroundColor: isActive
                  ? alpha("#FFFFFF", 0.28)
                  : "transparent"
              }}
            >
              <ListItemText primary={label}/>
              {/* <Typography variant="h6" sx={{ */}
              {/*   color: activeColor */}
              {/* }}> */}
              {/*   {label} */}
              {/* </Typography> */}
            </ListItemButton>
          </ListItem>
        </NavLink>
      )}
    </>
  );
};

Level2MenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  codes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Level2MenuItem;
