import {Button} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const BlueButtonStyleHeading = (props) => {
  return (
    <Button sx={{
      backgroundColor: (theme) => theme.palette.primary[50],
      fontWeight: '700',
      borderRadius: 50,
      paddingX: "15px"
    }}>
      {props.children}
    </Button>
  );
};

BlueButtonStyleHeading.propTypes = {
  children: PropTypes.node,
};