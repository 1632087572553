import { gql } from "@apollo/client";

export const DELETE_ROLE = gql`
  mutation comDeleteRole (
    $key: String!
  ) {
    comDeleteRole(
      key: $key
    ) {
      success
      message
    }
  }
`;
