export const secondary = {
  50: "#eaeeeb",
  100: "#d5dcd7",
  200: "#c0cbc3",
  300: "#abbaaf",
  400: "#97a99b",
  500: "#829786",
  600: "#6d8672",
  700: "#58755e",
  800: "#43634a",
  900: "#2e5236"
};
