import {gql} from "@apollo/client";

export const CUSTOMER_FIELDS = gql`
  fragment CustomerFields on CustomersType {
    address
    allowedBuildings
    autoId0
    city
    country
    customerKey
    customerType
    cvr
    deleted
    id
    isFabekeOwned
    label
    name
    parentName
    personalTeam
    phone
    phone2
    pnumber
    sef
    type
    userId
    users
    zip
  }
`;
