/* global process */
import React, {useRef} from "react";
import {Outlet} from "react-router";
// import "_metronic/_assets/sass/pages/login/classic/login-1.scss";
import {AppBar, Box, Grid, Link, Toolbar} from "@mui/material";
import {LanguageSelector} from "../../components";

// import LanguageSelector from "app/components/AppBar/LanguageSelector";

/**
 *
 */
export function HeaderLayout() {
  const appbarRef = useRef(null);
  return (
    <Box sx={{
      minHeight: "100%"
    }}>
      <AppBar ref={appbarRef} position="static" color="transparent">
        <Toolbar>
          <Box sx={{
            width: 220
          }}>
            <Box
              sx={{
                minWidth: "100px",
                width: "150px",
                paddingY: 2
              }}
            >
              <Link href={process.env.REACT_APP_FRONT_END_URL}>
                <img alt="logo" src={"/media/logos/energiteam-logo.png-5"}/>
              </Link>
            </Box>
          </Box>

          <Box sx={{
            flexGrow: 1
          }}/>

          <Box>
            <LanguageSelector/>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid
        container
        sx={{
          minHeight: `calc(100% - ${appbarRef?.current?.offsetHeight || 76}px )`
        }}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "34px",
              paddingRight: "24px"
            }}
          >
            <Outlet/>
          </Box>
        </Grid>
      </Grid>
      <footer></footer>
    </Box>
  );
}
