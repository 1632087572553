import {toLocaleNumber} from "../../number";

export const yearColumns = (gettext, locale) => {

  return [
    {
      field: "meter",
      headerName: gettext("Order No"),
      width: 200,
    },
    {
      field: "meterNumber",
      headerName: gettext("Meter No"),
      width: 150,
    },
    {
      field: "customer",
      headerName: gettext("Company"),
      width: 250,
    },
    // {
    //   field: "address",
    //   headerName: gettext("Address"),
    //   width: 300,
    // },
    {
      field: "streetName",
      headerName: gettext("Street"),
      width: 200,
    },
    {
      field: "buildingNumber",
      headerName: gettext("House No."),
      width: 100,
    },
    {
      field: "postcode",
      headerName: gettext("Zip Code"),
      width: 100,
    },
    {
      field: "cityName",
      headerName: gettext("City"),
      width: 150,
    },
    {
      field: "consumerCVR",
      headerName: gettext("Company No."),
      width: 100,
    },
    {
      field: "q1",
      headerName: gettext("Q1"),
      // flex: 1,
      width: 150,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "q2",
      headerName: gettext("Q2"),
      // flex: 1,
      width: 150,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "q3",
      headerName: gettext("Q3"),
      // flex: 1,
      width: 150,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "q4",
      headerName: gettext("Q4"),
      // flex: 1,
      width: 150,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "total",
      headerName: gettext("Year"),
      // flex: 1,
      width: 150,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "jan",
      headerName: gettext("Jan"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "feb",
      headerName: gettext("Feb"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "mar",
      headerName: gettext("Mar"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "apr",
      headerName: gettext("Apr"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "may",
      headerName: gettext("May"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "jun",
      headerName: gettext("Jun"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "jul",
      headerName: gettext("Jul"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "aug",
      headerName: gettext("Aug"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "sep",
      headerName: gettext("Sep"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "oct",
      headerName: gettext("Oct"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "nov",
      headerName: gettext("Nov"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
    {
      field: "dec",
      headerName: gettext("Dec"),
      // flex: 1,
      width: 100,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0),
    },
  ];
};