import {useQuery} from "@apollo/client";
import {ENERGY_DATA} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 * Custom hook to fetch energy data.
 * @param {object} variables - The variables to be passed to the query.
 * @param {Function} [onCompleted] - Callback function to be called when the query completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the query.
 * @returns {{data: Array<{id: string, value: number, timestamp: string}>, total: number, loading: boolean}} - The fetched energy data, total count, and the loading state.
 */
export function useEnergyData(
  variables,
  onCompleted = () => {
  },
  onError = () => {

  }
) {
  const {
    data,
    loading
  } = useQuery(ENERGY_DATA, {
    variables,
    onCompleted: ({energyData}) => {
      onCompleted(energyData);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    data: data?.energyData?.data || [],
    total: data?.energyData?.total || [],
    loading
  };
}
