import {Button, Drawer, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Box} from "@mui/system";
import PropTypes from "prop-types";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {useAddModule, useUpdateModule} from "../../../hooks";
import {useTextDomainContext} from "../../providers";
import {KeyTextField} from "../TextFields";

export const AddModule = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const propsDefault = {
    open: false
  };

  const [open, setOpen] = useState(typeof props.open !== "undefined" ? props.open : propsDefault.open);
  const [inputName, setInputName] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [inputDesc, setInputDesc] = useState("");
  // const [saveMode, setSaveMode] = useState("add");

  useEffect(() => {
    if (typeof props.open !== "undefined") {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.row.code) {
      setInputName(props.row.name);
      setInputCode(props.row.code);
      setInputDesc(props.row.desc);
    } else {
      setInputName("");
      setInputCode("");
      setInputDesc("");
    }
  }, [props.row]);

  const handleClose = () => {
    props.handleClose(false);
  };

  const {addModule, loading: updatingAdd} = useAddModule(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("Module added Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });
      }
    }
  );

  const {updateModule, loading: updatingEdit} = useUpdateModule(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("Module updated Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });
      }
    }
  );

  const resetForm = useCallback(() => {
    setInputCode("");
    setInputName("");
    setInputDesc("");
  }, []);

  const submitHandler = e => {
    e.preventDefault();
    const variables = {
      code: inputCode,
      name: inputName,
      desc: inputDesc
    };
    if (props.row.code) {
      updateModule({
        variables: variables,
        refetchQueries: ["getAllModules"]
      }).then();
    } else {
      addModule({
        variables: variables,
        refetchQueries: ["getAllModules"]
      }).then();
    }
  };

  const handleNameChange = (event) => {
    setInputName(event.target.value);
  };

  const handleCodeChange = (event) => {
    setInputCode(event.target.value);
  };

  const handleDescChange = (event) => {
    setInputDesc(event.target.value);
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: {
          padding: 4, maxWidth: 720
        }
      }}
    >
      <Box sx={{
        width: "auto"
      }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{
            height: "100%"
          }}>
            <Typography fontSize={22} fontWeight={700}>
              {!props.row.code && gettext("Add New Module")}
              {props.row.code && gettext("Edit Module")}
            </Typography>

            <Box sx={{
              height: "100%"
            }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label={gettext("Name")}
                    placeholder={gettext("Module name")}
                    size="small"
                    required
                    fullWidth
                    value={inputName}
                    onChange={handleNameChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <KeyTextField
                    type="text"
                    label={gettext("Code")}
                    placeholder={gettext("Module code, Alpha numeric and dash only")}
                    size="small"
                    required
                    fullWidth
                    value={inputCode}
                    onChange={handleCodeChange}
                    disabled={!!props.row.code}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    multiline
                    maxRows={4}
                    label={gettext("Description")}
                    placeholder={gettext("Description")}
                    fullWidth
                    value={inputDesc}
                    onChange={handleDescChange}
                  />
                </Grid>
              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={updatingAdd || updatingEdit}
                    fullWidth
                    type="submit"
                  >
                    {gettext("Save")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

AddModule.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  row: PropTypes.object
};
