import {Container, Grid, Stack, Typography} from "@mui/material";
import {useTextDomainContext} from "../../providers";
import React, {useContext, useState} from "react";
import {ResetPasswordForm} from "../../components";
import {useLocation} from "react-router";
import {AuthPageLayout} from "./components";

/**
 *
 */
export const ResetPassword = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const qParams = useLocation().search;
  const token = new URLSearchParams(qParams).get("token");

  const [resetPassword, setResetPassword] = useState(false);

  return (
    <AuthPageLayout>
      <Container maxWidth="xs">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {!resetPassword ? (
              <Typography fontSize={36} fontWeight={700} color="#27272A">
                {gettext("Reset Your Password")}
              </Typography>
            ) : (
              <>
                <Typography fontSize={36} fontWeight={700} color="#015731">
                  {gettext("Reset Completed")}
                </Typography>

                <Typography
                  fontSize={18}
                  fontWeight={500}
                  color="#52525B"
                  sx={{
                    pt: 1
                  }}
                >
                  {gettext(
                    "You can now use your new password to sign in to your account."
                  )}
                </Typography>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="baseline"
              sx={{
                mb: {
                  xs: -0.5,
                  sm: 0.5
                }
              }}
            >
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <ResetPasswordForm
              token={token}
              setResetPassword={setResetPassword}
            />
          </Grid>
        </Grid>
      </Container>
    </AuthPageLayout>
  );
};
