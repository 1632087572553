export const emailConfirmEmail = (gettext) => {
  return `
  <tr>
    <td>${gettext("Dear {{user_full_name}}")},</td>
  </tr>
  <tr>
    <td>${gettext("Thank you for registering with us. We're excited to have you on board!")}</td>
  </tr>
  <tr>
    <td>${gettext("To complete your registration and activate your account, please confirm your email address by clicking the below:")}
    </td>
  </tr>
  <tr>
    <td>
      <a class="link-button" href="{{confirm_email_link}}" style="color: #FFFFFF">${gettext("Confirm Email Address")}</a>
    </td>
  </tr>
`;
};