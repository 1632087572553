import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useAuth, useTextDomainContext} from "../../../providers";
import {useSnackbar} from "notistack";

/**
 *
 */
export default function AcceptSocialLogin() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const error = searchParams.get("error");

  const {setToken} = useAuth();

  const navigate = useNavigate();

  const {enqueueSnackbar} = useSnackbar();

  const {TextDomainContext} = useTextDomainContext();

  const {gettext} = React.useContext(TextDomainContext);

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate("/");
    } else {
      if (error && error === "405") {
        enqueueSnackbar(gettext("Your account doesn’t exist, please Sign Up"), {
          variant: "warning"
        });
      } else {
        enqueueSnackbar(gettext("Login failed! Please try again"), {
          variant: "warning"
        });
      }

      navigate("/auth/login", {
        replace: true
      });
    }
  }, [enqueueSnackbar, gettext, navigate, setToken, token, error]);

  // return <LayoutSplashScreen />;
  return <></>;
}
