export const secondary = {
  50: "#e6f1f6",
  100: "#c1ddea",
  200: "#9ec8dc",
  300: "#7fb2cd",
  400: "#6aa2c4",
  500: "#4b95c2",
  600: "#4188b6",
  700: "#3577a6",
  800: "#286796",
  900: "#064b7c"
};
