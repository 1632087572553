import {useMutation} from "@apollo/client";
import {useState} from "react";
import {UPDATE_USER_BY_ADMIN} from "./commands";

export const useUpdateUser = (
  onCompleted = () => {
  },
  onError = () => {
  }
) => {
  const [success, setSuccess] = useState(false);
  const [resetForm, setResetForm] = useState(false);

  const [updateUser, {loading}] = useMutation(UPDATE_USER_BY_ADMIN, {
    onCompleted: response => {
      if (response.comUpdateUserAdmin?.success) {
        setSuccess(true);
        setResetForm(true);
        setTimeout(() => {
          setSuccess(false);
          setResetForm(false);
        }, 5000);
        onCompleted();
      } else {
        setSuccess(false);
      }
    },
    onError: ({message}) => {
      setSuccess(false);
      onError(message);
    }
  });
  return {
    updateUser,
    updating: loading,
    success,
    resetForm
  };
};

/**
 *
 * @param onCompleted
 * @param onError
 */
// export function useUpdateUser(onCompleted = () => null, onError = () => null) {
//   const [comUpdateUserAdmin, { loading }] = useMutation(UPDATE_USER_BY_ADMIN, {
//     onCompleted: () => {
//       onCompleted();
//     },
//     onError: () => {
//       onError();
//     },
//     refetchQueries: ["allUsers"]
//   });
//
//   const updateUser = useCallback(
//     async variables => {
//
//       comUpdateUserAdmin({
//         variables
//       });
//     },
//     [comUpdateUserAdmin]
//   );
//
//   return {
//     updateUser,
//     updating: loading
//   };
// }
