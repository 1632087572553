import {RolesList} from "../../components";
import React from "react";

export const SystemRoles = () => {

  return (
    <RolesList
      showPermissions
    />
  );
};
