import {initIDB} from "./";

export const indexedDbCalculateSize = (dbName) => {
  return new Promise((resolve, reject) => {
    initIDB()
      .then((db) => {
        const txn = db.transaction(dbName, "readonly");
        const store = txn.objectStore(dbName);
        const request = store.openCursor();
        let totalSize = 0;

        request.onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const value = cursor.value;
            // Calculate size of the key and value
            const size = JSON.stringify(cursor.key).length + JSON.stringify(value).length;
            totalSize += size;
            cursor.continue();
          } else {
            resolve(totalSize);
            // resolve((totalSize / 1024).toFixed(2)); // Convert to KB
          }
        };

        request.onerror = function () {
          reject(0);
          // reject("Error reading IndexedDB size.");
        };

        txn.oncomplete = function () {
          db.close();
        };
      })
      .catch((err) => {
        reject(`Failed to initialize IndexedDB: ${err}`);
      });
  });
};
