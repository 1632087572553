import {Avatar, Box, ListItemButton, ListItemIcon, Tooltip, Typography} from "@mui/material";
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useUser} from "../../../../../../../providers";
import {nameInitial} from "../../../../../../../../utils";

export const UserBoxLink = props => {
  const {user} = useUser();
  const {profilePhotoUrl} = user || {
  };

  const [photo, setPhoto] = React.useState("");

  useEffect(() => {
    profilePhotoUrl && setPhoto(profilePhotoUrl);
  }, [profilePhotoUrl]);

  const handleClick = (event) => {
    if (typeof props.onClick === "function") {
      props.onClick(event);
    }
  };

  return (
    <Box>
      <Tooltip title="Account settings">
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <Avatar alt={user?.name} src={photo}>
              {nameInitial(user?.name)}
            </Avatar>
          </ListItemIcon>

          <div className="d-flex flex-column mx-2 text-left">
            <Typography
              variant="div"
              // variant="body1"
              // color="grey"
              // className="font-weight-bold"
            >
              {user?.name}
            </Typography>
          </div>
        </ListItemButton>
      </Tooltip>
    </Box>
  );
};

UserBoxLink.propTypes = {
  onClick: PropTypes.func,
};