import { merge } from "lodash";
import { ComponentsOverrides as CommonComponentsOverrides } from "../../common";
// import Alert from "./Alert";
// import AppBar from "./AppBar";
// import Button from "./Button";
// import Chip from "./Chip";
// import InputLabel from "./InputLabel";
// import OutlinedInput from "./OutlinedInput";

/**
 *
 * @param theme
 */
export const ComponentsOverrides = (theme) => {
  return merge(
    CommonComponentsOverrides(theme)
    // Alert(theme),
    // AppBar(theme),
    // Button(theme),
    // Chip(theme),
    // InputLabel(theme),
    // OutlinedInput(theme)
  );
};
