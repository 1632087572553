import { gql } from "@apollo/client";

export const GET_USER_COMPANIES = gql`
  query getUserCompanies {
    getUserCompanies{
      id
      name  
      type
      cvr
      address
      label
    }
  }
`;
