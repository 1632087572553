export const cellFill = (cell, color) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: color
    }, // Background color for "total"
  };
  cell.border = {
    top: {
      style: 'thin',
      color: {
        argb: color
      }
    },
    left: {
      style: 'thin'
    },
    bottom: {
      style: 'thin',
      color: {
        argb: color
      }
    },
    right: {
      style: 'thin'
    },
  };

  return cell;
};