import moment from "moment";

export const formatDateShort = (date) => {
  let returnDate = "";
  if (date) {
    const format = moment.localeData().longDateFormat('L');
    returnDate = moment(date).format(format);
  }
  return returnDate;
};
