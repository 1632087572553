import * as React from "react";
import {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {GridToolbarDropdown} from "./";
import {useTextDomainContext} from "../../providers";

export const EnergyReportDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // useMemo()

  const options = React.useMemo(
    () => [
      {
        value: "overall-statement",
        label: gettext("Annual Statement")
      },
      {
        value: "monthly-statement",
        label: gettext("Monthly Statement")
      },
    ],
    [gettext]
  );

  const [selectedOptions, setSelectedOptions] = React.useState(undefined);

  useEffect(() => {
    if (typeof props.selectedOption !== "undefined") {
      setSelectedOptions(options.find(obj => obj.value === props.selectedOption));
    }
  }, [options, props.selectedOption]);

  const handleOnChange = (obj) => {
    if (typeof props.onChange === "function") {
      props.onChange(obj);
    }
  };

  return (
    <GridToolbarDropdown options={options} onChange={handleOnChange} selectedOption={selectedOptions}/>
  );
};

EnergyReportDropdown.propTypes = {
  onChange: PropTypes.func,
  selectedOption: PropTypes.string,
};
