import { gql } from "@apollo/client";

export const UPDATE_DEAL = gql`
  mutation EditEnergyDeal (
    $id: String!
    $customer: String
    $building: String
    $commissionPrice: Float
    $commissionShare: Float
    $currency: String
    $energyType: String
    $epadKwh: Float
    $epadPrice: Float
    $epadShare: Float
    $epadType: String
    $fromDate: DateTime
    $mainPriceType: String
    $markupPrice: Float
    $markupShare: Float
    $meter: String
    $priceKwh: Float
    $pricePrice: Float
    $priceShare: Float
    $priceType: String
    $profileKwh: Float
    $profilePrice: Float
    $profileShare: Float
    $profileType: String
    $systemKwh: Float
    $systemPrice: Float
    $systemShare: Float
    $systemType: String
    $toDate: DateTime
  ) {
    editEnergyDeal(
      Id: $id
      customer: $customer
      building: $building
      commissionPrice: $commissionPrice
      commissionShare: $commissionShare
      currency: $currency
      energyType: $energyType
      epadKwh: $epadKwh
      epadPrice: $epadPrice
      epadShare: $epadShare
      epadType: $epadType
      fromDate: $fromDate
      mainPriceType: $mainPriceType
      markupPrice: $markupPrice
      markupShare: $markupShare
      meter: $meter
      priceKwh: $priceKwh
      pricePrice: $pricePrice
      priceShare: $priceShare
      priceType: $priceType
      profileKwh: $profileKwh
      profilePrice: $profilePrice
      profileShare: $profileShare
      profileType: $profileType
      systemKwh: $systemKwh
      systemPrice: $systemPrice
      systemShare: $systemShare
      systemType: $systemType
      toDate: $toDate
    ) {
      success
      message
    }
  }
`;

