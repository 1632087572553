import {gql} from "@apollo/client";

export const GET_BUILDING_ENERGY_PRICES = gql`
  query meterDataWithTaxes($date: Date, $buildingName: String!) {
    meterDataWithTaxes(date: $date, buildingName: $buildingName) {
      data {
        ts
        consumption
        serviceProviderFee
        price
        taxes
        totalPrice
        subscriptions
      }
    }
  }
`;