import {error} from "./error";
import {info} from "./info";
import {primary} from "./primary";
import {secondary} from "./secondary";
import {success} from "./success";
import {warning} from "./warning";
import {tertiary} from "./tertiary";
import {gray} from "./gray";
import {chart} from "./chart";

export const palette = {
  primary: {
    main: primary[700],
    ...primary
  },
  secondary: {
    main: secondary[700],
    ...secondary
  },
  error: {
    main: error[800],
    ...error
  },
  warning: {
    main: warning[800],
    ...warning
  },
  info: {
    main: info[500],
    ...info
  },
  success: {
    main: success[800],
    ...success
  },
  tertiary: {
    main: tertiary[800],
    ...tertiary
  },
  gray: {
    main: gray[800],
    ...gray
  },
  chart: {
    ...chart
  }
};
