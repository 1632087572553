import {gql} from "@apollo/client";

export const GET_COMPANIES_BUILDINGS = gql`
  query GetBuildings (
    $customerName: String!
    $subsidiaryName: String!
    ) {
    getBuildings (
      customerName: $customerName
      subsidiaryName: $subsidiaryName
    ) {
      address
      country
      label: address
      value: address
      electricity {
        label
        meterNo
      }
      heating {
        label
        meterNo
      }
      water {
        label
        meterNo
      }
      gas {
        label
        meterNo
      }
      energy {
        label
        meterNo
      }
    }
  }
`;
