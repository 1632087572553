import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from "prop-types";

export const PasswordValidator = (props) => {
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);

  const isAtLeast8Chars = password.length >= 8;
  const hasCapitalLetter = /[A-Z]/.test(password);
  const hasSmallLetter = /[a-z]/.test(password);

  useEffect(() => {
    setPassword(props.password);
  }, [props.password]);

  useEffect(() => {
    setIsValid(isAtLeast8Chars && hasCapitalLetter && hasSmallLetter);
  }, [hasCapitalLetter, hasSmallLetter, isAtLeast8Chars]);

  useEffect(() => {
    props.handleIsValid(isValid);
  }, [isValid, props]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="left"
    >
      <Box mt={2}>
        <Typography
          // variant="body1"
          sx={{
            color: isAtLeast8Chars ? 'success.main' : 'gray.400',
            display: 'flex'
          }}
        >
          <CheckIcon fontSize="small"/>
          At least 8 characters
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: hasCapitalLetter ? 'success.main' : 'gray.400',
            display: 'flex'
          }}
        >
          <CheckIcon fontSize="small"/>
          At least one capital character
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: hasSmallLetter ? 'success.main' : 'gray.400',
            display: 'flex'
          }}
        >
          <CheckIcon fontSize="small"/>
          At least one small character
        </Typography>
      </Box>
    </Box>
  );
};

PasswordValidator.propTypes = {
  handleIsValid: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired
};