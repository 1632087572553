// export const Typography = fontFamily => ({
export const Typography = {
  // htmlFontSize: 16,
  fontFamily: `"Neue Haas Grotesk Display Pro", "Roboto", "Arial", "Aeonik"`,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 900,
    fontSize: "3.75rem",
    lineHeight: "4.5rem"
  },
  h2: {
    fontWeight: 700,
    fontSize: "3rem",
    lineHeight: "3.625rem"
  },
  h3: {
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: "2.375rem"
  },
  h4: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.75rem"
  },
  h5: {
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "1.5rem"
  },
  h6: {
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "1.375rem"
  },
  body1: {
    fontSize: "1rem",
    fontWeight: 400
  },
  body2: {
    fontWeight: 300,
    fontSize: "1rem",
    color: "#555555"
  },
  caption: {
    fontWeight: "0.75rem",
    fontSize: "14px",
    lineHeight: "1rem"
  }
};
// });

