export const allBorders = () => {
  return {
    top: {
      style: 'thin'
    },
    left: {
      style: 'thin'
    },
    bottom: {
      style: 'thin'
    },
    right: {
      style: 'thin'
    },
  };
};