import {gql} from "@apollo/client";

export const UPDATE_USER_BY_ADMIN = gql`
  mutation comUpdateUserAdmin(
    $confirmPassword: String
    $countryCode: String
    $email: String
    $lang: String
    $name: String
    $password: String
    $roles: [String]
    $status: String
    $timezone: String
    $userId: String
    $partnerId: String
    $currentTeam: String
    $currentTeamId: String
    $currentSubsidiary: String
    $currentSubsidiaryId: String
  ) {
    comUpdateUserAdmin(
      confirmPassword: $confirmPassword
      countryCode: $countryCode
      email: $email
      lang: $lang
      name: $name
      password: $password
      roles: $roles
      status: $status
      timezone: $timezone
      userId: $userId
      partnerId: $partnerId
      currentTeam: $currentTeam
      currentTeamId: $currentTeamId
      currentSubsidiary: $currentSubsidiary
      currentSubsidiaryId: $currentSubsidiaryId
    ) {
      success
      message
    }
  }
`;
