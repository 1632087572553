export const secondary = {
  50: "#e9f6e7",
  100: "#cbe7c5",
  200: "#abd8a0",
  300: "#89ca7a",
  400: "#6fbf5c",
  500: "#57b33e",
  600: "#4da435",
  700: "#41922b",
  800: "#358120",
  900: "#1e620c"
};
