import React from "react";
import PropTypes from "prop-types";
import {EnergyTypeDropdownCommon} from "./EnergyTypeDropdownCommon";

export const EnergyTypeDropdown = (props) => {
  return (
    <EnergyTypeDropdownCommon
      componentLabel={props.componentLabel}
      defaultValue={props.defaultValue}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="old"
    />
  );
};

EnergyTypeDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
