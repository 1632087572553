/* global process */
import {getCacheWithoutExpiry, getGeoInfo} from "../";
import client from "../../services/apiClient";

export const sendSlackMessage = async params => {
  const {REACT_APP_API_URL_GRAPHQL, REACT_APP_NODE_ENV} = process.env;
  let fieldsString = "";
  const user = await getCacheWithoutExpiry("me");
  const userId = user && user.id ? user.id : "N/A";
  const userEmail = user && user.email ? user.email : "N/A";
  const userName = user && user.name ? user.name : "N/A";
  const teamName = user && user.currentTeam ? user.currentTeam : "NA";
  if (!Array.isArray(params?.fields)) {
    params.fields = [];
  }

  const defaultFields = [
    {
      title: "User ID",
      value: userId
    },
    {
      title: "User Name",
      value: userName
    },
    {
      title: "User Email",
      value: userEmail
    },
    {
      title: "User Team",
      value: teamName
    }
  ];
  Array.prototype.push.apply(params.fields, defaultFields);

  const defaultParam = {
    channel: "error",
    type: "error",
    heading: window.location.href,
    detail: "",
    fields: defaultFields
  };

  const options = {
    ...defaultParam,
    ...params
  };

  getGeoInfo().then(geoData => {
    let fields = [
      {
        title: "Environment",
        value: REACT_APP_NODE_ENV
      }
    ];

    if (geoData) {
      fields = [
        {
          title: "Environment",
          value: REACT_APP_NODE_ENV
        },
        {
          title: "IP Address",
          value: geoData.ip ? geoData.ip : "N/A"
        },
        {
          title: "Country Name",
          value: geoData.country_name ? geoData.country_name : "N/A"
        },
        {
          title: "City Name",
          value: geoData.city ? geoData.city : "N/A"
        }
      ];
    }

    const fieldsMerged = [...options.fields, ...fields];

    options.fields = fieldsMerged.filter(
      (v, i, a) =>
        a.findIndex(v2 => ["title", "value"].every(k => v2[k] === v[k])) === i
    );

    if (options.fields.length > 0) {
      fieldsString = "fields: [\n";
      options.fields.forEach(obj => {
        fieldsString += "{\n";
        Object.keys(obj).forEach(obj2 => {
          fieldsString += obj2 + ": \"" + obj[obj2] + "\"\n";
        });
        fieldsString += "},\n";
      });
      fieldsString += "]";
    }

    const data = JSON.stringify({
      query: `mutation{
        sendSlackMessage(slackData: {
            channel: "${options.channel}"
            type: "${options.type}"
            heading: "${options.heading}"
            detail: "${options.detail}"
            ${fieldsString}
        })
        {
            success
            message
        }
    }`,
      variables: {}
    });

    const Options = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    client
      .post(REACT_APP_API_URL_GRAPHQL, data, Options)
      // .then(function (response) {
      .then(function () {
        // ;
      })
      // .catch(function (error) {
      .catch(function () {
        //
      });

    // axios(config)
  });
};