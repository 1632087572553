import React, {useState} from 'react';
import * as XLSX from 'xlsx';

export const ExcelProcessor = () => {
  const [workbook, setWorkbook] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const wb = XLSX.read(binaryStr, {
          type: 'binary'
        });
        setWorkbook(wb);
      };
      reader.readAsBinaryString(file);
    }
  };

  const modifyCell = () => {
    if (workbook) {
      const sheetName = workbook.SheetNames[2]; // Access the third sheet
      const sheet = workbook.Sheets[sheetName];
      sheet['A10'] = {
        v: 'Test Value'
      }; // Set the value of cell A10
      saveFile(workbook);
    }
  };

  const saveFile = (wb) => {
    XLSX.writeFile(wb, 'modified_data.xls', {
      bookType: 'xls'
    });
  };

  return (
    <div>
      <input type="file" accept=".xls" onChange={handleFileUpload}/>
      <button onClick={modifyCell}>Modify Cell and Save</button>
    </div>
  );
};

