import {Typography as CommonTypography} from "../common";

export const Typography = () => ({
  // htmlFontSize: 16,
  // fontFamily: `"Roboto", "Neue Haas Grotesk Display Pro", "Arial"`,
  // fontWeightLight: 300,
  // fontWeightRegular: 400,
  // fontWeightMedium: 500,
  // fontWeightBold: 600,
  // h1: {
  //   fontWeight: 900,
  //   fontSize: "4.5rem",
  //   lineHeight: 1.28
  // },
  // h2: {
  //   fontWeight: 700,
  //   fontSize: "2.25rem",
  //   lineHeight: "40px"
  // },
  // h3: {
  //   fontWeight: 700,
  //   fontSize: "1.5rem",
  //   lineHeight: "32px"
  // },
  // h4: {
  //   fontWeight: 700,
  //   fontSize: "1.25rem",
  //   lineHeight: "28px"
  // },
  // h5: {
  //   fontWeight: 700,
  //   fontSize: "1.125rem",
  //   lineHeight: "28px"
  // },
  // h6: {
  //   fontWeight: 400,
  //   fontSize: "0.875rem",
  //   lineHeight: 1.57
  // },
  // body1: {
  //   fontWeight: 400,
  //   fontSize: "18px"
  // },
  // body2: {
  //   fontWeight: 400,
  //   fontSize: "16px"
  // },
  // caption: {
  //   fontWeight: 400,
  //   fontSize: "14px",
  //   lineHeight: 1
  // },
  ...CommonTypography
});

