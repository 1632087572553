import { useQuery } from "@apollo/client";
import {GET_DATAHUB_BUILDING_METERS,
  GET_DATAHUB_CUSTOMERS,
  GET_DATAHUB_CUSTOMER_BUILDINGS,
  GET_DATAHUB_METER_DETAILS,
  GET_DATAHUB_METER_DETAILS_MONTHLY,
  GET_DATAHUB_METER_DETAILS_YEARLY} from "../commands";

/**
 *
 */
export function useDataHubCustomers() {
  const { data, loading } = useQuery(GET_DATAHUB_CUSTOMERS, {
    // fetchPolicy: "no-cache"
  });

  return {
    customersList: data?.dataHubCustomers || [],
    loading
  };
}

/**
 *
 * @param id
 */
export function useDataHubCustomerBuildings(id) {
  const { data, loading } = useQuery(GET_DATAHUB_CUSTOMER_BUILDINGS, {
    variables: {
      id
    }
  });

  return {
    buildingsList: data?.dataHubCustomerBuildings || [],
    loading
  };
}

/**
 *
 * @param meterIdList
 */
export function useDataHubBuildingMeters(meterIdList) {
  const { data, loading } = useQuery(GET_DATAHUB_BUILDING_METERS, {
    variables: {
      meterIdList
    }
  });

  return {
    metersList: data?.datahubBuildingMeters || [],
    loading
  };
}

/**
 *
 * @param meteringPointId
 * @param daily
 */
export function useDataHubMeterDetailsDaily(meteringPointId, daily) {
  const { data, loading } = useQuery(GET_DATAHUB_METER_DETAILS, {
    variables: {
      meteringPointId,
      daily
    }
  });

  return {
    meterDetailDaily: data?.datahubMeteringdataDaily || [],
    loading
  };
}

/**
 *
 * @param meteringPointId
 * @param month
 */
export function useDataHubMeterDetailsMonthly(meteringPointId, month) {
  const { data, loading } = useQuery(GET_DATAHUB_METER_DETAILS_MONTHLY, {
    variables: {
      meteringPointId,
      month
    }
  });

  return {
    meterDetailMonthly: data?.datahubMeteringdataTs[0]?.dailyData || [],
    period: data?.datahubMeteringdataTs[0]?.period || {
    },
    loading
  };
}

/**
 *
 * @param meteringPointId
 * @param year
 */
export function useDataHubMeterDetailsYearly(meteringPointId, year) {
  const { data, loading } = useQuery(GET_DATAHUB_METER_DETAILS_YEARLY, {
    variables: {
      meteringPointId,
      year
    }
  });

  return {
    meterDetailYearly: data?.datahubMeteringdataTs || [],
    loading
  };
}
