import { palette } from "./palette";
import { Typography } from "./";
import { ThemeOptions as CommonThemeOptions } from "../common";

export const ThemeOptions = () => ({
  // direction: "rtl",
  // zIndex: {
  //   mobileStepper: 1000,
  //   fab: 1010,
  //   speedDial: 1010,
  //   appBar: 1100,
  //   drawer: 1200,
  //   model: 1300,
  //   snackbar: 1400,
  //   tooltip: 1500
  // },
  // // props: ComponentsOverrides,
  // components: ComponentsOverrides,

  // props: {
  //   MuiButtonBase: {
  //     disableRipple: false
  //   },
  //   MuiPopover: {
  //     elevation: 1
  //   }
  // },
  // typography: {
  //   fontFamily: "Aeonik"
  // }
  // customShadows: CustomShadows,
  palette,
  typography: Typography,
  ...CommonThemeOptions
});

