import { Typography } from "./Typography";
import { palette } from "./palette";
// import { ComponentsOverrides } from "./overrides";

export const ThemeOptions = () => ({
  // direction: "rtl",
  zIndex: {
    mobileStepper: 1000,
    fab: 1010,
    speedDial: 1010,
    appBar: 1100,
    drawer: 1200,
    model: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  // props: {
  //   MuiButtonBase: {
  //     disableRipple: false
  //   },
  //   MuiPopover: {
  //     elevation: 1
  //   }
  // },
  palette,
  typography: Typography
  // props: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: 80
  //       }
  //     }
  //   }
  // }
  // props: ComponentsOverrides,
  // components: ComponentsOverrides
  // typography: {
  //   // fontFamily: "Aeonik"
  //   fontFamily: "Neue Haas Grotesk Display Pro"
  // }
  // customShadows,
});

