/* global process */
import {Stack} from "@mui/system";
import {Autocomplete, Grid, TextField} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTextDomainContext, useUser} from "../../providers";
import {useCountries, useTimezones, useUpdateUser} from "../../../hooks";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {isEqual} from "lodash";
import PropTypes from "prop-types";

export const CountryTimezoneForm = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {countries, loading: countriesLoading} = useCountries();
  const {timezones, loading: timezonesLoading} = useTimezones();
  const {user, setUser} = useUser();

  const initialValue = useMemo(() => {
    return {
      timezonesList: [],
      inputCountry: countries[0] || null,
      inputTimezone: timezones[0] || null,
    };
  }, [countries, timezones]);

  //region React Hook (useState)
  const [timezonesList, setTimezonesList] = useState(initialValue.timezonesList);
  const [inputCountry, setInputCountry] = useState(initialValue.inputCountry);
  const [inputTimezone, setInputTimezone] = useState(initialValue.inputTimezone);
  //endregion React Hook (useState)

  //region React Hook (useEffect)
  useEffect(() => {
    if (inputCountry && timezones) {
      const filteredTimezones = timezones.filter(
        timezone => timezone.countryCode === inputCountry.code
      );
      setTimezonesList(filteredTimezones);
      setInputTimezone(filteredTimezones[0]);
    } else {
      setTimezonesList([]);
    }
  }, [inputCountry, timezones]);

  useEffect(() => {
    if (!inputCountry?.code) {
      if (countries.length) {
        if (!isEqual(inputCountry, countries[0])) {
          setInputCountry(countries[0]);
        }
      }
    }
  }, [countries, initialValue, inputCountry]);
  //endregion React Hook (useEffect)

  const {updateUser, loading: updating} = useUpdateUser(
    () => {
      handleClose();
      enqueueSnackbar(gettext("User Updated Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        if (process.env.REACT_APP_NODE_ENV !== "production") {
          enqueueSnackbar(`Backend Error: ${error}`, {
            variant: "error"
          });
        }
      }
    }
  );

  const submitHandler = e => {
    e.preventDefault();

    const variables = {
      userId: user.id,
      countryCode: inputCountry?.code,
      timezone: inputTimezone.zoneName,
    };

    const updatedUser = {
      ...user,
      ...variables
    };

    updateUser({
      variables: variables,
      refetchQueries: ["AllUsers", "SystemUsers", "PartnerUsers", "CompanyUsers", "useMe"]
    }).then(() => {
      setUser(updatedUser);
    });
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <form onSubmit={submitHandler}>
      <Stack spacing={4} sx={{
        height: "100%"
      }}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            options={countries}
            getOptionLabel={option => option.name || ""}
            getOptionKey={option => option.code || ""}
            value={inputCountry}
            onChange={(e, newValue) => {
              setInputCountry(newValue);
              // setInputTimezone(initialValue.inputTimezone);
            }}
            size="small"
            fullWidth
            isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
            renderInput={params => (
              <TextField
                {...params}
                required
                label={gettext("Country")}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Autocomplete
            disablePortal
            options={timezonesList}
            getOptionLabel={option => option.zoneName || ""}
            getOptionKey={option => option.zoneName || ""}
            value={inputTimezone}
            onChange={(e, newValue) => setInputTimezone(newValue)}
            size="small"
            fullWidth
            isOptionEqualToValue={(option, value) => option?.value?.toLowerCase() === value?.value?.toLowerCase()}
            renderInput={params => (
              <TextField
                {...params}
                required
                label={gettext("Timezone")}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <LoadingButton
            variant="contained"
            loading={updating || countriesLoading || timezonesLoading}
            fullWidth
            type="submit"
          >
            {gettext("Save")}
          </LoadingButton>
        </Grid>
      </Stack>
    </form>
  );
};

CountryTimezoneForm.propTypes = {
  handleClose: PropTypes.func.isRequired
};