import React from "react";
import {BuildingDropdownCommon} from "./BuildingDropdownCommon";
import PropTypes from "prop-types";

export const BuildingDropdownSimple = (props) => {
  return (
    <BuildingDropdownCommon
      componentLabel={props.componentLabel}
      defaultValue={props.defaultValue}
      currentTeam={props.currentTeam}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="simple"
    />
  );
};

BuildingDropdownSimple.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};