import {UserCustomers} from "../../components";
import React from "react";

export const SystemAllUserCustomers = () => {

  return (
    <UserCustomers
      showAdd
      showDelete
    />
  );
};
