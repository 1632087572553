import {Button, Drawer, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {Box} from "@mui/system";
import PropTypes from "prop-types";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {useAddRole, useUpdateRole} from "../../../hooks";
import {useTextDomainContext} from "../../providers";
import {KeyTextField} from "../TextFields";

export const AddRole = (props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const propsDefault = {
    open: false
  };

  const [open, setOpen] = useState(typeof props.open !== "undefined" ? props.open : propsDefault.open);
  const [inputRole, setInputRole] = useState("");
  const [inputKey, setInputKey] = useState("");

  useEffect(() => {
    if (typeof props.open !== "undefined") {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.row.key) {
      setInputRole(props.row.role);
      setInputKey(props.row.key);
    } else {
      setInputRole("");
      setInputKey("");
    }
  }, [props.row]);

  const handleClose = () => {
    props.handleClose(false);
  };

  const {addRole, loading: updatingAdd} = useAddRole(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("Role added Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });
      }
    }
  );

  const {updateRole, loading: updatingEdit} = useUpdateRole(
    () => {
      resetForm();
      handleClose();
      enqueueSnackbar(gettext("Role updated Successfully"), {
        variant: "success"
      });
    },
    error => {
      if (error === "409") {
        // setEmailError(true);
      } else if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });
      }
    }
  );

  const resetForm = useCallback(() => {
    setInputKey("");
    setInputRole("");
  }, []);

  const submitHandler = e => {
    e.preventDefault();
    const variables = {
      key: inputKey,
      role: inputRole
    };
    if (props.row.key) {
      updateRole({
        variables: variables,
        refetchQueries: ["getAllRoles"]
      }).then();
    } else {
      addRole({
        variables: variables,
        refetchQueries: ["getAllRoles"]
      }).then();
    }
  };

  const handleRoleChange = (event) => {
    setInputRole(event.target.value);
  };

  const handleKeyChange = (event) => {
    setInputKey(event.target.value);
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: theme => theme.zIndex.appBar + 1
      }}
      PaperProps={{
        sx: {
          padding: 4, maxWidth: 720
        }
      }}
    >
      <Box sx={{
        width: "auto"
      }}>
        <form onSubmit={submitHandler}>
          <Stack spacing={4} sx={{
            height: "100%"
          }}>
            <Typography fontSize={22} fontWeight={700}>
              {!props.row.key && gettext("Add New Role")}
              {props.row.key && gettext("Edit Role")}
            </Typography>

            <Box sx={{
              height: "100%"
            }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label={gettext("Role")}
                    placeholder={gettext("Role")}
                    size="small"
                    required
                    fullWidth
                    value={inputRole}
                    onChange={handleRoleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <KeyTextField
                    type="text"
                    label={gettext("Key")}
                    placeholder={gettext("Role key, Alpha numeric and dash only")}
                    size="small"
                    required
                    fullWidth
                    value={inputKey}
                    onChange={handleKeyChange}
                    disabled={!!props.row.key}
                  />
                </Grid>

              </Grid>
            </Box>

            <Paper elevation={0}>
              <Grid container spacing={4}>
                <Grid item xs={6} textAlign="center">
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClose()}
                  >
                    {gettext("Cancel")}
                  </Button>
                </Grid>

                <Grid item xs={6} textAlign="center">
                  <LoadingButton
                    variant="contained"
                    loading={updatingAdd || updatingEdit}
                    fullWidth
                    type="submit"
                  >
                    {gettext("Save")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

AddRole.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  row: PropTypes.object
};
