export const getLocalStorageSize = () => {
  let total = 0;
  for (const key in localStorage) {
    // if (localStorage.hasOwnProperty(key)) {
    if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
      const value = localStorage[key];
      total += key.length + value.length;
    }
  }
  return total;
};