import React, {useContext, useEffect, useMemo, useState} from "react";
import {useFormik} from "formik";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import * as Yup from "yup";
import {useTextDomainContext} from "../../providers";
import {useCountries, useRegister, useTimezones} from "../../../hooks";
import {useSearchParams} from "react-router-dom";
import detectBrowserLanguage from "detect-browser-language";
import {getGeoInfo, getPartnerKey} from "../../../utils";

// import { GoogleSigninButton } from "app/components";

/**
 *
 */
export const RegistrationForm = () => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const {countries} = useCountries();
  const {timezones} = useTimezones();
  const partnerKey = getPartnerKey();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inputCountry, setInputCountry] = useState("DK");
  const [inputTimezone, setInputTimezone] = useState("Europe/Copenhagen");

  useEffect(() => {
    getGeoInfo().then(ipData => {
      const country = countries.find(country => {
        return country.code === ipData.country_code;
      });

      if (country) {
        setInputCountry(country.code);
      } else {
        setInputCountry("DK");
      }

      const timezone = timezones.find(timezone => {
        return timezone.zoneName === ipData.timezone;
      });

      if (timezone) {
        setInputTimezone(timezone.zoneName);
      } else {
        setInputTimezone("Europe/Copenhagen");
      }
    });
  }, [countries, timezones]);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(255)
          .required(gettext("Name is required")),
        email: Yup.string()
          .email(gettext("Must be a valid email"))
          .max(255)
          .required(gettext("Email is required")),
        password: Yup.string()
          .min(8, gettext("Password must be at least 8 characters"))
          .required(gettext("Password is required")),
        confirmPassword: Yup.string().when("password", {
          is: val => (val && val.length > 0),
          then: () => Yup.string()
            .oneOf([Yup.ref("password")], gettext("Passwords must match"))
            .required(gettext("Confirm password is required"))
        })
      }),
    [gettext]
  );

  const {
    submit,
    isSubmitting
  } = useRegister(
    () => {
    },
    error => {
      if (error.message === "415") {
        formik.setErrors({
          submit: gettext("Email already exists.")
        });
      } else {
        formik.setErrors({
          submit: gettext("Unable to create account. ") + error.message
        });
      }
    }
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      submit: null,
      tc: false
    },
    onSubmit: (values, {setErrors}) => {
      // submit the form here
      if (values.tc) {
        submit({
          email: values.email,
          name: values.name,
          password: values.password,
          partnerKey: partnerKey,
          countryCode: inputCountry,
          timezone: inputTimezone,
          lang: detectBrowserLanguage()?.split("-")[0] || "en",
          customerType: "company",
          status: "Active",
          token
        });
      } else {
        setErrors({
          submit: gettext("You must agree to our Terms & Conditions and Privacy Policy")
        });
      }
    },
    validationSchema
  });

  return (
    <Box sx={{
      marginY: 1
    }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Collapse in={Boolean(formik.errors.submit)}>
              <Alert variant="outlined" severity="error">
                {formik.errors.submit}
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <OutlinedInput
                id="name-signup"
                type="text"
                size="large"
                value={formik.values.name}
                name="name"
                placeholder={gettext("Enter full name")}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
                error={Boolean(formik.touched.name && formik.errors.name)}
              />
              {formik.touched.name && formik.errors.name && (
                <FormHelperText error id="helper-text-name-signup">
                  {formik.errors.name}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <OutlinedInput
                fullWidth
                size="large"
                error={Boolean(formik.touched.email && formik.errors.email)}
                id="email-signup"
                type="email"
                placeholder={gettext("Enter email address")}
                value={formik.values.email}
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                inputProps={{}}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText error id="helper-text-email-signup">
                  {formik.errors.email}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <OutlinedInput
                fullWidth
                size="large"
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                id="password-signup"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                name="password"
                placeholder={gettext("Enter password")}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end" sx={{
                    color: "#D4D4D8"
                  }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(prev => !prev)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                      color="inherit"
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{}}
              />
              {formik.touched.password && formik.errors.password && (
                <FormHelperText error id="helper-text-password-signup">
                  {formik.errors.password}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <OutlinedInput
                fullWidth
                size="large"
                error={Boolean(
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                )}
                id="confirm-password-signup"
                type={showPassword ? "text" : "password"}
                value={formik.values.confirmPassword}
                name="confirmPassword"
                placeholder={gettext("Confirm password")}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                inputProps={{}}
                endAdornment={
                  <InputAdornment position="end" sx={{
                    color: "#D4D4D8"
                  }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(prev => !prev)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                      color="inherit"
                    >
                      {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <FormHelperText error id="helper-text-confirm-password-signup">
                  {formik.errors.confirmPassword}
                </FormHelperText>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.tc}
                  onChange={event =>
                    formik.setFieldValue("tc", event.target.checked)
                  }
                  name="checked"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  {gettext("I agree with")} &nbsp;
                  <Link variant="subtitle2" href="/terms">
                    {gettext("Terms & Conditions")}
                  </Link>
                  &nbsp; {gettext("and")} &nbsp;
                  <Link variant="subtitle2" href="/privacy">
                    {gettext("Privacy Policy")}
                  </Link>
                </Typography>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              disableElevation
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              {gettext("Sign up")}
            </Button>
          </Grid>

          {/* <Grid item xs={12}>
            <GoogleSigninButton />
          </Grid> */}
        </Grid>
      </form>
    </Box>
  );
};
