import React, { useContext, useEffect, useState } from "react";
import { CommonDropdown } from "./CommonDropdown";
import PropTypes from "prop-types";
import { useTextDomainContext } from "../../providers";

export const StatusDropdown = (props) => {
  const { TextDomainContext } = useTextDomainContext();
  const { gettext } = useContext(TextDomainContext);
  const options = [
    {
      value: "active",
      label: gettext("Active")
    },
    {
      value: "inactive",
      label: gettext("InActive")
    },
    {
      value: "deleted",
      label: gettext("Deleted")
    },
    {
      value: "blocked",
      label: gettext("Blocked")
    }
  ];
  const propsDefault = {
    componentLabel: gettext("Status")
  };
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);

  useEffect(() => {
    if (typeof props.componentLabel !== "undefined") {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel]);

  return (
    <CommonDropdown
      options={options}
      componentLabel={componentLabel}
      showAll={props.showAll}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
    />
  );
};

StatusDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
