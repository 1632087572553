import {gql} from "@apollo/client";
import {CUSTOMER_FIELDS} from "./fragments";

export const CUSTOMERS = gql`
  query GetCustomers ($userId: String, $customerId: String) {
    getCustomers(userId: $userId, customerId: $customerId) {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FIELDS}
`;

