export const emailHeader = (gettext, locale, partner, colors) => {
  const buttonColor = colors[partner.key];
  let logoStyle = "";
  switch (partner.key) {
    case "fabeke":
      logoStyle = "width: 97px;";
      break;
    case "energiteam":
      logoStyle = "width: 97px;";
      break;
    case "absolute-sight":
      logoStyle = "height: 36px;";
      break;
    default:
      logoStyle = "width: 97px;";
      break;
  }

  return `
  <!DOCTYPE html>
<html lang="${locale}">
<head>
  <meta content="width=device-width, initial-scale=1.0" name="viewport">
  <meta content="text/html; charset=UTF-8" http-equiv="Content-Type">
  <title>Title</title>
  <style media="all" type="text/css">
      body {
          font-family: Helvetica, sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          line-height: 1.3;
          background-color: #ffffff;
          color: #6B7280;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
      }

      table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
      }

      table td {
          font-family: Helvetica, sans-serif;
          color: #6B7280;
          font-size: 16px;
          vertical-align: top;
          padding-left: 40px;
          padding-right: 40px;
          padding-top: 10px;
          padding-bottom: 10px;
      }

      .body {
          max-width: 485px;
          background-color: #f9fafb;
          border: 1px solid #e5e7eb;
          border-radius: 5px;
          margin-left: auto;
          margin-right: auto;
      }

      .link-button {
          display: block;
          width: 100%;
          line-height: 48px;
          text-decoration: none;
          color: #ffffff;
          font-weight: bold;
          text-align: center;
          border-radius: 5px;
          background-color: ${buttonColor};
      }
  </style>
</head>
<body>
<table border="0" cellpadding="0" cellspacing="0" class="body" role="presentation">
  <tr>
    <td style="padding-top: 30px">
      <img alt="${partner.name}" src="${partner.appUrl}/media/partners/${partner.key}/logos/logo-dark.png"
           style="${logoStyle}" />
    </td>
  </tr>
  `;
};