import {Grid} from "@mui/material";
import DataHubTable from "./components/DataHubTable";
import {useDataHubCustomerBuildings} from "./hooks";
import PropTypes from "prop-types";
import React from "react";

const DatahubBuildings = props => {
  const {view, setView, id, setSelectedItem, selectedItem, gettext} = props;
  const {buildingsList, loading} = useDataHubCustomerBuildings(id);

  return (
    <Grid item>
      <DataHubTable
        title={view}
        setView={setView}
        view={view}
        rows={buildingsList}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        gettext={gettext}
        loading={loading}
      />
    </Grid>
  );
};
export default DatahubBuildings;

DatahubBuildings.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
  id: PropTypes.string,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
  gettext: PropTypes.func
};