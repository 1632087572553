import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import {useTextDomainContext} from "../../providers";
import PropTypes from "prop-types";
import {IoIosArrowDown} from "react-icons/io";

export const GridToolbarDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [options, setOptions] = useState(typeof props.options === "undefined" ? [] : props.options);
  const [selectedOption, setSelectedOption] = useState(typeof props.selectedOption === "undefined" ? {
    value: "",
    label: gettext("Select")
  } : props.selectedOption);

  useEffect(() => {
    if (typeof props.options !== "undefined" && props.options.length) {
      setOptions(props.options);
      if (typeof props.selectedOption === "undefined") {
        setSelectedOption(props.options[0]);
      } else {
        setSelectedOption(props.selectedOption);
      }

    }
  }, [props.options, props.selectedOption]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (obj) => {
    setSelectedOption(obj);
    if (typeof props.onChange === "function") {
      props.onChange(obj);
    }
    handleClose();
    return null;
  };

  // const handleExportToPdf = () => {
  //   ExportToPdf(data, `${fileName}.pdf`);
  //   handleClose();
  // };

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<IoIosArrowDown size={14}/>}
        // disabled={!data?.length}
        sx={{
          ml: 1,
          color: "grey.600",
          fontWeight: 400,
        }}
      >
        {selectedOption.label}
      </Button>

      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {options.map((obj) => (
          <MenuItem
            key={obj.value}
            onClick={() => handleSelect(obj)}
            sx={{
              fontSize: "0.875rem",
              color: "grey.600",
              fontWeight: 300
            }}
          >
            {obj.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

GridToolbarDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  selectedOption: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
};
