import { gql } from "@apollo/client";

export const UPDATE_ROLE_MODULES = gql`
mutation addOrUpdateRoleModule (
    $moduleCodes: [String]
    $roleKey: String!
    ) 
    {
    addOrUpdateRoleModule(
      moduleCodes: $moduleCodes
      roleKey:$roleKey,
    )
    {
      success
      message
    }
  }`;
