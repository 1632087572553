import {gql} from "@apollo/client";

export const UPDATE_CURRENT_USER_PROFILE = gql`
  mutation ComUpdateAccount (
    $countryCode: String
    $currentSubsidiary: String
    $currentSubsidiaryId: String
    $currentTeam: String
    $currentTeamId: String
    $file: String
    $lang: String
    $name: String
    $profilePhotoPath: String
    $timezone: String
  ) {
    comUpdateAccount(
      countryCode: $countryCode
      currentSubsidiary: $currentSubsidiary
      currentSubsidiaryId: $currentSubsidiaryId
      currentTeam: $currentTeam
      currentTeamId: $currentTeamId
      file: $file
      lang: $lang
      name: $name
      profilePhotoPath: $profilePhotoPath
      timezone: $timezone
    ) {
      success
      message
      user {
        id
        name
        email
        customerKey
        lang
        profilePhotoPath
        profilePhotoUrl
        countryCode
        timezone
        isSuperAdmin
        roles
        isVerified
        isActive
        currentTeam
        currentTeamId
        currentSubsidiary
        currentSubsidiaryId
        permissions {
          role
          modules
        }
        partner {
          id
          key
          name
          companyUrl
          appUrl
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;
