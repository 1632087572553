import {merge} from "lodash";
import Alert from "./Alert";
import AlertTitle from "./AlertTitle";
import AppBar from "./AppBar";
import Chip from "./Chip";
import InputLabel from "./InputLabel";
import OutlinedInput from "./OutlinedInput";
import Button from "./Button";
import TextField from "./TextField";

/**
 *
 * @param theme
 */
export const ComponentsOverrides = (theme) => {
  return merge(
    Alert(theme),
    AlertTitle(theme),
    AppBar(theme),
    Button(theme),
    Chip(theme),
    InputLabel(theme),
    OutlinedInput(theme),
    TextField(theme)
  );
};
