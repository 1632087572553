import {useEffect, useState} from "react";

/**
 * TODO: We need to update this description
 * @param {string} res Response string
 * @returns {string} value
 */
export const useApiResponse = (res) => {
  const [value, setValue] = useState(res);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(res)) {
      setValue(res);
    }
  }, [res, value]);

  return value;
};
