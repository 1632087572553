import md5 from "md5";
import {update} from "../../app/common/IndexedDb";

export const setCacheWithExpiry = (key, value, ttl = 0) => {
  const now = new Date();
  key = md5(key);
  const expiry = now.getTime() + ttl * 1000;

  if (key) {
    update("cache", {
      key: key,
      value: value,
      expiry: expiry
    }, key).then(
      () => {

      }
      // error => {
      //
      // }
    );
  }
  return null;
};