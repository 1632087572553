import {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../../providers";
import PropTypes from "prop-types";

export const usePriceTypes = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const priceTypes1 = [
    {
      value: "fixed-price",
      label: gettext("Fixed price"),
      // price: true,
      price: false,
      system: true,
      epad: true,
      profile: false,
      markup: true,
      commission: true
    },
    {
      value: "fixed-price-ex-profile",
      label: gettext("Fixed price ex profile"),
      price: false,
      system: true,
      epad: true,
      profile: false,
      markup: false,
      commission: true
    },
    {
      value: "fixed-price-ex-epad",
      label: gettext("Fixed price ex epad"),
      price: false,
      system: true,
      epad: false,
      profile: false,
      markup: true,
      commission: true
    },
    {
      value: "fixed-price-ex-epad-and-profile",
      label: gettext("Fixed price ex epad and profile"),
      price: false,
      system: true,
      epad: false,
      profile: false,
      markup: false,
      commission: true
    },
    // {
    //   value: "fixed-price-ex-system",
    //   label: gettext("Fixed price ex system"),
    //   price: false,
    //   system: false,
    //   epad: true,
    //   profile: true,
    //   markup: true,
    //   commission: true
    // },
    // {
    //   value: "fixed-price-ex-system-and-profile",
    //   label: gettext("Fixed price ex system and profile"),
    //   price: false,
    //   system: false,
    //   epad: true,
    //   profile: false,
    //   markup: true,
    //   commission: true
    // },
    // {
    //   value: "fixed-price-ex-system-and-epad",
    //   label: gettext("Fixed price ex system and epad"),
    //   price: false,
    //   system: false,
    //   epad: false,
    //   profile: true,
    //   markup: true,
    //   commission: true
    // },
    {
      value: "variable-spot-nordpool-price",
      label: gettext("Variable/Spot/Nordpool price"),
      price: false,
      system: false,
      epad: false,
      profile: false,
      markup: false,
      commission: true
    }
    // {
    //   value: "fixed-price-with-commission-ex-epad-and-profile",
    //   label: gettext("Fixed price with commission ex epad and profile"),
    //   price: false,
    //   system: true,
    //   epad: false,
    //   profile: false,
    //   markup: false,
    //   commission: true
    // },
    // {
    //   value: "fixed-price-ex-profile-and-markup",
    //   label: gettext("Fixed price ex profile and markup"),
    //   price: false,
    //   system: true,
    //   epad: true,
    //   profile: false,
    //   markup: false,
    //   commission: true
    // }
  ];
  const priceTypes2 = [
    {
      value: "fixed-price",
      label: gettext("Fixed price"),
      price: true,
      system: false,
      epad: false,
      profile: true,
      markup: true,
      commission: true
    },
    {
      value: "fixed-price-ex-profile",
      label: gettext("Fixed price ex profile"),
      price: true,
      system: false,
      epad: false,
      profile: false,
      markup: true,
      commission: true
    },
    {
      value: "fixed-profile-ex-spot",
      label: gettext("Fixed profile ex spot"),
      price: false,
      system: false,
      epad: false,
      profile: true,
      markup: true,
      commission: true
    },
    {
      value: "variable-spot-nordpool-price",
      label: gettext("Variable/Spot/Nordpool price"),
      price: false,
      system: false,
      epad: false,
      profile: false,
      markup: false,
      commission: true
    }
  ];
  const propsDefault = {
    countryCode: "DK"
  };

  let priceTypes = [];

  const [countryCode, setCountryCode] = useState(props.countryCode ? props.countryCode : propsDefault.countryCode);

  useEffect(() => {
    if (typeof props.countryCode !== "undefined") {
      setCountryCode(props.countryCode);
    }
  }, [props.countryCode]);

  switch (countryCode) {
    case "DE":
      priceTypes = priceTypes2;
      break;
    default:
      priceTypes = priceTypes1;
      break;
  }

  return priceTypes;
};

usePriceTypes.propTypes = {
  countryCode: PropTypes.string
};
