import React, {useEffect, useState} from "react";
import {useUser} from "../../providers";
import PropTypes from "prop-types";
import {SubsidiaryDropdown} from "./";
import {useUpdateProfile} from "../../../hooks";

/**
 * Subsidiary dropdown component
 * @param {object} props props
 * @returns {React.JSX.Element} React Component
 * @class
 */
export const SubsidiaryDropdownAutoSave = (props) => {
  const {updateProfile} = useUpdateProfile();
  const {
    user,
    setUser
  } = useUser();
  const {currentSubsidiaryId} = user || {
  };

  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : "");
  const [customerValue, setCustomerValue] = useState(undefined);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel("");
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel]);

  const handleChange = (event) => {
    if (event.value !== currentSubsidiaryId) {
      setCustomerValue(event.value);
      const variables = {
        currentSubsidiary: event.value === "all" ? "All" : event.label,
        currentSubsidiaryId: event.value
      };
      const updatedUser = {
        ...user,
        ...variables
      };
      setUser(updatedUser);

      updateProfile(variables);
    }
  };

  return (
    <SubsidiaryDropdown
      defaultValue={customerValue}
      showAll
      onChange={handleChange}
      componentLabel={componentLabel}
    />
  );

};

SubsidiaryDropdownAutoSave.propTypes = {
  componentLabel: PropTypes.string
};
