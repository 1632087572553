import React, {useState} from "react";
import PropTypes from "prop-types";
import {Container, Grid} from "@mui/material";
import {Box} from "@mui/system";
import Carousel from "react-material-ui-carousel";
import {PartnerImagePath} from "../../../../hooks";

import {AuthPageCarousel} from "./";
import {CarouselItems} from "../assets";
import {getPartnerKey} from "../../../../utils";

export const AuthPageLayout = (props) => {
  const partnerKey = getPartnerKey();
  const items = CarouselItems();
  const [loginImage1, setLoginImage1] = useState();

  PartnerImagePath(partnerKey, "login/login-1.png").then(imageUrl => {
    setLoginImage1(imageUrl);
  });

  return (
    <>
      <Grid item md={6} sx={{
        minHeight: `calc(100vh - 64px )`
      }}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Container sx={{
            textAlign: "center"
          }}>
            {props.children}
          </Container>
        </Box>
      </Grid>
      <Grid item md={6} display={{
        xs: "none",
        md: "block"
      }}
      sx={{
        backgroundImage: 'url("' + loginImage1 + '")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: `calc(100vh - 64px )`,
        position: "relative"
        // height: "50vh"
      }}
      >
        <Grid item md={12} sx={{
          backgroundColor: "secondary.700",
          opacity: "0.5",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          // height: `calc(100vh - 90px )`,
          // width: "50%",
          position: "absolute"
        }}>
        </Grid>
        <Carousel indicatorContainerProps={{
          style: {
            marginLeft: "50px",
            textAlign: "left"
          }

        }} sx={{
          opacity: "1"
        }}>
          {
            items.map((item, i) => <AuthPageCarousel key={i} item={item}/>)
          }
        </Carousel>

      </Grid>
    </>
  );
};

AuthPageLayout.propTypes = {
  children: PropTypes.node.isRequired
};
