import {DBConfig} from "../../config/DBConfig";

export const initIDB = () => {
  return new Promise((resolve, reject) => {
    const migrateObjects = [];
    const request = indexedDB.open(DBConfig.name, DBConfig.version);
    request.onerror = event => {
      // console.error(`Database error: ${event.target.errorCode}`);
      reject(`Database error: ${event.target.errorCode}`);
    };
    request.onsuccess = event => {
      const db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = event => {
      const db = event.target.result;

      DBConfig.objectStoresMeta.map(obj => {
        if (!migrateObjects[obj.store]) migrateObjects[obj.store] = [];
        if (event.oldVersion !== event.newVersion) {
          if (db.objectStoreNames && db.objectStoreNames.contains(obj.store)) {
            db.deleteObjectStore(obj.store);
          }
        }

        const store = db.createObjectStore(obj.store, obj.storeConfigstoreConfig);

        obj.storeSchema.map(obj2 => {
          store.createIndex(obj2.name, obj2.keyPath, obj2.options);
          return false;
        });
        return false;
      });
    };
  });
};

export const update = (dbName, data, key) => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readwrite");
      const store = txn.objectStore(dbName);
      const query = store.put(data, key);
      query.onsuccess = function (event) {
        resolve(event);
      };
      query.onerror = function (event) {
        reject(event);
      };
      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};

export const getById = (dbName, id) => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readonly");
      const store = txn.objectStore(dbName);
      const query = store.get(id);
      query.onsuccess = function (event) {
        if (!event.target.result) {
          reject(`The ${dbName} with ${id} not found`);
        } else {
          resolve(event.target.result);
        }
      };
      query.onerror = function (event) {
        reject(event);
      };
      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};

export const getByIndex = (dbName, key, value) => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readonly");
      const store = txn.objectStore(dbName);
      const index = store.index(key);
      const query = index.get(value);
      query.onsuccess = function () {
        if (!query.result) {
          reject(query.result);
        } else {
          // console.table(query.result);
          resolve(query.result);
        }
      };
      query.onerror = function (event) {
        reject(event);
      };
      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};

export const getAll = dbName => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readonly");
      const store = txn.objectStore(dbName);
      const rows = [];

      store.openCursor().onsuccess = event => {
        const cursor = event.target.result;
        if (cursor) {
          const row = cursor.value;
          rows.push(row);
          cursor.continue();
        }
        resolve(rows);
      };

      store.openCursor().onerror = err => {
        reject(err);
      };

      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};

export const deleteById = (dbName, id) => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      const txn = db.transaction(dbName, "readwrite");
      const store = txn.objectStore(dbName);

      const query = store.delete(id);

      query.onsuccess = function (event) {
        resolve(event);
      };

      query.onerror = function (event) {
        reject(event);
      };

      txn.oncomplete = function () {
        db.close();
      };
    });
  });
};

export const deleteAll = dbNameArray => {
  return new Promise((resolve, reject) => {
    initIDB().then(db => {
      dbNameArray.forEach(dbName => {
        const txn = db.transaction(dbName, "readwrite");
        const store = txn.objectStore(dbName);

        store.clear();
        store.onerror = err => {
          reject(err);
        };

        txn.oncomplete = function () {
          db.close();
        };
      });
      resolve("success");
    });
  });
};
