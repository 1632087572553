import React from "react";
import PropTypes from "prop-types";
import {CurrencyDropdownCommon} from "./CurrencyDropdownCommon";

export const CurrencyDropdownSimple = (props) => {
  return (
    <CurrencyDropdownCommon
      componentLabel={props.componentLabel}
      defaultValue={props.defaultValue}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="simple"
    />
  );
};

CurrencyDropdownSimple.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
