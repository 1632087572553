import { gql } from "@apollo/client";

export const GET_PARTNER_USERS_LIST = gql`
  query PartnerUsers ($partnerId: String!) {
    partnerUsers(partnerId: $partnerId) {
      id
      name
      email
      countryCode
      timezone
      roles
      lang
      companyId
      partner {
        id
        name
        key
        companyUrl
        appUrl
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
