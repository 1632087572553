import {gql} from "@apollo/client";

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings (
    $key: String!
    $data: JSONString!
  ) {
    updateSettings(
      key: $key
      data: $data
    ) {
      success
      message
    }
  }
`;

