import {useQuery} from "@apollo/client";
import {GET_USER_COMPANIES} from "./commands";

/**
 * Custom hook to fetch the list of companies associated with the current user.
 * @returns {{companiesList: Array<{id: string, name: string, type: string, cvr: string, address: string, label: string}>, loading: boolean}} - The list of user companies and the loading state.
 */
export function useGetUserCompanies() {
  const {
    data,
    loading
  } = useQuery(GET_USER_COMPANIES, {
    variables: {},
    // fetchPolicy: "network-only",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  return {
    companiesList: data?.getUserCompanies || [],
    loading
  };
}
