export const emailForgotPassword = (gettext) => {
  return `
    <tr>
        <td>${gettext("Did you forget your password? Create a new one by clicking the button below.")}</td>
    </tr>
    <tr>
        <td>
            <a class="link-button" href="{{create_password_link}}" style="color: #FFFFFF">${gettext("Create a new password")}</a>
        </td>
    </tr>
`;
};