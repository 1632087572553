import React from "react";
import PropTypes from "prop-types";
import {BuildingDropdownCommon} from "./BuildingDropdownCommon";

/**
 * Building dropdown component
 * @param {object} props props
 * @returns {Element} React Component
 * @class
 */
export function BuildingDropdown(props) {
  return (
    <BuildingDropdownCommon
      componentLabel={props.componentLabel}
      defaultValue={props.defaultValue}
      currentTeam={props.currentTeam}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="old"
    />
  );
}

BuildingDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
