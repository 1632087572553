import {gql} from "@apollo/client";

export const ME = gql`
query Me {
    me {
        id
        name
        email
        customerKey
        lang
        profilePhotoPath
        profilePhotoUrl
        countryCode
        timezone
        isSuperAdmin
        roles
        isVerified
        isActive
        currentTeam
        currentTeamId
        currentSubsidiary
        currentSubsidiaryId
        permissions {
            role
            modules
        }
        partner {
            id
            key
            name
            companyUrl
            appUrl
            createdBy
            updatedBy
            createdAt
            updatedAt
        }
    }
}
`;