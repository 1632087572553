import {gql} from "@apollo/client";

export const TAXES_INVOICES = gql`
query TaxesInvoice (
    $customer: String!
    $group: String!
    $meterType: String!
    $meteringPointId: String!
    $address: String!
    $fromDate: DateTime!
    $toDate: DateTime!
  ) {
    taxesInvoice(
        customer: $customer
        group: $group
        meterType: $meterType
        meteringPointId: $meteringPointId
        address: $address
        fromDate: $fromDate
        toDate: $toDate
    ) {
        meteringPointId
        customer
        address
        priceAndTax
        fromDate
        toDate
    }
}
`;