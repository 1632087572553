import {useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import {useCallback} from "react";
import {DELETE_USERS_CUSTOMER_ADMIN, GET_USER_CUSTOMERS} from "./commands";

/**
 * Custom hook to delete a user company.
 * @param {Function} [onCompleted] - Callback function to be called when the mutation completes successfully.
 * @param {Function} [onError] - Callback function to be called when an error occurs during the mutation.
 * @returns {{deleteUserCompany: Function, deleting: boolean}} - The function to delete a user company and the loading state.
 */
export function useDeleteUserCompany(
  onCompleted = () => {
  },
  onError = () => {
  }) {
  const [deleteUserCompany, {loading}] = useMutation(DELETE_USERS_CUSTOMER_ADMIN, {
    onCompleted,
    onError: (err) => {
      onError(err);

      enqueueSnackbar(
        "Delete User Company: " + err.message,
        {
          variant: "error"
        }
      );
    },
    errorPolicy: "all"
  });

  const deleteUserCompanyRow = useCallback(
    variables => {
      deleteUserCompany({
        variables,
        refetchQueries: [
          {
            query: GET_USER_CUSTOMERS,
            variables: {
              userId: variables.userId
            }
          }
        ]
      }).then();
    },
    [deleteUserCompany]
  );

  return {
    deleteUserCompany: deleteUserCompanyRow,
    deleting: loading
  };
}
