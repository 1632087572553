import {UsersList} from "../../components";
import React from "react";

export const SystemUsers = () => {
  return (
    <UsersList
      // showAdd
      showEdit
      // showDeleteSingle
      // showChangePassword
      // showCompanies
      userFilter="system"
    />
  );
};
