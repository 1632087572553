import {useQuery} from "@apollo/client";
import {GET_USER_CUSTOMERS} from "./commands";

/**
 * Custom hook to fetch the companies associated with a user.
 * @param {string} userId - The ID of the user.
 * @returns {{companies: Array<object>, loading: boolean, error: object}} - The list of companies, loading state, and error.
 */
export function useUserCompanies(userId) {
  const {data, loading} = useQuery(GET_USER_CUSTOMERS, {
    variables: {
      userId
    }
  });

  return {
    customers: data?.comGetUser?.teams || [],
    userName: data?.comGetUser?.name || "",
    loading
  };
}
