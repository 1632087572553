import {Box, Button, TableCell, Typography} from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import React, {useContext, useMemo, useState} from "react";
// import { styled } from "@mui/system";
import styled from "@mui/styled-engine";
import countries from "../../../../pages/energyPrices/countries";
import {useTextDomainContext} from "../../../../providers";

const RowCell = styled(TableCell)(() => ({
  align: "left",
  fontSize: "14px",
  fontWeight: 400,
  color: "#71717A",
  border: "none"
}));

/**
 *
 * @param initialSelectedItem
 */
export function useSelectedItem(initialSelectedItem) {
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);

  const memoizedSelectedItem = useMemo(() => selectedItem, [selectedItem]);

  return [memoizedSelectedItem, setSelectedItem];
}

/**
 *
 * @param initialView
 */
export function useView(initialView) {
  const [view, setView] = useState(initialView);

  const memoizedView = useMemo(() => view, [view]);

  return [memoizedView, setView];
}

/**
 *
 * @param countryCode
 */
function getCountryLabel(countryCode) {
  const country = countries.find(c => c.code === countryCode);
  return country ? country.label : countryCode;
}

/**
 *
 * @param dateTimeStr
 * @param format
 */
export function convertDateTime(dateTimeStr, format) {
  const dateObj = new Date(dateTimeStr);
  dateObj.setDate(dateObj.getDate() + 1);
  const yearPart = dateObj.getFullYear();
  const monthPart = String(dateObj.getMonth() + 1).padStart(2, "0");
  const dayPart = String(dateObj.getDate()).padStart(2, "0");
  const nextDayStr = `${yearPart}-${monthPart}-${dayPart}`;

  const [datePart, timePart] = dateTimeStr.split("T");
  const [hourPart, minutePart] = timePart.substr(0, 5).split(":");
  const timeStr = `${hourPart}:${minutePart}`;

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  if (format === "time") {
    return timeStr;
  } else if (format === "dateTime") {
    return `${datePart} ${timeStr}`;
  } else if (format === "month") {
    return monthNames[parseInt(monthPart) - 1];
  } else if (format === "NextDay") {
    return nextDayStr;
  } else {
    return datePart;
  }
}

/**
 *
 * @param view
 * @param segment
 */
export function useGetHeadCells(view, segment) {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  let headCells = [];
  if (view === "Customers") {
    headCells = [
      {
        id: "customerName",
        // disablePadding: true,
        label: gettext("Name"),
        tooltip: gettext("Company Name")
      },
      {
        id: "customerKey",
        disablePadding: false,
        label: gettext("Key"),
        tooltip: gettext("A code of reference ( Who referred a company)")
      },
      {
        id: "customerCvr",
        disablePadding: false,
        label: gettext("CVR"),
        tooltip: gettext("Central business Register number")
      },
      {
        id: "accessGiven",
        disablePadding: false,
        label: gettext("Access given"),
        tooltip: gettext("Date when the access is given")
      },
      {
        id: "validFrom",
        disablePadding: false,
        label: gettext("From"),
        tooltip: gettext("Validity start date")
      },
      {
        id: "validTo",
        disablePadding: false,
        label: gettext("To"),
        tooltip: gettext("Validity end date")
      },
      {
        id: "lastSeen",
        disablePadding: false,
        label: gettext("Last seen"),
        tooltip: gettext(
          "Timestamp of last encountered when requesting data from datahub"
        )
      },
      {
        id: "buildingCount",
        disablePadding: false,
        label: "",
        tooltip: gettext("How many buildings company have")
      }
    ];
  } else if (view === "Buildings") {
    headCells = [
      {
        id: "address",
        // disablePadding: true,
        label: gettext("Address"),
        tooltip: gettext(
          "Official address of meter (not including location information)"
        )
      },
      {
        id: "country",
        disablePadding: false,
        label: gettext("Country"),
        tooltip: gettext("Country code")
      },
      {
        id: "meterCount",
        disablePadding: false,
        label: "",
        tooltip: gettext("How many meters company have")
      }
    ];
  } else if (view === "Meters") {
    headCells = [
      {
        id: "meteringPointId",
        // disablePadding: true,
        label: gettext("ID"),
        tooltip: gettext("The metering point id from datahub")
      },
      {
        id: "meterNumber",
        disablePadding: false,
        label: gettext("Meter number"),
        tooltip: gettext("Often the number on company's bill")
      },
      {
        id: "typeOfMP",
        disablePadding: false,
        label: gettext("Type"),
        tooltip: gettext("Type of meter. E17 = consumption, E18 = production")
      },
      {
        id: "meterReadingOccurrence",
        disablePadding: false,
        label: gettext("Interval"),
        tooltip: gettext(
          "Data granularity. PT1H = every 1 hour, PT15M = every 15 minutes"
        )
      },
      {
        id: "authorization",
        disablePadding: false,
        label: gettext("Authorization"),
        tooltip: gettext("Company Authenticity number")
      },
      {
        id: "lastData",
        disablePadding: false,
        label: gettext("Last date"),
        tooltip: gettext("Last date")
      },
      {
        id: "lastSeen",
        disablePadding: false,
        label: gettext("Last seen"),
        tooltip: gettext(
          "Timestamp of last encountered when requesting data from datahub"
        )
      },
      {
        id: "view",
        disablePadding: false,
        label: "",
        tooltip: gettext("View meter details")
      }
    ];
  } else if (view === "Meter Detail" && segment === 0) {
    headCells = [
      {
        id: "ts",
        disablePadding: false,
        label: gettext("Hours"),
        tooltip: gettext(
          "Timestamp of last encountered when requesting data from datahub"
        )
      },
      {
        id: "vPerM2",
        disablePadding: false,
        label: gettext("V_per_m2"),
        tooltip: gettext(
          "measuring the electrical potential difference across a surface area, we use the unit volts per square meter (V/m2)"
        )
      },
      {
        id: "co2PerM2",
        disablePadding: false,
        label: gettext("CO₂_per_m2"),
        tooltip: gettext(
          "measuring the Carbon dioxide Consumption. potential difference across a surface area, we use the unit carbon dioxide per square meter (Co2/m2)"
        )
      },
      {
        id: "v",
        disablePadding: false,
        label: gettext("v"),
        tooltip: gettext("Parameter showing Electricity consumption")
      },
      {
        id: "co2",
        disablePadding: false,
        label: gettext("CO₂"),
        tooltip: gettext("Carbon dioxide Consumption")
      },
      {
        id: "co2ConversionRate",
        // disablePadding: true,
        label: gettext("Conversion Rate"),
        tooltip: gettext("Conversion Rate for CO₂")
      },

      {
        id: "q",
        disablePadding: false,
        label: gettext("q"),
        tooltip: gettext("q")
      },
      {
        id: "price",
        disablePadding: false,
        label: gettext("Price"),
        tooltip: gettext("Amount of money for consumption")
      }
    ];
  } else if (view === "Meter Detail" && segment === 1) {
    headCells = [
      {
        id: "ts",
        disablePadding: false,
        label: gettext("Days"),
        tooltip: gettext(
          "Timestamp of last encountered when requesting data from datahub"
        )
      },
      {
        id: "dataFrom",
        disablePadding: false,
        label: gettext("From"),
        tooltip: gettext("Meter reading start date")
      },
      {
        id: "end",
        disablePadding: false,
        label: gettext("End"),
        tooltip: gettext("Meter reading end date")
      },
      {
        id: "vPerM2",
        disablePadding: false,
        label: gettext("V_per_m2"),
        tooltip: gettext(
          "measuring the electrical potential difference across a surface area, we use the unit volts per square meter (V/m2)"
        )
      },
      {
        id: "co2PerM2",
        disablePadding: false,
        label: gettext("CO₂_per_m2"),
        tooltip: gettext(
          "measuring the Carbon dioxide Consumption. potential difference across a surface area, we use the unit carbon dioxide per square meter (Co2/m2)"
        )
      },
      {
        id: "v",
        disablePadding: false,
        label: gettext("v"),
        tooltip: gettext("Parameter showing Electricity consumption")
      },
      {
        id: "co2",
        disablePadding: false,
        label: gettext("CO₂"),
        tooltip: gettext("Carbon dioxide Consumption")
      },
      {
        id: "co2ConversionRate",
        // disablePadding: true,
        label: gettext("Conversion Rate"),
        tooltip: gettext("Conversion Rate for CO₂")
      },

      {
        id: "q",
        disablePadding: false,
        label: gettext("q"),
        tooltip: gettext("q")
      },
      {
        id: "price",
        disablePadding: false,
        label: gettext("Price"),
        tooltip: gettext("Amount of money for consumption")
      }
    ];
  } else {
    headCells = [
      {
        id: "ts",
        disablePadding: false,
        label: gettext("Month"),
        tooltip: gettext(
          "Timestamp of last encountered when requesting data from datahub"
        )
      },
      {
        id: "dataFrom",
        disablePadding: false,
        label: gettext("From"),
        tooltip: gettext("Meter reading start date")
      },
      {
        id: "end",
        disablePadding: false,
        label: gettext("End"),
        tooltip: gettext("Meter reading end date")
      },
      {
        id: "vPerM2",
        disablePadding: false,
        label: gettext("V_per_m2"),
        tooltip: gettext(
          "measuring the electrical potential difference across a surface area, we use the unit volts per square meter (V/m2)"
        )
      },
      {
        id: "co2PerM2",
        disablePadding: false,
        label: gettext("CO₂_per_m2"),
        tooltip: gettext(
          "measuring the Carbon dioxide Consumption. potential difference across a surface area, we use the unit carbon dioxide per square meter (Co2/m2)"
        )
      },
      {
        id: "v",
        disablePadding: false,
        label: gettext("v"),
        tooltip: gettext("Parameter showing Electricity consumption")
      },
      {
        id: "co2",
        disablePadding: false,
        label: gettext("CO₂"),
        tooltip: gettext("Carbon dioxide Consumption")
      },
      {
        id: "co2ConversionRate",
        // disablePadding: true,
        label: gettext("Conversion Rate"),
        tooltip: gettext("Conversion Rate for CO₂")
      },

      {
        id: "q",
        disablePadding: false,
        label: gettext("q"),
        tooltip: gettext("q")
      },
      {
        id: "price",
        disablePadding: false,
        label: gettext("Price"),
        tooltip: gettext("Amount of money for consumption")
      }
    ];
  }

  return headCells;
}

/**
 *
 * @param view
 * @param row
 * @param handleView
 * @param segment
 */
export function getTableCells(view, row, handleView, segment) {
  if (view === "Customers") {
    return (
      <>
        <RowCell
          // component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: "#27272A"
          }}
        >
          {row.customerName}
        </RowCell>
        <RowCell>{row.customerKey}</RowCell>
        <RowCell>{row.customerCvr}</RowCell>
        <RowCell>{row.accessGiven}</RowCell>
        <RowCell>{convertDateTime(row.validFrom, "Date")}</RowCell>
        <RowCell>{convertDateTime(row.validTo, "Date")}</RowCell>
        <RowCell>{row.lastSeen}</RowCell>
        <RowCell
          align="left"
          sx={{
            border: "none"
          }}
        >
          <Button onClick={() => handleView(row)}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#015731",
                pr: 1
              }}
            >
              {row.buildingCount + " Buildings"}
            </Typography>
            <ArrowForwardIosRoundedIcon fontSize="14px"/>
          </Button>
        </RowCell>
      </>
    );
  } else if (view === "Buildings") {
    return (
      <>
        <RowCell
          // component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: "#27272A"
          }}
        >
          {row.address}
        </RowCell>

        <RowCell>
          <Box
            sx={{
              "& > img": {
                mr: 1,
                borderRadius: "4px",
                flexShrink: 0
              },
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${row.country.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${row.country.toLowerCase()}.png 2x`}
              alt=""
            />
            {getCountryLabel(row.country)}
          </Box>
        </RowCell>
        <RowCell
          align="right"
          sx={{
            border: "none"
          }}
        >
          <Button onClick={() => handleView(row)}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#015731",
                pr: 1
              }}
            >
              {row.meterCount + " Meters"}
            </Typography>
            <ArrowForwardIosRoundedIcon fontSize="14px"/>
          </Button>
        </RowCell>
      </>
    );
  } else if (view === "Meters") {
    return (
      <>
        <RowCell
          // component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: "#27272A"
          }}
        >
          {row.meteringPointId}
        </RowCell>
        <RowCell>{row.meterNumber}</RowCell>
        <RowCell>{row.typeOfMP}</RowCell>
        <RowCell>{row.meterReadingOccurrence}</RowCell>
        <RowCell>{row.authorization}</RowCell>
        <RowCell>{convertDateTime(row.lastData, "dateTime")}</RowCell>
        <RowCell>{convertDateTime(row.lastSeen, "dateTime")}</RowCell>
        <RowCell
          align="left"
          sx={{
            border: "none"
          }}
        >
          <Button onClick={() => handleView(row)}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#015731",
                pr: 1
              }}
            >
              view
            </Typography>
            <ArrowForwardIosRoundedIcon fontSize="14px"/>
          </Button>
        </RowCell>
      </>
    );
  } else if (view === "Meter Detail" && segment === 0) {
    return (
      <>
        <RowCell
          // component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: "#27272A"
          }}
        >
          {convertDateTime(row.ts, "time")}
        </RowCell>
        <RowCell>{row.vPerM2}</RowCell>
        <RowCell>{row.co2PerM2}</RowCell>
        <RowCell>{row.v}</RowCell>
        <RowCell>{row.co2}</RowCell>
        <RowCell>{row.co2ConversionRate}</RowCell>
        <RowCell>{row.q}</RowCell>
        <RowCell>{row.price}</RowCell>
      </>
    );
  } else if (view === "Meter Detail" && segment === 1) {
    return (
      <>
        <RowCell
          // component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: "#27272A"
          }}
        >
          {convertDateTime(row.ts, "date")}
        </RowCell>
        <RowCell>{convertDateTime(row.ts, "date")}</RowCell>
        <RowCell>{convertDateTime(row.ts, "NextDay")}</RowCell>
        <RowCell>{row.vPerM2}</RowCell>
        <RowCell>{row.co2PerM2}</RowCell>
        <RowCell>{row.v}</RowCell>
        <RowCell>{row.co2}</RowCell>
        <RowCell>{row.co2ConversionRate}</RowCell>
        <RowCell>{row.q}</RowCell>
        <RowCell>{row.price}</RowCell>
      </>
    );
  } else if (view === "Meter Detail" && segment === 2) {
    return (
      <>
        <RowCell
          // component="th"
          scope="row"
          sx={{
            fontWeight: 500,
            color: "#27272A"
          }}
        >
          {convertDateTime(row?.monthlyData[0]?.ts, "month")}
        </RowCell>
        <RowCell>{convertDateTime(row?.period?.dataFrom, "date")}</RowCell>
        <RowCell>{convertDateTime(row?.period?.end, "date")}</RowCell>
        <RowCell>{row?.monthlyData[0]?.vPerM2}</RowCell>
        <RowCell>{row?.monthlyData[0]?.co2PerM2}</RowCell>
        <RowCell>{row?.monthlyData[0]?.v}</RowCell>
        <RowCell>{row?.monthlyData[0]?.co2}</RowCell>
        <RowCell>{row?.monthlyData[0]?.co2ConversionRate}</RowCell>
        <RowCell>{row?.monthlyData[0]?.q}</RowCell>
        <RowCell>{row?.monthlyData[0]?.price}</RowCell>
      </>
    );
  }
}
