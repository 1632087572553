import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_NEW_NOTIFICATIONS } from "../commands";

/**
 *
 * @param showNotificationCounter
 */
export function useNotificationsPreview(showNotificationCounter) {
  const [counter, setcounter] = useState(0);

  const { data } = useQuery(GET_NEW_NOTIFICATIONS, {
    onCompleted: ({ comGetNewNotifications }) => {
      setcounter(comGetNewNotifications?.length || 0);
    }
  });

  return {
    count: showNotificationCounter ? counter : 0,
    previewNotifications: data?.comGetNewNotifications?.slice(0, 10) || []
  };
}
