import { gql } from "@apollo/client";

export const GET_USER_CUSTOMERS = gql`
  query getUserCustomers($userId: String) {
    comGetUser(userId: $userId) {
      name
      teams {
        id: Id
        Id
        name
        label
        cvr
      }
    }
  }
`;

