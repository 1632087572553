import * as XLSX from "xlsx";

export const exportToExcel = (data, filename = "data.xlsx") => {
  if (!Array.isArray(data) || !data.length) {
    throw new Error("Invalid data format. Expected an array of objects or a 2D array.");
  }

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  const wbout = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array"
  });

  const blob = new Blob([wbout], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });
  const uri = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", uri);
  link.setAttribute("download", filename);
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
