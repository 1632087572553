import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

export const FilterBox = (props) => {
  return (
    <Box
      sx={{
        p: 1,
        // backgroundColor: "secondary.50",
        // border: "1px solid",
        minHeight: "52px",
        borderColor: "secondary.200",
        borderRadius: "8px"
      }}
    >
      {props.children}
    </Box>
  );
};

FilterBox.propTypes = {
  children: PropTypes.node.isRequired
};
