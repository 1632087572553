import {useMutation} from "@apollo/client";
import {useState} from "react";
import {CHANGE_PASSWORD_MUTATIION} from "../../GraphQL/Mutations";
import {enqueueSnackbar} from "notistack";

export const useChangePassword = (
  onCompleted = () => {
  },
  onError = () => {
  }
) => {
  const [success, setSuccess] = useState(false);

  const [changePassword, {loading}] = useMutation(CHANGE_PASSWORD_MUTATIION, {
    onCompleted: response => {
      if (response && response.comUpdatePassword.success === "true") {
        setSuccess(true);
        onCompleted();
      } else {
        if (response?.comUpdatePassword?.message?.length) {
          response.comUpdatePassword.message.forEach((msg) => {
            enqueueSnackbar(msg, {
              variant: "error"
            });
          });
        }

        setSuccess(false);
      }
    },
    onError: (error) => {
      setSuccess(false);
      onError(error);
    }
  });
  return {
    changePassword,
    loading,
    success,
  };
};