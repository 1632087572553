import React, {useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {daDK, deDE, enUS, esES, frFR, nbNO, nlNL, svSE} from "@mui/x-data-grid/locales";
import {createTheme, LinearProgress, ThemeProvider, useTheme} from "@mui/material";
import {useLang} from "../../providers";
import PropTypes from "prop-types";

const useGridTheme = () => {
  const locale = useLang();
  const theme = useTheme();
  let selectedLanguage = enUS;
  switch (locale) {
    case "da":
      selectedLanguage = daDK;
      break;
    case "de":
      selectedLanguage = deDE;
      break;
    case "es":
      selectedLanguage = esES;
      break;
    case "fr":
      selectedLanguage = frFR;
      break;
    case "nb":
      selectedLanguage = nbNO;
      break;
    case "nl":
      selectedLanguage = nlNL;
      break;
    case "no":
      selectedLanguage = nbNO;
      break;
    case "sv":
      selectedLanguage = svSE;
      break;
    default:
      selectedLanguage = enUS;
      break;
  }


  return createTheme(
    theme,
    locale === selectedLanguage
  );
};

/**
 *
 * @param props
 */
export const DataGridComponent = (props) => {
  const theme = useGridTheme();
  const {
    rows,
    columns,
    loading,
    getRowId,
    disableSelectionOnClick,
    CustomToolbar
  } = props;

  const [pageSize, setPageSize] = useState(10);

  return (
    <div style={{
      width: "100%"
    }}>
      <ThemeProvider theme={theme}>
        <DataGrid
          style={{
            zIndex: 0
          }}
          sx={{
            backgroundColor: "rgb(247, 252, 250)",

            ml: "10px",
            mr: "5px",
            border: "none"
            // ".MuiDataGrid-columnSeparator": {
            //   display: "none"
            // }
          }}
          {...props}
          rows={rows ? rows : []}
          columns={columns}
          loading={loading}
          getRowId={getRowId}
          pageSize={pageSize}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 50, 100]}
          disableSelectionOnClick={disableSelectionOnClick}
          components={{
            Toolbar: CustomToolbar ? CustomToolbar : null,
            LoadingOverlay: LinearProgress
          }}
          autoHeight
          disableColumnMenu
        />
      </ThemeProvider>
    </div>
  );
};

DataGridComponent.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  getRowId: PropTypes.func,
  disableSelectionOnClick: PropTypes.bool,
  CustomToolbar: PropTypes.func
};