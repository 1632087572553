import {DBConfig} from "../../config/DBConfig";

export const initIDB = () => {
  return new Promise((resolve, reject) => {
    const migrateObjects = [];
    const request = indexedDB.open(DBConfig.name, DBConfig.version);
    request.onerror = event => {
      // console.error(`Database error: ${event.target.errorCode}`);
      reject(`Database error: ${event.target.errorCode}`);
    };
    request.onsuccess = event => {
      const db = event.target.result;
      resolve(db);
    };

    request.onupgradeneeded = event => {
      const db = event.target.result;

      DBConfig.objectStoresMeta.map(obj => {
        if (!migrateObjects[obj.store]) migrateObjects[obj.store] = [];
        if (event.oldVersion !== event.newVersion) {
          if (db.objectStoreNames && db.objectStoreNames.contains(obj.store)) {
            db.deleteObjectStore(obj.store);
          }
        }

        const store = db.createObjectStore(obj.store, obj.storeConfigstoreConfig);

        obj.storeSchema.map(obj2 => {
          store.createIndex(obj2.name, obj2.keyPath, obj2.options);
          return false;
        });
        return false;
      });
    };
  });
};