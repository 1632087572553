import {gql} from "@apollo/client";

export const CONSUMPTION_ENERGY_DATA_MONTH_DATA = gql`
  query ConsumptionEnergyData (
      $meterType:  String!,
      $customer:  String!,
      $group:  String!,
      $year:  String!,
      $isClimate: Boolean
      $month: String
    ) {
    consumptionEnergyData(
      meterType: $meterType
      customer: $customer
      group: $group
      year: $year
      isClimate: $isClimate
      month: $month
    ) {
      consumptionData {
        month
        details {
          grandSum
          hourlyAggregatedData {
            ts
            consumptionV
          }
          data {
            meter
            consumptionSum
            data {
              ts
              consumptionV
            }
          }
        }
      }
    }
  }
`;