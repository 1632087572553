import {Button} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export const BlackButton = (props) => {
  const sx = {
    backgroundColor: "common.black",
    color: "common.white",
    borderRadius: 1,
    // py: 1,
    // px: 3,
  };


  return (
    <Button
      {...props}
      sx={{
        ...sx,
        ...props.sx
      }}
      // onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

BlackButton.propTypes = {
  children: PropTypes.string,
  sx: PropTypes.object,
  onClick: PropTypes.func,
};