import { gql } from "@apollo/client";

export const REGISTER = gql`
  mutation comRegister(
    $email: String!
    $name: String!
    $password: String!
    $partnerKey: String!
  ) {
    comRegister(
      email: $email
      name: $name
      password: $password
      partnerKey: $partnerKey
    ) {
      success
      message
      token
    }
  }
`;
