import React from "react";
import PropTypes from "prop-types";
import {MeterDropdownCommon} from "./MeterDropdownCommon";

/**
 * Meter dropdown component
 * @param {object} props props
 * @returns {React.JSX.Element} React Component
 * @class
 */
export const MeterDropdown = (props) => {
  return (
    <MeterDropdownCommon
      componentLabel={props.componentLabel}
      building={props.building}
      energyType={props.energyType}
      defaultValue={props.defaultValue}
      currentTeam={props.currentTeam}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="old"
    />
  );

};

MeterDropdown.propTypes = {
  componentLabel: PropTypes.string,
  building: PropTypes.string,
  energyType: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
