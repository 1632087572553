export const emailFooter = (gettext) => {
  return `
  <tr>
    <td>
      <div style="border-bottom: 1px solid #e5e7eb; height: 1px;"></div>
    </td>
  </tr>
  <tr>
    <td style="color: #9ca3af;">
      ${gettext("This link will expire in")}
      <span style="color: #064b7c;">${gettext("2 days")}</span>.
      ${gettext("Did you get this email by mistake? If so no worries - you can just ignore it.")}
    </td>
  </tr>
  <tr>
    <td style="font-size: 24px; color: #374151; font-weight: bold;">
      ${gettext("Questions?")}
    </td>
  </tr>
  <tr>
    <td style="padding-bottom: 40px; color: #9ca3af;">
      ${gettext("Feel free to reach out to Customer Service. They are around 24/7 to help you.")}
    </td>
  </tr>
</table>
</body>
</html>`;
};