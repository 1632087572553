import {useEffect, useState} from "react";
import {useTimeout} from "./";

/**
 *
 * @param value
 * @param delay
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const {
    reset,
    clear
  } = useTimeout(() => setDebouncedValue(value), delay);
  useEffect(reset, [value, reset]);
  useEffect(clear, [clear]);

  return debouncedValue;
};
