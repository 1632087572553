import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTextDomainContext} from "../../providers";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import {useLocation} from "react-router";
import {useFormik} from "formik";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography
} from "@mui/material";

import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useLogin} from "../../../hooks";
import {getPartnerKey} from "../../../utils";

// import { GoogleSigninButton } from "app/components";
// import GoogleLogin from "react-google-login";
// import {
//     loginGoogle,
//     loginWithSocialMedia,
// } from "app/modules/Auth/_redux/authCrud";
// import { useGoogleLogin } from "app/modules/Auth/Login/hooks";

/**
 *
 */
export function LoginForm() {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const location = useLocation();
  const partnerKey = getPartnerKey();

  // const [toastList, updateToastList] = useState([]);

  // const {
  //     REACT_APP_URL,
  //     REACT_APP_CLIENT_ID_GOOGLE,
  //     REACT_APP_BASE_BACKEND_URL,
  // } = process.env;
  // const { setToken } = useAuth();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(gettext("Must be a valid email"))
          .max(255)
          .required(gettext("Email is required")),
        password: Yup.string()
          .max(255)
          .required(gettext("Password is required"))
      }),
    [gettext]
  );

  const {
    submit,
    isSubmitting
  } = useLogin(
    () => {
    },
    error => {
      if (error) {
        formik.setErrors({
          submit: error
        });
      } else {
        formik.setErrors({
          submit: gettext("Unknown Error Occurred !")
        });
      }
    }
  );

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  }, [location.pathname]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false
    },
    onSubmit: values => {
      // submit the form here
      submit({
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe
      });
    },
    validationSchema
  });

  // const scope = [
  //     "https://www.googLeapis.com/auth/userinfo.email",
  //     "https://www.googleapis.com/auth/userinfo.profile",
  // ].join(" ");

  // const responseGoogle = () => {
  //     try {
  //         const params = {
  //             response_type: "code",
  //             prompt: "select_account",
  //             access_type: "offline",
  //             scope,
  //         };
  //         const res = loginGoogle(params);
  //         if (res) {
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // };

  return (
    <Box sx={{
      marginY: 1
    }}>
      <Collapse in={Boolean(formik.errors.submit)}>
        <Alert variant="outlined" severity="error">
          {formik.errors.submit}
        </Alert>
      </Collapse>

      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{
          mt: 1
        }}>
          <Grid item xs={12}>
            {/* <Stack> */}
            <FormControl sx={{
              width: "70%"
            }}>
              {/* <InputLabel htmlFor="email-login"> */}
              {/*   /!* <Typography fontSize={14} fontWeight={500} color="#3F3F46"> *!/ */}
              {/*   {gettext("Email Address")} */}
              {/*   /!* </Typography> *!/ */}
              {/* </InputLabel> */}
              <OutlinedInput
                id="email-login"
                type="email"
                name="email"
                // fullWidth
                size="large"
                placeholder={gettext("Email address")}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(formik.touched.email && formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {formik.errors.email}
                </FormHelperText>
              )}
            </FormControl>
            {/* </Stack> */}
          </Grid>

          <Grid item xs={12}>
            {/* <Stack> */}
            <FormControl sx={{
              width: "70%"
            }}>
              {/* <InputLabel htmlFor="password-login"> */}
              {/*   /!* <Typography fontSize={14} fontWeight={500} color="#3F3F46"> *!/ */}
              {/*   {gettext("Password")} */}
              {/*   /!* </Typography> *!/ */}
              {/* </InputLabel> */}
              <OutlinedInput
                id="password-login"
                name="password"
                type={showPassword ? "text" : "password"}
                // fullWidth
                size="large"
                placeholder={gettext("Password")}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.touched.password && formik.errors.password
                )}
                endAdornment={
                  <InputAdornment position="end" sx={{
                    color: "#D4D4D8"
                  }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="inherit"
                      size="small"
                    >
                      {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                >
                  {formik.errors.password}
                </FormHelperText>
              )}
            </FormControl>
            {/* </Stack> */}
          </Grid>

          <Grid item xs={12}>
            <FormControl sx={{
              width: "70%"
            }}>
              <Button
                disableElevation
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {gettext("Sign in")}
              </Button>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12}>
            <GoogleSigninButton />
          </Grid> */}


          {/*{partnerKey === "fabeke" && (*/}
          {/*{partnerKey && (*/}
          {partnerKey === "no-key" && (
            <Grid item xs={12} variant="body2">
              {/* <Link variant="body2" href="/auth/forgot-password" color="#A1A1AA"> */}
              <Typography variant="body2">
                {gettext("Don't Have An Account Yet?")}&nbsp;
                <Link variant="h6" href={"/auth/register"}>{gettext("Sign Up")}</Link>
              </Typography>
              {/* </Link> */}
            </Grid>
          )}

          <Grid item xs={12}>
            <Link variant="body2" href="/auth/forgot-password">
              {gettext("Forgot Your Password?")}
            </Link>
          </Grid>

          {/* <Grid item xs={12} sx={{ */}
          {/*   mt: -1 */}
          {/* }}> */}
          {/*   <FormControlLabel */}
          {/*     control={ */}
          {/*       <Checkbox */}
          {/*         checked={formik.values.rememberMe} */}
          {/*         onChange={event => */}
          {/*           formik.setFieldValue("rememberMe", event.target.checked) */}
          {/*         } */}
          {/*         name="checked" */}
          {/*         color="primary" */}
          {/*       /> */}
          {/*     } */}
          {/*     label={ */}
          {/*       <Typography fontSize={"12px"}> */}
          {/*         {gettext("Remember Me")} */}
          {/*       </Typography> */}
          {/*     } */}
          {/*   /> */}
          {/* </Grid> */}

          {/* <Grid item xs={12}>
                        <Button
                            className={"btn-google"}
                            variant="outlined"
                            startIcon={
                                <img
                                    src="/media/social/google.png"
                                    className="img-responsive float-left google-icon"
                                    alt="Google"
                                />
                            }
                            onClick={() => responseGoogle()}
                        >
                            Sign in with Google
                        </Button>

                        <GoogleLogin
                            clientId={REACT_APP_CLIENT_ID_GOOGLE}
                            buttonText={gettext("Sign in with Google")}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            className={"btn-google"}
                            theme={"dark"}
                        />
                    </Grid> */}
        </Grid>
      </form>
    </Box>
  );
}
